import React, {useState} from 'react';
import {closeCookiePopup} from '../../js/Components/CookieBanner/cookieBanner.js';
import {t} from 'PlattixUI/PlattixReactCore/i18n';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {ResponsiveComponent} from "../components/Responsive";

// import Cookies from "universal-cookie";

export function CookieBannerComponent(props: any) {
    const cookieAcceptedString : string = 'cookies-accepted';
    
    const [show, setShow] = props.show;
    const [cookiesAccepted, setCookiesAccepted] = useState(localStorage.getItem(cookieAcceptedString) === 'true');
        
    const acceptCookies = () => {
        if (cookiesAccepted) return setShow(null);
        
        localStorage.setItem('cookies-accepted', 'true');
        setCookiesAccepted(true);
        setShow(null)
    }

    return (
        <div className="cookieBanner"/* @* conditional - className- cookieOnlyMobile="@Model" *@*/>
            <div className="banner">
                <ResponsiveComponent devices={['desktop']}>
                    <h5>{t('Cookies.WeUseCookies')}</h5>
                </ResponsiveComponent>
                <p>
                    {t('Cookies.Banner.ShortExplanation')}
                    <span dangerouslySetInnerHTML={{ __html: t('Cookies.Banner.LinksInText') }} />
                </p>
                {/*{!cookiesAccepted &&*/}
                    <div className="cookieBtns">
                        <button id="cookiesAccept" className="content-btn content-btn-1" close-button="" onClick={acceptCookies}>{t('Cookies.Banner.OKBtn')}</button>
                    </div>
                {/*}*/}
            </div>
            <div id="readMorePopup" className="cookiePopup">
                <div className="module-container popupContent">
                    <div className="module-header">
                        <h5>{t('Cookies.WeUseCookies')}</h5>
                        {/*<button type="button" className="close content-btn content-btn-3" onClick={(e) => closeCookiePopup(e.currentTarget)}><span aria-hidden="true">×</span></button>*/}
                        <FontAwesomeIcon icon={faTimes} onClick={(e) => closeCookiePopup(e.currentTarget)} className="close" />
                    </div>
                    <div className="module-content module-block" id="module-content">
                        <div className="tab-content">
                            <div className="cookieDescGroup">
                                <h5>{t('Cookies.FunctionalCookies')}</h5>
                                <p>{t('Cookies.FunctionalCookies.Description')}</p>
                                <p>{t('Cookies.FunctionalCookies.Accepted')}</p>
                                <h6>{t('Cookies.UsedCookies')}</h6>
                                <ul>
                                    <li>
                                        <span className="cookieName">{t('Cookies.Name')}</span>UserCulture
                                        <br />
                                        <span className="cookieName">{t('Cookies.Description')}</span>{t('Cookies.Cookie.UserCulture')}
                                    </li>
                                    <li>
                                        <span className="cookieName">{t('Cookies.Name')}</span>.AspNetCore.Antiforgery.*
                                        <br />
                                        <span className="cookieName">{t('Cookies.Description')}</span>{t('Cookies.Cookie.AspNetCore.Antiforgery')}
                                    </li>
                                    <li>
                                        <span className="cookieName">{t('Cookies.Name')}</span>Energytix
                                        <br />
                                        <span className="cookieName">{t('Cookies.Description')}</span>{t('Cookies.Cookie.Energytix')}
                                    </li>
                                    <li>
                                        <span className="cookieName">{t('Cookies.Name')}</span>AspNetCore.Identity.2FA
                                        <br />
                                        <span className="cookieName">{t('Cookies.Description')}</span>{t('Cookies.Cookie.AspNetCore.Identity.2FA')}
                                    </li>
                                    <li>
                                        <span className="cookieName">{t('Cookies.Name')}</span>.AspNetCore.Mvc.CookieTempDataProvider
                                        <br />
                                        <span className="cookieName">{t('Cookies.Description')}</span>{t('Cookies.Cookie.AspNetCore.Mvc.CookieTempDataProvider')}
                                    </li>
                                </ul>
                            </div>

                            {/*@*<div className="cookieDescGroup">*/}
                            {/*    <h5>@ApplicationModel.Translate("Cookies.AnalyticCookies")</h5>*/}
                            {/*    <p>@ApplicationModel.Translate("Cookies.AnalyticCookies.Description")</p>*/}
                            {/*    <h6>@ApplicationModel.Translate("Cookies.UsedCookies")</h6>*/}
                            {/*    <ul>*/}
                            {/*        <li>*/}
                            {/*            <span className="cookieName">@ApplicationModel.Translate("Cookies.CookieNotInUse")</span>*/}
                            {/*        </li>*/}
                            {/*    </ul>*/}
                            {/*</div>*/}

                            {/*<div className="cookieDescGroup">*/}
                            {/*    <h5>@ApplicationModel.Translate("Cookies.TrackingCookies")</h5>*/}
                            {/*    <p>@ApplicationModel.Translate("Cookies.TrackingCookies.Description")</p>*/}
                            {/*    <h6>@ApplicationModel.Translate("Cookies.UsedCookies")</h6>*/}
                            {/*    <ul>*/}
                            {/*        <li>*/}
                            {/*            <span className="cookieName">@ApplicationModel.Translate("Cookies.CookieNotInUse")</span>*/}
                            {/*        </li>*/}
                            {/*    </ul>*/}
                            {/*</div>*/}

                            {/*<div className="cookieDescGroup">*/}
                            {/*    <h5>@ApplicationModel.Translate("Cookies.ThirdPartyCookies")</h5>*/}
                            {/*    <p>@ApplicationModel.Translate("Cookies.ThirdPartyCookies.Description")</p>*/}
                            {/*    <h6>@ApplicationModel.Translate("Cookies.UsedCookies")</h6>*/}
                            {/*    <ul>*/}
                            {/*        <li>*/}
                            {/*            <span className="cookieName">@ApplicationModel.Translate("Cookies.CookieNotInUse")</span>*/}
                            {/*        </li>*/}
                            {/*    </ul>*/}
                            {/*</div>*@*/}
                        </div>
                    </div>
                </div>
            </div>

            <div id="settingsPopup" className="cookiePopup" /*@* conditional - className- cookieOnlyMobile="@Model" *@*/>
                <div className="module-container popupContent">
                    <div className="module-header">
                        <h5>{t('Cookies.CookieSettings')}</h5>
                        <button type="button" className="close" onClick={(event) => closeCookiePopup(event)}><span aria-hidden="true">×</span></button>
                    </div>
                    <div className="module-content module-block" id="module-content">
                        <div className="tab-content">
                            <div className="cookieDescGroup">
                                <h5>{t('Cookies.FunctionalCookies')}</h5>
                                <p>{t('Cookies.FunctionalCookies.Description')}</p>
                                {/*@*<div className="cookieBtns">*/}
                                {/*    <button className="content-btn content-btn-5 content-btn-disabled">@ApplicationModel.Translate("Cookies.Settings.DeclineBtn")</button>*/}
                                {/*    <button className="content-btn content-btn-5 content-btn-disabled">@ApplicationModel.Translate("Cookies.Settings.AcceptBtn")</button>*/}
                                {/*</div>*@*/}
                            </div>
                            {/*@*<div className="cookieDescGroup">*/}
                            {/*    <h5>@ApplicationModel.Translate("Cookies.AnalyticCookies")</h5>*/}
                            {/*    <p>@ApplicationModel.Translate("Cookies.AnalyticCookies.Description")</p>*/}
                            {/*    <div className="cookieBtns">*/}
                            {/*        <button className="content-btn content-btn-5 content-btn-disabled">@ApplicationModel.Translate("Cookies.Settings.DeclineBtn")</button>*/}
                            {/*        <button className="content-btn content-btn-5 content-btn-disabled">@ApplicationModel.Translate("Cookies.Settings.AcceptBtn")</button>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="cookieDescGroup">*/}
                            {/*    <h5>@ApplicationModel.Translate("Cookies.TrackingCookies")</h5>*/}
                            {/*    <p>@ApplicationModel.Translate("Cookies.TrackingCookies.Description")<br /><span className="cookieBtnStatus" id="statusTrackingCookies">@ApplicationModel.Translate("Cookies.CookieStatusTrue")</span></p>*/}
                            {/*    <div className="cookieBtns">*/}
                            {/*        <button id="acceptTrackingCookies" className="content-btn content-btn-1">@ApplicationModel.Translate("Cookies.Settings.AcceptBtn")</button>*/}
                            {/*        <button id="declineTrackingCookies" className="content-btn content-btn-2">@ApplicationModel.Translate("Cookies.Settings.DeclineBtn")</button>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="cookieDescGroup">*/}
                            {/*    <h5>@ApplicationModel.Translate("Cookies.ThirdPartyCookies")</h5>*/}
                            {/*    <p>@ApplicationModel.Translate("Cookies.ThirdPartyCookies.Description")<br /><span className="cookieBtnStatus" id="statusThirdPartyCookies">@ApplicationModel.Translate("Cookies.CookieStatusTrue")</span></p>*/}
                            {/*    <div className="cookieBtns">*/}
                            {/*        <button id="acceptThirdPartyCookies" className="content-btn content-btn-1">@ApplicationModel.Translate("Cookies.Settings.AcceptBtn")</button>*/}
                            {/*        <button id="declineThirdPartyCookies" className="content-btn content-btn-2">@ApplicationModel.Translate("Cookies.Settings.DeclineBtn")</button>*/}
                            {/*    </div>*/}
                            {/*</div>*@*/}
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}