import React from "react";
import {styled} from "goober";
import {useMediaQuery} from "@mui/material";
import {deviceDimensions} from "PlattixUI/core/components/Responsive";

export interface ResponsiveCheckerProps {
    show: boolean;
}

export function ResponsiveChecker(props: ResponsiveCheckerProps) {
    
    const mobile = useMediaQuery(deviceDimensions.mobile);
    const tablet = useMediaQuery(deviceDimensions.tablet);
    const desktop = useMediaQuery(deviceDimensions.desktop);
    
    if (!props.show) return (<></>);
    
    return (
        <ResponsiveCheckerContainer>
            <div><b>Mobile: </b><p className={mobile ? 'true' : 'false'}>{`${mobile}`}</p></div>
            <div><b>Tablet: </b><p className={tablet ? 'true' : 'false'}>{`${tablet}`}</p></div>
            <div><b>Desktop: </b><p className={desktop ? 'true' : 'false'}>{`${desktop}`}</p></div>
        </ResponsiveCheckerContainer>
    );
}

export const ResponsiveCheckerContainer = styled("div")`
  background: rgba(255, 255, 255, 0.5);
  border: 2px solid red;
  position: fixed;
  top: 0;
  right: 0;
  padding: 1px 4px;
  display: flex;
  flex-flow: column wrap;
  opacity: 0.2;
  
  div {
    display: flex;
    gap: 10px;
  }
  
  .true {
    color: green;
    font-weight: 700;
  }
  
  .false {
    color: red;
  }
`;