import $ from 'jquery';

// --------------- Optiemenu van module --------------- //


export function moduleOptions(target) {
    // let moduleContainer = target.closest('.module-container');
    // let dropdown = moduleContainer.querySelector('.module-options-dropdown');
    //
    // console.log(document.querySelector('.module-container .module-options-dropdown').classList.contains('module-options-active'));
    // if (!dropdown) dropdown = target.closest('.module-options-dropdown');
    // console.log(target, dropdown)
    //
    //
    // if (dropdown.classList.contains('module-options-active')) {
    //     dropdown.classList.remove('module-options-active');
    //     console.log('if');
    // } else {
    //     dropdown.classList.add('module-options-active');
    //     console.log('else');
    //     //if (dropdown)
    //     //    target.closest('.module-options-dropdown').classList.toggle('module-options-active');
    // }

    console.log('Deze functie is niet langer in werking.')

};

// --------------- Dichtklappen van module --------------- //

export function moduleColapse(target) {
    let moduleContainer = target.closest('.module-container');
    let moduleHeader = target.closest('.module-header');

    moduleContainer.querySelector('.module-content').classList.toggle('module-content-disable');
/*    moduleContainer.classList.toggle('module-container-colapsed');*/

    if (moduleContainer.querySelector('.module-content').classList.contains('module-content-disable')) {
        moduleHeader.style.borderBottom = 'none';
        moduleHeader.querySelector('.module-colapse').children[0].classList.remove('fa-caret-down');
        moduleHeader.querySelector('.module-colapse').children[0].classList.add('fa-caret-right');

        //moduleContainer.classList.add('module-container-colapsed');
        //if (moduleContainer.previousElementSibling !== null) {
        //    moduleContainer.previousElementSibling.classList.add('module-container-colapsed');
        //}
    } else {
        moduleHeader.style.borderBottom = '1px solid rgba(102, 102, 102, 0.25)';
        moduleHeader.querySelector('.module-colapse').children[0].classList.remove('fa-caret-right');
        moduleHeader.querySelector('.module-colapse').children[0].classList.add('fa-caret-down');

        //moduleContainer.classList.remove('module-container-colapsed');
        //if (moduleContainer.previousElementSibling !== null) {
        //    moduleContainer.previousElementSibling.classList.remove('module-container-colapsed');
        //}
    }
};

// --------------- Dichtklappen van chart-legend-body --------------- //

// export function groupColaps(target) {
//     target.closest('.chart-legend-body').querySelector('ul').classList.toggle('module-content-disable');
//
//     if (target.querySelector('i').classList.contains('fa-caret-down')) {
//         target.querySelector('i').classList.remove('fa-caret-down');
//         target.querySelector('i').classList.add('fa-caret-right');
//         target.classList.remove('legend-group-open');
//     } else {
//         target.querySelector('i').classList.remove('fa-caret-right');
//         target.querySelector('i').classList.add('fa-caret-down');
//         target.classList.add('legend-group-open');
//     }
// };

// export function treeviewColapse(target) {
//     target.closest('.treeview-item').classList.toggle('closed');
//
//     if (target.querySelector('i').classList.contains('fa-caret-down')) {
//         target.querySelector('i').classList.remove('fa-caret-down');
//         target.querySelector('i').classList.add('fa-caret-right');
//     } else {
//         target.querySelector('i').classList.remove('fa-caret-right');
//         target.querySelector('i').classList.add('fa-caret-down');
//     }
// };

// --------------- Dichtklappen van Netwerk module --------------- //

export function sideBarHover(target) {
    if (window.innerWidth > 768) {
        for (let i = 0; i < target.querySelectorAll('ul > .nav-item > .nav-link > .module-netwerk-sidebar-text').length; i++) {
            target.querySelectorAll('ul > .nav-item > .nav-link > .module-netwerk-sidebar-text')[i].style.display = 'block';
        }
    }
};

export function sideBarHoverOut(target) {
    for (let i = 0; i < target.querySelectorAll('ul > .nav-item > .nav-link > .module-netwerk-sidebar-text').length; i++) {
        target.querySelectorAll('ul > .nav-item > .nav-link > .module-netwerk-sidebar-text')[i].style.display = 'none';
    }
};

// --------------- Tabs Netwerk module --------------- //

export function netwerkTab(target) {
    let ul = target.closest('ul');
    let actives = ul.querySelectorAll('.nav-item > a');

    actives.forEach((elem, index) => {
        elem.classList.remove('active');
        if (elem !== target) return;

        let tabs = elem.closest('.module-content').querySelectorAll('.tab-content .tab-pane');
        let options = {
            type: elem.getAttribute('aria-controls')
        };

        elem.classList.add('active');

        tabs.forEach(tab => tab.classList.remove('active'));
        let selectedTab = [...tabs].find(tab => tab.getAttribute('aria-labelledby') === `${options.type}-tab`);
        selectedTab.classList.add('active')
    });
};


document.addEventListener('input', (event) => {
    if (event.target.id === 'ServicepointAssetGroupId') {
        let addNewGroup = document.getElementById('addNewGroup');
        if (event.target.value === 'newGroup') {
            addNewGroup.style.display = 'flex';
        } else {
            addNewGroup.style.display = 'none';
        }
    } else {
        return;
    }
});



// --------------- Datatable --------------- //

export function placeTableDividers() {
    let tables = document.querySelectorAll('table');
    tables.forEach((elem, index) => {
        let th = elem.querySelectorAll('th');
        let tr = elem.querySelectorAll('tr');

        th.forEach((e, i) => {
            if (i != (th.length - 1)) {
                if (e.innerHTML.indexOf('<span class="module-dataTable-table-divider"></span>') != -1) {
                    return;
                } else {
                    return e.innerHTML += '<span class="module-dataTable-table-divider"></span>';
                }
            }
        });

        tr.forEach((e, i) => {
            let td = e.querySelectorAll('td');
            td.forEach((el, ind) => {
                if (ind != (td.length - 1)) {
                    if (el.innerHTML.indexOf('<span class="module-dataTable-table-divider table-divider-2"></span>') != -1) {
                        return;
                    } else {
                        return el.innerHTML += '<span class="module-dataTable-table-divider table-divider-2"></span>';
                    }
                }
            });
        });
    });
};

placeTableDividers();


// --------------- Datatable filter --------------- //

//let showFilter = (target) => {
//    if (!target) {
//       return;
//    }

//    const gridModule = $(target.closest('.grid-module'));
//    gridModule.toggleClass('advanced-search')

//    let filterBtn = $(target.querySelector('i.fas'));
//    filterBtn.toggleClass('fa-filter')
//    filterBtn.toggleClass('fa-times')
//    if ($('#quickSearch').val()) {
//        const gridCode = $(target).closest('.grid-module').attr('id');
//        grids[gridCode].debouncedReload();
//    }
//};

//let filterPreset = (target) => {
//    let filterPresetOptions = target.closest('.saveBtnOpenerGroup').querySelector('div.saveFilterOptions');

//    if (filterPresetOptions.classList.contains('module-dataTable-filter-hidden')) {
//        filterPresetOptions.classList.remove('module-dataTable-filter-hidden');
//    } else {
//        filterPresetOptions.classList.add('module-dataTable-filter-hidden');
//    }
//};

// --------------- Popup Tab Selection --------------- //

export function tabSelection(target) {
    let tabs = target.closest('ul.nav-pills').querySelectorAll('li');
    let tabContent = target.closest('form').querySelectorAll('.module-content-tab-content > div');
    tabs.forEach((elem, index) => {
        if (elem === target.parentNode) {
            if (tabContent[index].classList.contains('module-dataTable-filter-hidden')) {
                tabContent[index].classList.remove('module-dataTable-filter-hidden');
                tabContent.forEach((e, i) => {
                    if (e != tabContent[index]) {
                        e.classList.add('module-dataTable-filter-hidden');
                    }
                });
            }
        }
    });
};

export function closePopup(target) {
    //let popup = target.closest('.module-container');
    //let backdrop = document.querySelector("body > div.modal-backdrop.fade.show");
    //popup.style.display = 'none';
    //popup.classList.remove('show');
    //backdrop.remove();
    //document.body.classList.remove('modal-open');
    console.log('This function is no longer available.');
};

// --------------- Hide steps in upload popup --------------- //

export function resetDisplayNone() {
    //let hideThis = document.querySelectorAll(".module-content-tab-content > .setup-content");
    //hideThis.forEach((elem, index) => {
    //    if (index === 0) {
    //        return;
    //    } else {
    //        elem.style.display = 'none';
    //    }
    //});
/*location.reload();*/
    console.log('This function is no longer available.');
};

// --------------- Close dropdown menus, modals and popups --------------- //

// document.addEventListener('click', (e) => {
//     let visibleItem = document.querySelectorAll('.module-options-active, .show-legend-item-dropdown, .versionModal, .nav-topBar-visible, marketplace-basket-hide');
//
//     visibleItem.forEach((elem, index) => {
//         let a1 = (e.srcElement.parentNode != elem.parentNode.querySelector('a[onclick="moduleOptions(this)"]'));
//         let a2 = (e.srcElement.parentNode != elem.parentNode.querySelector('button[onclick="legendGroupOptions(this)"]'));
//         let a3 = (e.srcElement.closest('.versionModal') != elem);
//         let a4 = (!(e.srcElement.closest('.dropdown-nav') && e.srcElement.closest('.dropdown-nav').querySelector('.nav-topBar-visible')) && !e.srcElement.closest('#searchDropdownMenuautocomplete-list'));
//         let a5 = (!e.srcElement.closest('.marketplace-basket')/*?.querySelector('.marketplace-basket-list')*/);
//         let a6 = (!e.srcElement.closest('.staffoldSelector') && !e.srcElement.closest('.staffold-items'));
//         let a7 = (e.srcElement.closest('.shortcutsModal') != elem);
//
//         if (a1 && e.srcElement.parentNode.getAttribute('onclick') !== 'moduleOptions(this)') elem.classList.remove('module-options-active');
//
//         if (a2) {
//             elem.classList.remove('show-legend-item-dropdown');
//             if (elem.parentNode.querySelector('button.module-options'))
//                 elem.parentNode.querySelector('button.module-options').classList.remove('show-legend-options');
//         }
//
//         if (a3) document.getElementById('versionOverlay')?.classList.add('module-content-disable');
//         if (a4) document.querySelector('.nav-topBar-dropdown')?.classList.remove('nav-topBar-visible');
//         if (a5) {
//             let el = document.querySelector('.marketplace-basket-list');
//             if (el) el.classList.add('marketplace-basket-hide');
//         }
//         if (a6) {
//             let el = document.querySelector('.staffold-items');
//             if (el) el.classList.add('hidden');
//         }
//         if (a7) document.getElementById('shortcutsOverview')?.classList.add('module-content-disable');
//     });
// });

// --------------- Legendgroups colapse --------------- //

export function legendGroupColapse(target) {
    let groupContainer = target.closest('.legend-content-group, .settings-content-group');
    let groupHeader = target.closest('.legend-content-group-header, .settings-content-group-title');

    const  groupBody = groupContainer.querySelector('.legend-content-group-body, .settings-content-group-body')
    
    if (groupBody)
        groupBody.classList.toggle('module-content-disable');

    if (groupBody && groupBody.classList.contains('module-content-disable')) {
        groupHeader.querySelector('.legend-group-colapse, .settings-content-group-colapse').children[0].classList.remove('fa-caret-down');
        groupHeader.querySelector('.legend-group-colapse, .settings-content-group-colapse').children[0].classList.add('fa-caret-right');
        groupContainer.style = 'margin-bottom: 10px;';
    } else {
        groupHeader.querySelector('.legend-group-colapse, .settings-content-group-colapse').children[0].classList.remove('fa-caret-right');
        groupHeader.querySelector('.legend-group-colapse, .settings-content-group-colapse').children[0].classList.add('fa-caret-down');
        groupContainer.style = '';
    }
};

// --------------- Optiemenu van Legendgroup en items --------------- //

export function legendGroupOptions(target) {
    let legendDropdownMenu = target.parentNode.querySelector('.legend-item-dropdown');
    let lastLegendItem = document.querySelector('.legend-content-group:last-child .legend-content-group-body li.legend-content-group-body-item:last-child');

    if (legendDropdownMenu) {
        legendDropdownMenu.classList.toggle('show-legend-item-dropdown');
        target.classList.toggle('show-legend-options');
    }
    
    //if (target.parentNode === lastLegendItem) {
    if (lastLegendItem) lastLegendItem.style = `margin-bottom: calc(${legendDropdownMenu.offsetHeight}px + 10px)`;
    //}

    let topPosition = Math.round($(target).position().top + 25);
    let leftPosition = Math.round(($(target).position().left - legendDropdownMenu.getBoundingClientRect().width) + 25);

    legendDropdownMenu.style.top = `${topPosition}px`;
    legendDropdownMenu.style.left = `${leftPosition}px`;
};


// --------------- Settingsmenu colapse --------------- //

document.addEventListener('click', (event) => {
    if (event.srcElement.closest('.settings-content-group-title')) {
        if (document.querySelectorAll('.showSettings').length > 0) {
            document.querySelectorAll('.showSettings').forEach((elem, index) => {
                elem.classList.remove('showSettings');
            });
        }
        let items = event.srcElement.closest('.settings-content-group').querySelector('.settings-content-group-items');
        if(items) items.classList.add('showSettings');
    }
});


// --------------- Show Netwerk Group --------------- //

//let showNetwerkGroup = (target) => {
//    let legendItems = document.querySelector('.content-legend-items');
//    let legendGroupId = target.closest('.legend-content-group').getAttribute('data-group-id');
//    let contentGroup = legendItems.querySelector(`.content-groupName[data-group-id="${legendGroupId}"]`);

//    if (!target.checked) {
//        $(contentGroup).slideUp();
//    } else {
//        $(contentGroup).slideDown();
//    }
//};

export function showNetwerkGroup(target) {
    let legendItems = document.querySelector('.content-legend-items');
    let legendGroupId = target.closest('.treeview-item').getAttribute('data-asset-id');
    let contentGroup = legendItems.querySelector(`.asset[data-asset-id="${legendGroupId}"]`);
    console.log(legendItems, legendGroupId, contentGroup, $(contentGroup));
    //console.log(target.closest('.treeview-item'));

    if (!target.checked) {
        $(contentGroup).slideUp();
    } else {
        $(contentGroup).slideDown();
    }
};

// --------------- Padding fix Bootstrap tags input --------------- //

$(document).ready(function () {
    let tagContainers = document.querySelectorAll('.bootstrap-tagsinput');

    tagContainers.forEach(container => {
        let config = {
            attributes: false,
            childList: true,
            subtree: false
        };

        let callback = (mutationsList, observer) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    container.querySelector('span.tag.label.label-info') ? container.style.marginTop = '5px' : container.style.marginTop = '';
                }
            }
        };

        let observer = new MutationObserver(callback);
        observer.observe(container, config);

    });
});

// --------------- Productlist checkbox actions --------------- //

let productCheckboxes = document.querySelectorAll('.productList-overview .productList-overview-item-checkbox');

export function showCheckboxActions(target) {
    let checkboxOptions = document.querySelector('.productList-overview-options');

    if ([...productCheckboxes].filter(a => a.checked).length > 0) {
        checkboxOptions.classList.remove('hide-productList-overview-options');
    } else {
        checkboxOptions.classList.add('hide-productList-overview-options');
    }
};

productCheckboxes.forEach(checkbox => {
    checkbox.addEventListener('click', (e) => {
        showCheckboxActions(e.srcElement);
    });
});


// --------------- Marketplace filter --------------- //


export function unfoldFilter(target) {
    let list = target.parentNode.querySelector('.marketplace-filter-expendable');
    let title = target.parentNode.querySelector('a');
    let open = list && list.classList.contains('marketplace-filter-hide');

    let openCategory = () => {
        if (list) list.classList.remove('marketplace-filter-hide');
        if (title) title.classList.add('marketplace-filter-expendable-selected-title');
    };

    let closedCategory = () => {
        if (list) list.classList.add('marketplace-filter-hide');
        if (title) title.classList.remove('marketplace-filter-expendable-selected-title');
    };

    open ? openCategory() : closedCategory();
};

let filterUnfoldBtn = document.querySelectorAll('.marketplace-filter-expendable > li > a, .marketplace-filter > a');

filterUnfoldBtn.forEach(btn => {
    btn.addEventListener('click', (e) => {
        unfoldFilter(e.srcElement);
    });
});

export function openBasket(target) {
    let list = target.closest('.marketplace-basket').querySelector('.marketplace-basket-list');
    let open = list && list.classList.contains('marketplace-basket-hide');

    let openBasket = () => {
        if (list) list.classList.remove('marketplace-basket-hide');
    };

    let closedBasket = () => {
        if (list) list.classList.add('marketplace-basket-hide');
    };

    open ? openBasket() : closedBasket();

};

// --------------- Module menu navigation --------------- //



$('document').ready(() => {
    let menuSelector = document.querySelector('#menuSelector');

    if (menuSelector) {
        let menuModuleOptions = menuSelector.closest('.module-container').querySelector('.module-options-dropdown');
        let menuItems = menuSelector.querySelectorAll('.settings-content-group a[id]');
        let menuModuleItems = menuModuleOptions ? menuModuleOptions.querySelectorAll('ul li a[menuselector-item]') : null;
        let menuItemsArr = menuModuleItems ? [...menuItems].concat([...menuModuleItems]) : [...menuItems];
        let detailContainer = document.querySelector('#detailContainer');
        let detailContainerItems = menuItemsArr ? menuItemsArr.map(a => a.id + '-container') : null;
        let multipleDetailContainer = document.querySelector('#multipleDetailContainer');

        menuItemsArr.forEach(elem => {
            elem.addEventListener('click', e => {
                let container = detailContainer.closest('.module-container');
                let elemId = e.srcElement.closest('a');
                let containerId = elemId.id + '-container';
                let containerElem = document.querySelector(`#${containerId}`);
                let otherContainerIds = detailContainerItems.filter(a => a !== containerId);
                let containerTitle = container.querySelector('.module-header h5');
                let otherElemIds = menuItemsArr.filter(a => a !== elemId);

                if ((elem.getAttribute('menuselector-type') === 'group' || elem.getAttribute('menuselector-type') === 'group-item') && multipleDetailContainer) {
                    let menuSelectorItemIdElem = multipleDetailContainer.querySelector('[menuselector-item-id]');
                    let container2 = multipleDetailContainer.querySelector('.module-container');
                    //let containerTitle2 = container2.querySelector('.module-header h5');

                    menuSelectorItemIdElem.setAttribute('menuselector-item-id', containerId);
                    //containerTitle2.innerText = e.srcElement.innerText;

                    container.classList.add('hide');
                    multipleDetailContainer.classList.remove('hide');
                } else {
                    container.classList.remove('hide');
                    if (multipleDetailContainer) multipleDetailContainer.classList.add('hide');
                }

                otherContainerIds.forEach(el => {
                    let item = document.querySelector(`#${el}`);
                    if (item) item.classList.add('hide')
                });
                if (containerElem) containerElem.classList.remove('hide');
                containerTitle.innerText = e.srcElement.innerText;
                elemId.classList.add('active');
                let title = elemId.closest('.settings-content-group-title');
                if (title) title.classList.add('active');
                //!elemId.closest('.settings-content-group-body') || elemId.closest('li')?.classList.add('active');

                if (elemId.closest('.settings-content-group-body')) {
                    let li = elemId.closest('li');
                    if (li) li.classList.add('active');
                    //elemId.closest('.settings-content-group-title')?.classList.add('active');
                    let el = elemId.closest('.settings-content-group').querySelector('.settings-content-group-title');
                    if (el) el.classList.add('active');
                }

                otherElemIds.forEach(el => {
                    let item = document.querySelector(`#${el.id}`);
                    if (item){
                        item.classList.remove('active');
                        let li = item.closest('li');
                        if (li) li.classList.remove('active');
                        
                        let title = item.closest('.settings-content-group-title');
                        if (title) {
                            title.classList.remove('active');
                            if (elemId.closest('.settings-content-group-body')) {
                                title.classList.remove('active');
                            }
                        }
                        // if above is the replacement of 
                        // elemId.closest('.settings-content-group-body') || document.querySelector(`#${el.id}`)?.closest('.settings-content-group-title')?.classList.remove('active');
                    }
                });
            });
        });
    }

});




// --------------- Module menu navigation --------------- //

export function showTabConfig(target, direction) {
    let container = target.closest('.tab-content-configuration');
    let containerOfContainers = container.parentNode;
    let otherContainers = [...containerOfContainers.querySelectorAll('.tab-content-configuration')].filter(c => c !== container);
    let allContainers = containerOfContainers.querySelectorAll('.tab-content-configuration');
    let optionsElem = container.querySelector('.tab-content-configuration-options');
    let optionsHeaderElem = container.querySelector('.tab-content-configuration-options-header');

    if (direction == 'back') {
        allContainers.forEach(elem => elem.classList.remove('hide'));
        optionsElem.classList.add('hide');
        optionsHeaderElem.classList.remove('hide');
        return;
    }

    otherContainers.forEach(elem => elem.classList.add('hide'));
    optionsElem.classList.remove('hide');
    optionsHeaderElem.classList.add('hide');
};


// --------------- .shop-simulation hide siblings --------------- //

export function hideShopSimulationSiblings() {
    const shopSimulationElems = document.querySelectorAll('.shop-simulation');
    shopSimulationElems.forEach(sim => {
        sim.querySelectorAll('details').forEach(det => {
            det.addEventListener('click', e => {
                let currentDetails = e.srcElement.closest('details');
                let detailscontainer = currentDetails.parentNode;
                let allDetails = [...detailscontainer.children]
                let otherDetails = allDetails.filter(d => d !== currentDetails && d.tagName === currentDetails.tagName);
                let classToApply = 'minimized';

                if (currentDetails.classList.contains(classToApply)) return e.preventDefault();

                otherDetails.forEach(d => {
                    if (!currentDetails.hasAttribute('open')) {
                        d.classList.add(classToApply);
                    } else {
                        d.classList.remove(classToApply);
                    }
                    
                });

            });
        });
    });
};


// --------------- Insert filter buttons --------------- //

//export function insertProposalFilterBtns(submitBtn) {
//    let proposalCategoryElems = document.querySelectorAll('.proposal[data-proposal-categories]');
//    let proposalFilterContainer = document.querySelector('#proposalFilterBtns');
//    let proposalCategories = [];

//    let insertBtn = (filterOption, name, classes) => {
//        let filterButton = `<button type="button" data-proposal-filter="${filterOption}" class="content-btn content-btn-1 ${classes}">${name}</button>`;
//        return filterButton;
//    };

//    let showProposals = (proposals, currentBtn) => {
//        let allFilterBtns = document.querySelectorAll(`.content-btn[data-proposal-filter]`);
//        let otherFilterBtns = [...allFilterBtns].filter(b => b !== currentBtn);

//        canSubmit ? submitBtn.classList.remove('disabled') : submitBtn.classList.add('disabled');

//        allFilterBtns.forEach(b => {
//            if (b === currentBtn) return b.classList.remove('deselected');
//            return b.classList.add('deselected');
//        });

//        proposalCategoryElems.forEach(p => p.classList.add('hide'));
//        proposals.forEach(p => p.classList.remove('hide'));
//    };

//    proposalCategoryElems.forEach(e => {
//        let propCat = e.getAttribute('data-proposal-categories').split(' ');

//        propCat.forEach(p => {
//            if (!proposalCategories.includes(p)) proposalCategories.push(p);
//            return;
//        });

//    });

//    proposalFilterContainer.innerHTML += insertBtn('all', "All", '');
//    proposalCategories.forEach(c => proposalFilterContainer.innerHTML += insertBtn(c, c, 'deselected'));

//    document.querySelectorAll(`.content-btn[data-proposal-filter]`).forEach(btn => {
//        btn.addEventListener('click', e => {
//            let filterArg = e.srcElement.getAttribute('data-proposal-filter');
//            let filteredElems = [...proposalCategoryElems].filter(e => e.getAttribute('data-proposal-categories').split(' ').includes(filterArg));
//            let allSelected = filterArg === 'all';
//            canSubmit = filteredElems.some(p => p.classList.contains('checked'));

//            if (allSelected) {
//                canSubmit = [...proposalCategoryElems].some(p => p.classList.contains('checked'));
//                return showProposals(proposalCategoryElems, btn);
//            }

//            showProposals(filteredElems, btn);
//        });
//    });
//};

