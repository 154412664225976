import {ActiveCartQueryKey, ShoppingCartViewModel, useActiveCart} from "PlattixSalesUI/Sales/MarketplaceUtil";
import {useEffect, useState} from "react";
import {ModalBaseProps} from "PlattixUI/core/components/Modal";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {PlattixAutoFormModal, PlattixSubmitField, PlattixSubmitFormModal} from "PlattixUI/core/components/form/Form";
import {doPost, isHttpError, usePlattixQuery} from "PlattixUI/PlattixReactCore/api/Api";
import {Endpoints} from "configuration/ApiEnpointMap";
import {SelectOption} from "PlattixUI/core/components/form/Select";
import {useQueryClient} from "@tanstack/react-query";
import {PlattixSwal} from "PlattixUI/core/components/Swal";
import {styled} from "goober";

export interface ChangeShoppingCartModel {
    newServicepointId: number | null;
    newCustomerAccountId: number | null;
    newShareCode: string;
    force: boolean;
}

interface AlternativeWebShopParameters {
    alternativeServicepoints: SelectOption[],
    alternativeCustomerAccounts: SelectOption[],
}

export function WebshopSelector() {

    const activeCart = useActiveCart()
    const cart = activeCart.data;

    const [showChangeSpModal, setShowChangeSpModal] = useState(false);
    const [showChangeAccountModal, setShowChangeAccountModal] = useState(false);
    const [showChangeShareCodeModal, setShowChangeShareCodeModal] = useState(false);

    // const alternativeParamsQuery = usePlattixQuery<AlternativeWebShopParameters>(['Shop', 'AlternativeShopParams'], Endpoints.Marketplace.AlternativeParams())

    // useEffect(() => {
    //     console.log(`cart:`, cart);
    // }, [cart]);

    // const hasServicepoint = alternativeParamsQuery.data?.alternativeServicepoints.find(as => as.value !== -1);
    //
    // useEffect(() => {
    //     console.log(`alternativeParamsQuery.data:`, alternativeParamsQuery.data);
    //     console.log(`alternativeParamsQuery.data?.alternativeServicepoints`, /*hasServicepoint,*/ alternativeParamsQuery.data?.alternativeServicepoints);
    //     console.log(`alternativeParamsQuery.data?.alternativeCustomerAccounts`, alternativeParamsQuery.data?.alternativeCustomerAccounts);
    // }, [alternativeParamsQuery.data]);

    return (
        <>
            <WebshopSelectorContainer>
                {}
                <MenuButton
                    title={t('Servicepoint')}
                    value={cart?.servicepointName}
                    button={{title: t('edit'), onClick: () => setShowChangeSpModal(true)}}
                />

                {/*<hr />*/}

                <MenuButton
                    title={t('Account')}
                    value={cart?.customerAccountName}
                    button={{title: t('edit'), onClick: () => setShowChangeAccountModal(true)}}
                />

                <MenuButton
                    title={t('ShareCode')}
                    value={cart?.pricestructureShareCode}
                    button={{title: t('edit'), onClick: () => setShowChangeShareCodeModal(true)}}
                />

                <MenuButton
                    title={t('ShopOwner')}
                    value={cart?.ownerAccountName}
                />
            </WebshopSelectorContainer>
    
            <ChangeSpModal show={showChangeSpModal} onClose={() => setShowChangeSpModal(false)}/>
            <ChangeAccountModal show={showChangeAccountModal} onClose={() => setShowChangeAccountModal(false)}/>
            <ChangeShareCodeModal show={showChangeShareCodeModal} onClose={() => setShowChangeShareCodeModal(false)}/>
        </>
    );
}

interface ChangeWebShopParamModalParams extends ModalBaseProps {
    fields: PlattixSubmitField<ChangeShoppingCartModel>[],
    title: string,
    url: string
}

function ChangeSpModal(props: ModalBaseProps) {
    const query = usePlattixQuery<AlternativeWebShopParameters>(['Shop', 'AlternativeShopParams'], Endpoints.Marketplace.AlternativeParams())

    const fields: PlattixSubmitField<ChangeShoppingCartModel>[] = [
        {
            name: "newServicepointId",
            type: "select",
            options: query.data?.alternativeServicepoints ?? []
        }
    ]

    return <ChangeWebShopParamModal
        {...props}
        url={Endpoints.Marketplace.ChangeServicepoint()}
        title={t('Shop.ChangeSp')}
        fields={fields} 
    />
}
function ChangeAccountModal(props: ModalBaseProps) {
    const query = usePlattixQuery<AlternativeWebShopParameters>(['Shop', 'AlternativeShopParams'], Endpoints.Marketplace.AlternativeParams())

    const fields: PlattixSubmitField<ChangeShoppingCartModel>[] = [
        {
            name: "newCustomerAccountId",
            type: "select",
            options: query.data?.alternativeCustomerAccounts ?? []
        }
    ]

    return <ChangeWebShopParamModal
        {...props}
        url={Endpoints.Marketplace.ChangeCustomerAccount()}
        title={t('Shop.ChangeAccount')}
        fields={fields} 
    />
}

function ChangeWebShopParamModal(props: ChangeWebShopParamModalParams) {
    const query = usePlattixQuery<AlternativeWebShopParameters>(['Shop', 'AlternativeShopParams'], Endpoints.Marketplace.AlternativeParams())
    
    const queryClient = useQueryClient();

    function onSuccess(model: ShoppingCartViewModel) {
        queryClient.setQueryData([ActiveCartQueryKey], model)
    }

    return <PlattixSubmitFormModal<ChangeShoppingCartModel, any>
        {...props}
        postUrl={props.url} 
        title={props.title}
        fields={props.fields} loading={query.isLoading}
        onSuccess={onSuccess}
    />
}

function ChangeShareCodeModal(props: ModalBaseProps) {
    const [loading, setLoading] = useState(false);

    const fields: PlattixSubmitField<ChangeShoppingCartModel>[] = [
        {
            name: "newShareCode",
            type: "text"
        },
        {
            name: "force",
            type: "hidden",
        },
    ]
    const queryClient = useQueryClient();
    
    async function onSubmit(model: ChangeShoppingCartModel){
        setLoading(true)
        let result = await doPost<ShoppingCartViewModel>(Endpoints.Marketplace.ChangeShareCode(), model)
        setLoading(false)
        
        if (isHttpError(result)){
            
            if (result.status === 409 /*conflict*/){
                return PlattixSwal({
                    title: result.title,
                    showConfirmButton: true,
                    preConfirm: () => onSubmit({...model, force: true}), // resubmit with force
                })
            }
            
            return PlattixSwal({
                title: result.title,
                showConfirmButton: true
            });
        }
        
        queryClient.setQueryData([ActiveCartQueryKey], result)
        props.onClose();
    }

    return <PlattixAutoFormModal
        {...props}
        title={t('Shop.ChangeAccount')}
        fields={fields}
        onSubmit={onSubmit}
        loading={loading}
        closeOnOutsideClick={false}
    />
}

export interface MenuButtonProps {
    title: string,
    value?: string,
    button?: {
        title: string,
        onClick: () => void,
    },
}

export function MenuButton(props: MenuButtonProps) {
    return (
        <MenuButtonContainer>
            <MenuButtonHeader>
                <h5>{props.title}</h5>
                {!!props.button &&
                    <p onClick={props.button.onClick}>{props.button.title}</p>
                }
            </MenuButtonHeader>
            
            {!!props.value &&
                <MenuButtonBody>
                    <p>{props.value}</p>
                </MenuButtonBody>
            }
            
        </MenuButtonContainer>
    );
}

export const MenuButtonContainer = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
    `;
});

export const MenuButtonHeader = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        gap: 5px;
        align-items: center;
        
        h5 {
            margin-right: auto;
            font-weight: 700;
            font-size: 15px;
        }
        
        p {
            cursor: pointer;
        
            &:hover {
                color: var(--styleColor1);
            }
        }
    `;
});

export const MenuButtonBody = styled('div')(() => {
    return `
        width: 100%;
    `;
});

export const WebshopSelectorContainer = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        gap: 10px;
    `;
});