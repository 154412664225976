import {PermissionedType} from "configuration/ApiEnpointMap";


export const BaseApiEndpoints = {
    Account: {
        Login: "/Account/Login",
        ReLogin: "/Account/ReLogin",
        Logout: "/Account/Logout",
        Register: "/Account/Register",
        Forgot2FA: "/Account/Forgot2FA",
        ResetPassword: "/Account/ResetPassword",
        ResendVerification: "/Account/ResendEmailVerification",
        ForgotPassword: "/Account/ForgotPassword",
        UpdateLanguage: "/Account/UpdateLanguage"
    },
    Feedback: "/Feedback/SendFeedbackMail",
    Roles: {
        Entity: '/Role/Entity'
    },
    Invitations: {
        Get: url => `/Invitation/${url}`,
        Edit: (url: string) => `/Invitation/${url}`,
        Delete: (url: string) => `/Invitation/${url}`,
        Resend: (url: string) => `/Invitation/${url}/Resend`,
        Revoke: (url: string) => `/Invitation/${url}/Revoke`,
        Reopen: (url: string) => `/Invitation/${url}/Reopen`,
        Accept: (url: string) => `/Invitation/${url}/Accept`,
        Reject: (url: string) => `/Invitation/${url}/Reject`,

        GetForUser: '/Invitation',
        Create: (type: PermissionedType, id: number) => `/Invitation/${type}/${id}`,
    },
    Translate: (sourceLang, targetLang) => `/Translate/${sourceLang}/${targetLang}`,
    Grid: {
        Get: (gridId: number) => `/Grid/${gridId}`,
        Delete: (gridId: number) => `/Grid/${gridId}`,
        UpdateOrder: (gridId: number) => `/Grid/${gridId}/UpdateOrder`,
        ColumnTypes: `/Grid/ColumnTypes`,
        Columns: (gridId: number) => `/Grid/${gridId}/Columns`,
        Column: (gridId: number, columnId: number | null) => `/Grid/${gridId}/Column/` + (columnId ? String(columnId) : ''),
        StoredProcedures: `/Grid/GetStoredProcedures`,
        StoredProcedureColumns: `/Grid/StoredProcedure/Columns`,
        ImportColumns: (gridCode: string) => `/Grid/ImportColumns/${gridCode}`,
    },
    Codes: {
        List: (schema: string, table: string) => `/Code/Schema/${schema}/Table/${table}`,
        EditCode: (schema: string, table: string, code) => `/Code/Schema/${schema}/Table/${table}/Code/${code}`,
        DeleteCode: (schema: string, table: string, code) => `/Code/Schema/${schema}/Table/${table}/Code/${code}`,
    }
}