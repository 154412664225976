import {Page, PageHeader} from "PlattixUI/core/Header";
import {ContentCardButtons, PlattixCard, PlattixCardContainer} from "PlattixUI/core/components/ContentCard";
import {
    PlattixCheckbox,
    PlattixInput,
    PlattixSubmitButton,
    PlattixValidatedInput
} from "PlattixUI/core/components/form/Input";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {PlattixMultiStepForm, PlattixMultiStepFormStep} from "PlattixUI/core/components/MultiStepForm";
import {AddressComponent} from "PlattixUI/core/components/AddressComponent";
import {useFieldArray, useForm, UseFormReturn} from "react-hook-form";
import {PlattixCodeFormSelect} from "PlattixUI/core/components/form/Select";
import React, {Fragment, useEffect, useState} from "react";
import {doPost, isHttpError, useHostUrl, usePlattixMutation, usePlattixQuery} from "PlattixUI/PlattixReactCore/api/Api";
import {PlattixDataGrid} from "PlattixUI/core/grid/PlattixDataGrid";
import {GridRowOptions} from "PlattixUI/core/grid/gridStyling/GridOptions";
import {PlattixForm} from "PlattixUI/core/components/form/Form";
import {AccountModel} from "PlattixUI/types/AccountTypes";
import {PlattixSwal} from "PlattixUI/core/components/Swal";
import {LoadingScreen} from "PlattixUI/core/components/Loader";
import {useCodesQuery} from "PlattixUI/util/CodesUtil";
import {Iban, Required, RequiredIf, VatNumber} from "PlattixUI/core/forms/FormValidators";
import {HttpError} from "PlattixUI/PlattixReactCore/CoreTypes";
import {toast} from "react-toastify";
import {DeleteIconButton, EditButton} from "PlattixUI/core/components/Buttons";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";

export interface AccountDataComponentProps {
    isReadonly?: boolean,
    form: any,
    isValid?: any,
    error?: HttpError,
}

export interface CreateAccountWizardProps {
    postCallback?: string,
    onCancel?: any,
    /**
     * Function that is called when account is successfully created
     * @param accountId: the accountId of the newly created account
     */
    onSuccess?: (accountId: number, account: AccountModel) => void
}

export interface AccountComponentProps {
    accountId: number;
    pageHeader?: string;
    standalone?: boolean;
    hidebank?: boolean;
}

export interface AccountsComponentProps {

}

export enum enumAccountType {
    EnergyCommunity = "2",
    Private = "3",
    Company = "4",
    PublicInstitution = "5",
    ChargingStation = "6"
}

export function AccountTypeDataComponent(props: AccountDataComponentProps) {
    const form = props.form;
    const {register, watch} = form;
    const accountTypeCode = watch("accountTypeCode");

    useEffect(() => {
            form.trigger("accountTypeCode").then();
        }, [accountTypeCode, form]
    )

    return (
        <>
            <div className="form-group row form-group-center accountTypeSelector">
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        value={enumAccountType.Private}
                        id={'PrivateAccount'}
                        {...register("accountTypeCode", {required: true})}
                    />
                    <label
                        className="form-check-label"
                        htmlFor={'PrivateAccount'}
                    >
                        {t("PrivateAccount")}
                    </label>
                </div>
                <div className="form-check custom-radio">
                    <input
                        className="form-check-input"
                        type="radio"
                        value={enumAccountType.Company} {...register("accountTypeCode", {required: true})}
                        id={'CompanyAccount'}
                    />
                    <label
                        className="form-check-label"
                        htmlFor={'CompanyAccount'}
                    >
                        {t("CompanyAccount")}
                    </label>
                </div>
            </div>
        </>
    )
}

AccountGeneralDataComponent.defaultValues = {
    deliveryAddressSame: true,
    invoiceAddressSame: true
}

export function AccountGeneralDataComponent(props: AccountDataComponentProps) {
    const form = props.form;
    const {register, setValue, getValues, watch} = form;
    const accountTypeCode = watch("accountTypeCode");
    const deliveryAddressSame = watch("deliveryAddressSame");
    const invoiceAddressSame = watch("invoiceAddressSame");
    const [initialized, setInitialized] = useState<Boolean>(false);

    useEffect(() => {
            if (!initialized) {
                setValue("deliveryAddressSame", getValues("deliveryAddressSame"));
                setValue("invoiceAddressSame", getValues("invoiceAddressSame"));
                setInitialized(true);
            }
        }, [initialized, setValue]
    )

    return (
        <>

            <fieldset>
                <legend>
                    <h5>{t('Account.Settings.PersonalData')}</h5>
                </legend>
                {(accountTypeCode === enumAccountType.Private) &&
                    <PlattixInput
                        register={register("firstName")}
                        label={"FirstName"}
                        readOnly={props.isReadonly}
                    />
                }

                <PlattixValidatedInput<AccountModel>
                    label={"Name"}
                    formHook={form}
                    name={'name'}
                    validation={Required()}
                    readOnly={props.isReadonly}
                />

                <PlattixCodeFormSelect<AccountModel>
                    name="languageCode"
                    form={form}
                    label="Language"
                    namespace={"DataAccessLayer.Data"}
                    tableName={"Language"}
                />
            </fieldset>

            <fieldset>
                <legend>
                    <h5>{t('Account.Settings.Servicepoint')}</h5>
                </legend>
                <AddressComponent form={form} addressModel={"mailingAddress"} isReadonly={props.isReadonly}/>
            </fieldset>

            <fieldset>
                <legend>
                    <h5>{t('Account.Settings.ShippingAddress')}</h5>
                </legend>

                <PlattixCheckbox
                    register={register("deliveryAddressSame")}
                    label={"RequestController.DeliveryAddressQuestion"}
                />

                {(!deliveryAddressSame) &&
                    <AddressComponent
                        form={form}
                        addressModel="deliveryAddress"
                    />
                }
            </fieldset>

            <fieldset>
                <legend>
                    <h5>{t('Account.Settings.InvoiceAddress')}</h5>
                </legend>

                <PlattixCheckbox
                    register={register("invoiceAddressSame")}
                    label={"RequestController.InvoiceAddressQuestion"}
                />
                {(!invoiceAddressSame) &&
                    <AddressComponent form={form} addressModel="invoiceAddress"/>
                }
            </fieldset>

        </>
    )
}

export function AccountCompanyDataComponent(props: AccountDataComponentProps) {
    const form = props.form;
    const {register} = form;

    return (
        <fieldset>
            <legend>
                <h5>{t('Account.Settings.CompanyData')}</h5>
            </legend>
            <PlattixValidatedInput<AccountModel>
                name={"customer.companyNumber"} label={t("CompanyNumber")} formHook={form}
                validation={[RequiredIf('accountTypeCode', {
                    value: enumAccountType.Company,
                    errorMessage: t('Account.Error.RequiredForCompany')
                })]}
                error={props.error}
            />
            <PlattixValidatedInput<AccountModel>
                name={"customer.vatNumber"} formHook={form} label={t("VatNumber")}
                validation={[VatNumber(), RequiredIf('accountTypeCode', {
                    value: enumAccountType.Company,
                    errorMessage: t('Account.Error.RequiredForCompany')
                })]}
                error={props.error}
            />
            <PlattixCodeFormSelect<AccountModel> name="customer.vatTypeCode" form={form} label={"VatType"}
                                                 namespace={"DataAccessLayer.Data"}
                                                 tableName={"Vattype"}
            />
            <PlattixCodeFormSelect<AccountModel> name="customer.paymentTypeCode" form={form} label={"PaymentType"}
                                                 namespace={"DataAccessLayer.Data"} tableName={"PaymentType"}/>
            <PlattixCodeFormSelect<AccountModel> name="customer.invoiceFrequencyCode"
                                                 form={form}
                                                 label={"Contract.Contract.InvoiceFrequency"}
                                                 namespace={"DataAccessLayer.Data"}
                                                 tableName={"InvoiceFrequency"}/>
            <PlattixInput name="FixedDueDate" register={register("customer.fixedDueDate")}
                          label={t("FixedDueDate")}
                          error={props.error}
            />
        </fieldset>
    )
}

export function AccountCommunicationDataComponent(props: AccountDataComponentProps) {
    const form = props.form;
    const {register, getValues} = form;
    useEffect(() => {
        console.log(`form:`, form, form.getValues(), getValues());
    }, [form]);

    const communicationTypesQuery = useCodesQuery('AccountCommunicationType');
    const communicationTypes = communicationTypesQuery.data ?? [];

    return (
        <fieldset>
            <legend>
                <h5>{t('Account.Settings.Communication')}</h5>
            </legend>
            {!getValues.accountCommunications &&
                communicationTypes.map((x, index) => {
                        return (
                            <div key={x.value.toString()}>
                                <PlattixInput type="hidden"
                                              register={register(`accountCommunications[${index}].accountCommunicationTypeCode`, {value: x.value})}
                                              label={x.value.toString()}/>
                                <PlattixInput register={register(`accountCommunications[${index}].value`)} label={x.label}/>
                            </div>
                        )
                    }
                )
            }
            {!!getValues.accountCommunications && communicationTypes.map((x, index) => {
                const element = getValues().accountCommunications?.findIndex(element => element.accountCommunicationTypeCode === x.value);

                if (!element) return (
                    <Fragment key={x.value.toString()}></Fragment>
                );

                return (
                    <div key={x.value.toString()}>
                        <PlattixInput
                            type="hidden"
                            register={register(`accountCommunications[${element}].value`, {value: x.value})}
                            label={x.label.toString()}
                        />

                        {/*<PlattixInput register={register(`accountCommunications[${index}].value`)} label={x.label}/>*/}

                        <PlattixInput
                            register={register(`accountCommunications[${element}].value`)}
                            label={x.label}
                        />
                    </div>
                )
            })}
        </fieldset>
    )
}


type AccountBankAccountComponentProps = {
    form: UseFormReturn<AccountModel, object>
}

export function AccountBankAccountComponent(props: AccountBankAccountComponentProps) {

    const fieldArray = useFieldArray<AccountModel>({
        control: props.form.control,
        name: "bankAccountNumbers"
    })

    return (
        <fieldset>
            <legend>
                <h5>{t('Account.Settings.BankingAccount')}</h5>
            </legend>

            {
                fieldArray.fields.map((field, index) =>
                    <React.Fragment key={field.id}>
                        <PlattixValidatedInput<AccountModel>
                            type={'hidden'}
                            formHook={props.form} name={`bankAccountNumbers.${index}.accountBankAccountNumberId` as const}/>

                        <PlattixValidatedInput<AccountModel>
                            label={'bankAccountNumber'}
                            validation={[Required(), Iban()]}
                            formHook={props.form} name={`bankAccountNumbers.${index}.bankAccountNumber` as const}/>
                        <PlattixValidatedInput<AccountModel>
                            label={'bicCode'}
                            formHook={props.form} readOnly
                            name={`bankAccountNumbers.${index}.bankingInstitutionCode` as const}/>
                        <PlattixValidatedInput<AccountModel>
                            label={'Bank'}
                            formHook={props.form} readOnly
                            name={`bankAccountNumbers.${index}.bankingInstitutionDescription` as const}/>
                        <PlattixValidatedInput<AccountModel>
                            label={'showOnInvoice'}
                            formHook={props.form}
                            name={`bankAccountNumbers.${index}.showOnInvoice` as const}
                            type={"checkbox"}
                        />

                        <DeleteIconButton onClick={() => fieldArray.remove(index)} />
                    </React.Fragment>
                )
            }

            <EditButton icon={faPlus} onClick={() => fieldArray.append({accountBankAccountNumberId: -1})}>{t('account.bankAccountNumber.Add')}</EditButton>

        </fieldset>
    )
}

export function CreateAccountWizard(props: CreateAccountWizardProps) {
    const form = useForm<AccountModel>({
        mode: "all",
        defaultValues: {
            languageCode: 'NL',
            deliveryAddressSame: true,
            invoiceAddressSame: true,
        }
    });
    const {register, handleSubmit, watch} = form;
    const accountTypeCode = watch("accountTypeCode");
    const [initialized, setInitialized] = useState<boolean>(false);
    const TactixUrl = useHostUrl("Tactix");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<HttpError | null>(null);

    useEffect(() => {
        if (!initialized) {
            register("extended", {value: true});
            setInitialized(true);
        }
    }, [initialized, register]);

    const onSubmit = async (data) => {
        setLoading(true)
        // Only called when formstate is valid

        const response: any = await doPost<AccountModel>(`${TactixUrl}/api/Accounts/Save`, data, {remote: true})
        if (isHttpError(response)) {
            setError(response);
            setLoading(false)
            return;
        }
        setError(null)
        console.log(`response`, response);

        if (props.postCallback && response.accountId) {
            const url = `${props.postCallback}&accountid=${response.accountId}`
            const postCallbackResponse = await doPost(url, undefined, {remote: true})
            if (isHttpError(postCallbackResponse)) {
                await PlattixSwal({
                    icon: 'error',
                    text: postCallbackResponse.detail,
                });
                setLoading(false)
                return;
            }
        }

        props.onSuccess?.(response.accountId, response);
        toast.success(t('Account.Creation.Successful.Msg'))
        setLoading(false);
    }

    return (
        <>
            <PlattixCard header={t("Account.Create.New")}>
                <PlattixMultiStepForm onSubmission={handleSubmit(onSubmit)} onCancel={props.onCancel} loading={loading}
                                      form={form as any}>
                    <PlattixMultiStepFormStep step={1} title="Account.ChooseType">
                        <AccountTypeDataComponent form={form}/>
                    </PlattixMultiStepFormStep>

                    {(accountTypeCode) &&
                        <PlattixMultiStepFormStep step={2} title="Account.General">
                            <AccountGeneralDataComponent form={form}/>
                        </PlattixMultiStepFormStep>
                    }

                    {(accountTypeCode) &&
                        <PlattixMultiStepFormStep step={3} title="Account.Communications">
                            <AccountCommunicationDataComponent form={form}/>
                        </PlattixMultiStepFormStep>
                    }

                    {(accountTypeCode === enumAccountType.Company) &&
                        <PlattixMultiStepFormStep step={4} title={"Account.Customer"}>
                            <AccountCompanyDataComponent form={form}/>
                        </PlattixMultiStepFormStep>
                    }

                </PlattixMultiStepForm>
            </PlattixCard>
        </>
    )
}

export function AccountComponent(props: AccountComponentProps) {
    const form = useForm<AccountModel>({ mode: 'all'});
    const {handleSubmit, watch, reset} = form;
    const [submitting, setSubmitting] = useState(false);
    const accountTypeCode = watch("accountTypeCode");
    const TactixUrl = useHostUrl("Tactix");

    const queryEnabled = (!!TactixUrl && !!props.accountId)

    const queryKey = ['AccountQuery', props.accountId];
    const url = `${TactixUrl}/api/Accounts/${props.accountId}`

    const accountQuery = usePlattixQuery<AccountModel>(
        queryKey,
        url,
        {},
        {
            enabled: queryEnabled
        },
        {
            remote: true
        }
    );

    const onSuccesHandler = () => {
        toast.success(t("Saved"))
    };

    const accountMutation = usePlattixMutation(queryKey, url, {},{
        onSuccess: onSuccesHandler
    },{
        remote: true
    })

    const account = accountQuery.data;
    useEffect(() => {
        reset(account);
    }, [account, reset]);

    const onSubmit = async (data) => {
        console.log(`data`, data, accountMutation);
        await accountMutation.mutateAsync(data)

        if (accountMutation.isSuccess) window.location.reload();
        //
        // setSubmitting(true)
        // doPost(`${TactixUrl}/api/Accounts/Save`, data, {remote: true}).then((result: any) => {
        //
        //     if (!isHttpError(result)) {
        //         toast.success(t("Saved"));
        //     } else {
        //         PlattixSwal({
        //             icon: 'error',
        //             text: result.detail,
        //         });
        //     }
        // })
        // setSubmitting(false)
    }

    if (accountQuery.isLoading) return <LoadingScreen/>;

    const component = (
        <>
            <PlattixCard header={t("Account")} width={'span2'}>
                <PlattixForm id="AccountForm" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <AccountTypeDataComponent form={form}/>

                    <AccountGeneralDataComponent form={form}/>

                    {(accountTypeCode === enumAccountType.Company) &&
                        <AccountCompanyDataComponent form={form}/>
                    }

                    <AccountCommunicationDataComponent form={form}/>
                    {!props.hidebank && <AccountBankAccountComponent form={form}/>}

                    <ContentCardButtons>
                        <PlattixSubmitButton
                            name={t("Submit")}
                            form="AccountForm"
                            onSubmit={handleSubmit(onSubmit)}
                            loading={accountMutation.isLoading}
                        />
                    </ContentCardButtons>
                </PlattixForm>
            </PlattixCard>
        </>
    );

    if (props.standalone) return (
        <Page title={t('Account')}
              loading={queryEnabled && accountQuery.isLoading} error={accountQuery.error ?? accountMutation.error}
              backButton
        >
            <PlattixCardContainer>
                {component}
            </PlattixCardContainer>
        </Page>
    );
    return component;
}

export function AccountsComponent(props: AccountsComponentProps) {
    const rowOptions: GridRowOptions = [
        {
            href: (rowData) => `/Account/${rowData.id}`,
            label: t("Action.Edit")
        },
        {
            onClick: (rowData) => alert("delete id " + rowData.id.toString() + " not implemented"),
            label: t("Action.Delete")
        }
    ]

    return (
        <>
            <PageHeader title={t("Accounts")}/>
            <PlattixCard header={"EnergyCommunity.Accounts.Header"}>
                <PlattixDataGrid gridCode={"Accounts"} parameters={{
                    "paramlanguagecode": "NL",
                    "paramwhereclause": "",
                    "param_user_id": "123"
                }} rowOptions={rowOptions}/>
            </PlattixCard>
        </>
    )
}

