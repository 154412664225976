import React, {PropsWithChildren, useState} from 'react';
import {SpeedDial, SpeedDialAction, SwipeableDrawer, useMediaQuery} from "@mui/material";
import {css, styled} from "goober";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {t} from "../../PlattixReactCore/i18n";

export const bodyPaddingX = '5vw';
export const ResponsiveDrawerMenuGap = '7px';

export const deviceDimensions = {
    desktop: 'only screen and (min-width: 1151px)',
    tablet: 'only screen and (min-width: 421px) and (max-width: 1150px)',
    mobile: 'only screen and (max-width: 420px)',
};

export const drawerBleeding = 50;

export type ResponsiveDevices = 'desktop' | 'tablet' | 'mobile';

export interface ResponsiveComponentProps {
    // devices: string[],
    devices: ResponsiveDevices[],
}

export function ResponsiveComponent(props: React.PropsWithChildren<ResponsiveComponentProps>) {

    // const theme = useTheme();
    
    const devices = {
        // desktop: useMediaQuery(theme.breakpoints.up('lg')),
        // tablet: useMediaQuery(theme.breakpoints.up('sm')),
        // mobile: useMediaQuery(theme.breakpoints.up('xs')),
        mobile: useMediaQuery(deviceDimensions.mobile),
        tablet: useMediaQuery(deviceDimensions.tablet),
        desktop: useMediaQuery(deviceDimensions.desktop),
    };
    
    const currentDevice = Object.keys(devices).find(key => devices[key]) as ResponsiveDevices;
    
    const hasDevice = () => {
        // if (!!props.devices.length) return false;
        if (currentDevice === undefined) return false;
        return props.devices.includes(currentDevice);
    };

    // if (hasDevice()) return <>props.children</>
    // else return <>niets</>

    return (
        <>
            {hasDevice() &&
                props.children
            }
        </>
    );
}

export const swipeableDrawerStyling = css`
    .MuiPaper-root {}
`;

const PullerContainer = styled('div')`
    width: 100%;
    height: fit-content;
    padding: 25px ${bodyPaddingX} 0 ${bodyPaddingX};
    margin-bottom: 15px;
    
    h5 {
        margin-bottom: 10px;
        text-align: center;
    }
`;

const Puller = styled('div')`
    width: 30px;
    height: 6px;
    background: #AAA;
    border-radius: 3px;
    position: absolute;
    top: 8px;
    left: calc(50% - 15px);
`;

const ResponsiveDrawerMenu = styled('div')`
    width: 100vw;
    height: fit-content;
    min-height: 20vh;
    max-height: 80vh;
    padding: 0 ${bodyPaddingX} 50px ${bodyPaddingX};
    display: flex;
    flex-flow: column wrap;
    gap: 15px;
    
    & > a, & > p {
        min-height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0;
        padding: 0;
        font-size: 1.3em;
        color: var(--textColorDark);
        border-radius: 10px;
        
        &.active {
            background: var(--backgroundColor3);
        }
    }
`;

export interface ResponsiveSwipeDrawerProps {
    devices: ResponsiveDevices[],
    title?: string,
    open: boolean,
    onClose: () => void,
    onOpen: () => void,
    // disableSwipeToOpen?: boolean,
}

// export const InvalidResponsiveSwipeDrawerProps = ['devices', 'title', 'name', 'onClose', 'onOpen', 'open']

export function ResponsiveSwipeDrawer(props: PropsWithChildren<ResponsiveSwipeDrawerProps>) {
    
    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

    return (
        <ResponsiveComponent devices={props.devices}>
            <SwipeableDrawer
                anchor={'bottom'}
                disableSwipeToOpen={true}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                open={props.open}
                onClose={props.onClose}
                onOpen={props.onOpen}
                // transitionDuration={200}
                ModalProps={{
                    keepMounted: true,
                }}
                className={swipeableDrawerStyling}
            >
                <PullerContainer>
                    <Puller />
                    <h5>{props.title}</h5>
                    <hr />
                </PullerContainer>

                <ResponsiveDrawerMenu>
                    {props.children}
                </ResponsiveDrawerMenu>
            </SwipeableDrawer>
        </ResponsiveComponent>
    );
}

const speedDialStyling = css`
    position: fixed;
    bottom: 75px;
    right: ${bodyPaddingX};
    
    .MuiSpeedDial-fab {
        background: var(--styleColor1);
        
        &:hover {
            background: var(--styleColor1);
        }
        
        svg {
            height: 16px;
        }
    }
        
    .MuiSpeedDialAction-staticTooltipLabel {
        min-width: fit-content;
    }
`;

export type ResponsiveSpeedDialActionsProps = {
    icon: React.ReactNode;
    name: string,
    onClick: () => void,
    id?: string,
};

export interface ResponsiveSpeedDialProps {
    actions: ResponsiveSpeedDialActionsProps[],
}

export function ResponsiveSpeedDial(props: ResponsiveSpeedDialProps) {
    const [open, setOpen] = useState(false);
    
    const handleClose = () => {
        setOpen(false);
    }
    
    const handleOpen = () => {
        setOpen(true);
    }

    return (
        <ResponsiveComponent devices={['mobile', 'tablet']}>
            {/*<Backdrop open={open} />*/}
            <SpeedDial
                ariaLabel={t('PageBtns')}
                icon={<FontAwesomeIcon icon="pencil" />}
                onClose={handleClose}
                onOpen={handleOpen}
                className={speedDialStyling}
            >
                {props.actions.map((action) => {
                    const handleClick = () => {
                        handleClose();
                        action.onClick();
                        console.log('closing')
                    };
                    return (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            tooltipOpen
                            onClick={handleClick}
                            id={action.id ? action.id : ''}
                        />
                    );
                }
                )}
            </SpeedDial>
        </ResponsiveComponent>
    );
}
