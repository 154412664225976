import {FieldPath, Path, PathValue, ValidateResult} from "react-hook-form";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {isValidEmail} from "PlattixUI/util/EmailUtil";
import React from 'react';
import {RemoteValidator} from "PlattixUI/core/forms/Validators/RemoteValidator";
import {Validator} from "PlattixUI/core/forms/Validators/Validator";
import {UniqueValidator} from "PlattixUI/core/forms/Validators/UniqueValidator";
import {isShowIfValue, ShowIfType} from "PlattixUI/core/components/form/Input";
import {isValidDate} from "PlattixUI/util/DateUtil";
import {IbanValidator} from "PlattixUI/core/forms/Validators/IbanValidator";


export type ValidatorInput = number | string | undefined | null

class RequiredValidator<TFieldValues> extends Validator<TFieldValues> {
    private readonly allowZero: boolean;

    public name = "required"

    constructor(allowZero: boolean) {
        super();
        this.allowZero = allowZero;
        this.message = t('Validation.Error.Required')
    }

    validate(value: PathValue<TFieldValues, Path<TFieldValues>>): ValidateResult {
        if (!this.form || !this.field) throw new Error("Form and/or field not set");

        if (this.props?.type === 'checkbox') return true
        if (
            this.props?.type === 'datetime-local'
            || this.props?.type === 'date'
            || this.props?.type === 'time'
        
        ) {
            return isValidDate(value)
        }

        if (this.allowZero && (value === '0' || value === 0)) return true;

        return !!value;
    }
}

class SameAsValidator<TFieldValues> extends Validator<TFieldValues> {
    private readonly other: FieldPath<TFieldValues>;

    public name = 'sameas'

    constructor(other: FieldPath<TFieldValues>, otherLabel?: string) {
        super();
        this.other = other;
        this.message = t('Validation.Error.SameAs', {other: otherLabel ?? other})
    }

    validate(value: PathValue<TFieldValues, Path<TFieldValues>>): ValidateResult | Promise<ValidateResult> {
        if (!this.form || !this.field) throw new Error("Form and/or field not set");

        const otherValue = this.form.getValues(this.other);

        return value === otherValue;
    }
}

class RequiredIfValidator<TFieldValues> extends Validator<TFieldValues> {
    private readonly requiredIf: ShowIfType<TFieldValues>;
    private readonly allowZero: boolean;
    private readonly value: string | number | boolean;

    public name = 'requiredIf'

    constructor(requiredIf: ShowIfType<TFieldValues>, allowZero: boolean, otherLabel?: string, errorMessage?: string, value?: string | number | boolean) {
        super();
        this.requiredIf = requiredIf;
        this.allowZero = allowZero;
        this.value = value ?? true;
        this.message = errorMessage ?? t('Validation.Error.Required')
    }

    isRequired(showIf: ShowIfType<TFieldValues>) : boolean {
        if (Array.isArray(showIf)) {
            return showIf.flatMap(v => this.isRequired(v)).every(v => v) ;
        }

        if (showIf === undefined ) return false;
        if (typeof showIf === 'boolean') return showIf;
        if (isShowIfValue(showIf)){
            const value = this.form?.getValues(showIf.field);
            // eslint-disable-next-line eqeqeq
            if (value != showIf.value){
                return true
            }
            return false;
        }
        // showIF is of type FieldPath
        return !!this.form?.getValues(showIf);
    }


    validate(value: PathValue<TFieldValues, Path<TFieldValues>>): ValidateResult | Promise<ValidateResult> {
        if (!this.form || !this.field) throw new Error("Form and/or field not set");

        const required = this.isRequired(this.requiredIf);

        if (required) {
            if (!this.allowZero && (value === '0' || value === 0)) return false;
            return !!value
        }

        return true;
    }
}

class RangeValidator<TFieldValues> extends Validator<TFieldValues> {
    private readonly min: number;
    private readonly max: number;
    private readonly exclusive?: boolean;

    public name = 'range'

    constructor(min: number, max: number, exclusive?: boolean) {
        super();
        this.min = min;
        this.max = max;
        this.exclusive = exclusive;
        this.name = t('Validation.Error.Range', {min: min, max: max})
    }

    validate(value: PathValue<TFieldValues, Path<TFieldValues>>): ValidateResult | Promise<ValidateResult> {
        if (!this.form || !this.field) throw new Error("Form and/or field not set");

        if (value === '') return true;
        if (isNaN(Number(value))) return true;

        if (this.exclusive)
            return this.min < value && value < this.max;

        return this.min <= value && value <= this.max;
    }
}

class MinValidator<TFieldValues> extends Validator<TFieldValues> {
    private readonly min: number;
    private readonly exclusive?: boolean;

    public name = 'min'

    constructor(min: number, exclusive?: boolean) {
        super();
        this.min = min;
        this.exclusive = exclusive;
        this.message = t('Validation.Error.Min', {min: min})
    }

    validate(value: PathValue<TFieldValues, Path<TFieldValues>>): ValidateResult | Promise<ValidateResult> {
        if (!this.form || !this.field) throw new Error("Form and/or field not set");

        if (value === '') return true;
        if (isNaN(Number(value))) return true;

        if (this.exclusive) return value > this.min;

        return value >= this.min;
    }
}

class MaxValidator<TFieldValues> extends Validator<TFieldValues> {
    private readonly max: number;
    private readonly exclusive?: boolean;

    public name = 'max'

    constructor(max: number, exclusive?: boolean) {
        super();
        this.max = max;
        this.exclusive = exclusive;
        this.message = t('Validation.Error.Max', {max: max})
    }

    validate(value: PathValue<TFieldValues, Path<TFieldValues>>): ValidateResult | Promise<ValidateResult> {
        if (!this.form || !this.field) throw new Error("Form and/or field not set");

        if (value === '') return true;
        if (isNaN(Number(value))) return true;

        if (this.exclusive) return value < this.max;

        return value <= this.max;
    }
}


class EmailValidator<TFieldValues> extends Validator<TFieldValues> {

    constructor() {
        super();
        this.name = 'email'
        this.message = t('Validation.Error.Email')
    }

    validate(value: PathValue<TFieldValues, Path<TFieldValues>>): ValidateResult | Promise<ValidateResult> {
        if (!this.form || !this.field) throw new Error("Form and/or field not set");

        if (typeof value === 'string') return isValidEmail(value.trim());

        return true;
    }
}

class EmailListValidator<TFieldValues> extends Validator<TFieldValues> {

    constructor() {
        super();
        this.name = 'email-list'
        this.message = t('Validation.Error.Email')
    }

    validate(value: PathValue<TFieldValues, Path<TFieldValues>>): ValidateResult | Promise<ValidateResult> {
        if (!this.form || !this.field) throw new Error("Form and/or field not set");

        if (typeof value === 'string') {
            const invalidEmails = value.split(/[,;\n]/).filter(email => !isValidEmail(email.trim()));
            if (invalidEmails.length > 0) {
                this.message = t('Email.InvalidFormat', {email: invalidEmails})

                return false
            }
        }

        return true;
    }
}

/**
 * Validate if a string matches the requirements for a password:
 * - At least 8 characters
 * - At least 1 digit
 * - At least 1 lower case character
 * - At least 1 upper case character
 * - At least 1 non-alphanumeric character
 */
class PasswordRequirementsValidator<TFieldValues> extends Validator<TFieldValues> {

    constructor() {
        super();
        this.name = 'password'
        this.message = t('Validation.Error.Password')
    }

    validate(value: PathValue<TFieldValues, Path<TFieldValues>>): ValidateResult | Promise<ValidateResult> {
        if (!this.form || !this.field) throw new Error("Form and/or field not set");

        if (typeof value === 'string') {
            const errors: string[] = []
            if (value.length < 8) errors.push(t('PasswordTooShort', {minLength: 8}))
            if (!value.match(/[0-9]/)) errors.push(t('PasswordRequiresDigit'))
            if (!value.match(/[a-z]/)) errors.push(t('PasswordRequiresLower'))
            if (!value.match(/[A-Z]/)) errors.push(t('PasswordRequiresUpper'))
            if (!value.match(/[^0-9a-zA-Z]/)) errors.push(t('PasswordRequiresNonAlphanumeric'))

            if (errors.length > 0){
                // this.message = `${t('Validation.Error.Password')}\n${errors.join('\n')}`
                this.message = <>
                    {t('Validation.Error.Password')}
                    <ul>{errors.map(e => <li key={e}>{e}</li>)}</ul>
                </>
                return false
            }
            return true;
        }

        return true;
    }
}
/**
 * Validate if a string matches the requirements for a password:
 * - At least 8 characters
 * - At least 1 digit
 * - At least 1 lower case character
 * - At least 1 upper case character
 * - At least 1 non-alphanumeric character
 */
class VatNumberValidator<TFieldValues> extends Validator<TFieldValues> {

    constructor() {
        super();
        this.name = 'vat-number'
        this.message = t('Validation.Error.VATNumber.InvalidFormat')
    }
    
    countries = [
        "BE[0-9]{10}",
        "BG[0-9]{9,10}",
        "CY[0-9]{8}[a-zA-z0-9]",
        "DK( [0-9]{2}){4}",
        "DE[0-9]{9}",
        "EE[0-9]{9}",
        "FI[0-9]{8}",
        "FR[a-zA-Z0-9]{2} [0-9]{9}",
        "EL[0-9]{9}",
        "HU[0-9]{8}",
        "IE[0-9]{8}",
        "IT[0-9]{11}",
        "HR[0-9]{11}",
        "LV[0-9]{11}",
        "LT[0-9]{9}", "LT[0-9]{12}",
        "LU[0-9]{8}",
        "MT[0-9]{8}",
        "NL[0-9]{9}B[0-9]{2}",
        "AT[0-9]{9}",
        "PL[0-9]{10}",
        "RO[0-9]{2,10}",
        "SI[0-9]{8}",
        "SI[0-9]{8}",
        "SK[0-9]{9}",
        "ES[a-zA-Z0-9][0-9]{7}[a-zA-Z0-9]",
        "CZ[0-9]{8-10}",
        "SE[0-9]{12}",
        "GB[0-9]{3} [0-9]{4} [0-9]{2}( [0-9]{3})?", "GB(GD|HA)[0-9]{3}"
    ]
    regex = new RegExp(`^(?:${this.countries.join('|')})$`)

    validate(value: PathValue<TFieldValues, Path<TFieldValues>>):  ValidateResult | Promise<ValidateResult>  {
        if (!this.form || !this.field) throw new Error("Form and/or field not set");

        if (typeof value === 'string'){
            return !!value.match(this.regex);
        }

        return true;
    }
}


export function Required<TFieldValues> (allowZero?: boolean) {return new RequiredValidator<TFieldValues>(allowZero ?? true)}
export function SameAs<TFieldValues> (otherField: FieldPath<TFieldValues>, otherLabel?: string) {return new SameAsValidator<TFieldValues>(otherField, otherLabel)}

/// Field is required only when another field has a truthy value
export function RequiredIf<TFieldValues> (otherField: ShowIfType<TFieldValues>, options?: {value?: string|number|boolean, otherLabel?: string, allowZero?: boolean, errorMessage?: string}) {
    return new RequiredIfValidator<TFieldValues>(otherField, options?.allowZero ?? true, options?.otherLabel, options?.errorMessage, options?.value)
}
/// Numeric value must be larger or equal than min and smaller or equal than max
/// If the value is not a number, the field will be considered valid
export function Range<TFieldValues> (min: number, max: number, exclusive?: boolean) {return new RangeValidator<TFieldValues>(min, max, exclusive)}
/// Numeric value must be larger or equal than min
/// If the value is not a number, the field will be considered valid
export function Min<TFieldValues> (min: number, exclusive?: boolean) {return new MinValidator<TFieldValues>(min, exclusive)}
/// Numeric value must be smaller or equal than max
/// If the value is not a number, the field will be considered valid
export function Max<TFieldValues> (max: number, exclusive?: boolean) {return new MaxValidator<TFieldValues>(max, exclusive)}
/// Numeric value must be larger or equal than 0
/// If the value is not a number, the field will be considered valid
export function LargerThanZero<TFieldValues> (){return new MinValidator<TFieldValues>(0, true)}
export function PWD<TFieldValues> (){return new MinValidator<TFieldValues>(0, true)}

/**
 * Check if string has structure of email
 */
export function Email<TFieldValues>(){return new EmailValidator<TFieldValues>()}

/**
 * Require a list of email addresses separated by newline, comma or semicolon
 */
export function EmailList<TFieldValues>(){return new EmailListValidator<TFieldValues>()}


/**
 * Validate if a string matches the requirements for a password:
 * - At least 8 characters
 * - At least 1 digit
 * - At least 1 lower case character
 * - At least 1 upper case character
 * - At least 1 non-alphanumeric character
 */
export function Password<TFieldValues>(){return new PasswordRequirementsValidator<TFieldValues>()}

/**
 * Validate a European VAT number
 */
export function VatNumber<TFieldValues>(){return new VatNumberValidator<TFieldValues>()}

export function Remote<TFieldValues>(url: string, includeValues?: FieldPath<TFieldValues>[]){
    return new RemoteValidator(url, includeValues)
}

export function Unique<TFieldValues>(fields: FieldPath<TFieldValues>[]){
    return new UniqueValidator(fields)
}

export function Iban<TFieldValues>(){
    return new IbanValidator<TFieldValues>()
}