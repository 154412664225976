import {PlattixCard, PlattixCardContainer} from "PlattixUI/core/components/ContentCard";
import React from "react";
import {Route, Switch} from "react-router-dom";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import { PageHeader } from "PlattixUI/core/Header";

export const legalEndpoints = {
    base: `/Legal`,
    TermsAndConditions: () => `${legalEndpoints.base}/TermsAndConditions`,
    PrivacyPolicy: () => `${legalEndpoints.base}/PrivacyPolicy`,
    CookiePolicy: () => `${legalEndpoints.base}/CookiePolicy`,
    CompanyDetails: () => `${legalEndpoints.base}/CompanyDetails`,
    ReturnPolicy: () => `${legalEndpoints.base}/ReturnPolicy`,
};

export interface LegalRoutersProps {
    
}

export function LegalRouters(props: LegalRoutersProps) {
    return (
        <>
            <Route path={legalEndpoints.TermsAndConditions()} component={TermsAndConditions} />
            <Route path={legalEndpoints.PrivacyPolicy()} component={PrivacyPolicy} />
            <Route path={legalEndpoints.CookiePolicy()} component={CookiePolicy} />
            <Route path={legalEndpoints.CompanyDetails()} component={CompanyDetails} />
            <Route path={legalEndpoints.ReturnPolicy()} component={ReturnPolicy} />
            <Route exact path={legalEndpoints.base} component={TermsAndConditions} />
        </>
    );
}

export function Legal() {
    return (
        <Switch>
            <LegalRouters />
        </Switch>
    );
}

export function TermsAndConditions() {
    return (
        <>
            <PageHeader
                title={t('AlgemeneVoorwaarden')}
            />
            <PlattixCard header={t('AlgemeneVoorwaarden')} width={'full'}>
                {/*<p>{t('AlgemeneVoorwaarden.text')}</p>*/}
                <p dangerouslySetInnerHTML={{ __html: t('AlgemeneVoorwaarden.text') }} />
            </PlattixCard>
        </>
    );
}

export function PrivacyPolicy() {
    return (
        <>
            <PageHeader
                title={t('PrivacyPolicyPageHeader')}
            />
            <PlattixCard header={t('PrivacyPolicyPageHeader')} width={'full'}>
                {/*<p>{t('PrivacyPolicy.text')}</p>*/}
                <p dangerouslySetInnerHTML={{ __html: t('PrivacyPolicy.text') }} />
            </PlattixCard>
        </>
    );
}

export function CookiePolicy() {
    return (
        <>
            <PageHeader
                title={t('CookiePolicy')}
            />
            <PlattixCard header={t('CookiePolicy')} width={'full'}>
                {/*<p>{t('CookiePolicy.text')}</p>*/}
                <p dangerouslySetInnerHTML={{ __html: t('CookiePolicy.text') }} />

                <div className="cookieDescGroup">
                    <h5>{t("Cookies.FunctionalCookies")}</h5>
                    {/*<p>{t("Cookies.FunctionalCookies.Description")}</p>*/}
                    <p dangerouslySetInnerHTML={{ __html: t('Cookies.FunctionalCookies.Description') }} />
                    
                    <h6>{t("Cookies.UsedCookies")}</h6>
                    <ul>
                        <li>
                            <span className="cookieName">{t("Cookies.Name")}</span>UserCulture
                            <br/>
                            <span className="cookieName">{t("Cookies.Description")}</span>{t("Cookies.Cookie.UserCulture")}
                        </li>
                        <li>
                            <span className="cookieName">{t("Cookies.Name")}</span>.AspNetCore.Antiforgery.*
                            <br/>
                            <span className="cookieName">{t("Cookies.Description")}</span>{t("Cookies.Cookie.AspNetCore.Antiforgery")}
                        </li>
                        <li>
                            <span className="cookieName">{t("Cookies.Name")}</span>Energytix
                            <br/>
                            <span className="cookieName">{t("Cookies.Description")}</span>{t("Cookies.Cookie.Energytix")}
                        </li>
                        <li>
                            <span className="cookieName">{t("Cookies.Name")}</span>AspNetCore.Identity.2FA
                            <br/>
                            <span className="cookieName">{t("Cookies.Description")}</span>{t("Cookies.Cookie.AspNetCore.Identity.2FA")}
                        </li>
                        <li>
                            <span className="cookieName">{t("Cookies.Name")}</span>.AspNetCore.Mvc.CookieTempDataProvider
                            <br/>
                            <span className="cookieName">{t("Cookies.Description")}</span>{t("Cookies.Cookie.AspNetCore.Mvc.CookieTempDataProvider")}
                        </li>
                    </ul>
                </div>
            </PlattixCard>
        </>
    );
}

export function CompanyDetails() {
    return (
        <>
            <PageHeader
                title={t('CompanyDetailsPageHeader')}
            />
            <PlattixCard header={t('CompanyDetailsPageHeader')} width={'full'}>
                <h3>{t("CompanyDetails.TradeName.title")}</h3>
                <p>{t("CompanyDetails.TradeName.text")}</p>

                <h3>{t("CompanyDetails.CompanyNumber.title")}</h3>
                <p>{t("CompanyDetails.CompanyNumber.text")}</p>

                <h3>{t("CompanyDetails.VATNumber.title")}</h3>
                <p>{t("CompanyDetails.VATNumber.text")}</p>

                <h3>{t("CompanyDetails.Address.title")}</h3>
                <p>{t("CompanyDetails.Address.text")}</p>

                <h3>{t("CompanyDetails.Phone.title")}</h3>
                <p>{t("CompanyDetails.Phone.text")}</p>

                <h3>{t("CompanyDetails.Email.title")}</h3>
                <p>{t("CompanyDetails.Email.text")}</p>
            </PlattixCard>
        </>
    );
}

export function ReturnPolicy() {
    return (
        <>
            <PageHeader
                title={t('ReturnPolicyPageHeader')}
            />
            <PlattixCard header={t('ReturnPolicyPageHeader')} width={'full'}>
                {/*<p>{t('ReturnPolicy.text')}</p>*/}
                <p dangerouslySetInnerHTML={{ __html: t('ReturnPolicy.text') }} />
            </PlattixCard>
        </>
    );
}