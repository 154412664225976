import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {FontAwesomeIcon, FontAwesomeIconProps} from "@fortawesome/react-fontawesome";
import {styled} from "goober";
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { PlattixTooltip } from './ContentCard';

export function PlattixNavigation(props: React.PropsWithChildren<any>) {
    return (
        <div className="menu" id="menu">
            {props.children}
        </div>
    );
}

export interface CustomPlattixNavigationItemProps extends PlattixNavigationItemProps {
    menuItems?: CustomPlattixNavigationItemProps[];
}

export interface PlattixNavigationItemProps {
    Classes?: string;
    Group?: boolean;
    Inactive?: boolean;
    OptionType?: string;
    Name: string;
    Hidden?: boolean;
    Link?: string;
    PageRefresh?: boolean;
    HasPermission?: string;
    PreventClosing?: boolean;
    
    indicator?: {
        show?: boolean;
        message?: string;
    }
}

export function PlattixNavigationItem(props: React.PropsWithChildren<PlattixNavigationItemProps>) {
    const [closed, setClosed] = useState(false);
    
    const closedHandler = (e) => {
        e.stopPropagation();
        setClosed(!closed);
    }
    
    const indicator = () => {
        if (!props.indicator?.show) return <></>;
        
        if (props.indicator.message) return (
            <>
                <PlattixTooltip
                    title={props.indicator.message}
                >
                    <WarningIcon />
                </PlattixTooltip>
            </>
        );
        
        return <FontAwesomeIcon icon={faCircleExclamation} />;
    }
    
    return (
        <div 
            className={'item' 
                + (props.Classes ? ' ' + props.Classes : '') 
                + (props.Group ? ' group' : '') 
                + (props.Inactive ? ' inactive' : '') 
                + (props.children && !props.Group ? ' has-children' : '') 
                // + (props.Hidden && props.Group ? ' hide' : '')
                + (props.Hidden ? ' hide' : '')
                + (props.children && closed ? ' closed' : '')
            } 
            {...props.OptionType ? { 'option-type': props.OptionType } : {}} 
            {...props.HasPermission ? { 'has-permission': props.HasPermission } : {}} 
            {...props.PreventClosing ? '' : { 'onClick': closedHandler }} 
            
        >

            {(props.children) ?
                [
                    <p key={props.Name}>{props.Name}
                        {((props.Group && props.children)
                            ? <FontAwesomeIcon icon={closed ? 'angle-left' : 'angle-down'} />
                            : null
                        )}
                    </p>
                ]
                :
                <>
                    <NavLink exact to={props.Link ? props.Link : '#'}>
                        <MenuText>
                            {indicator()}
                            {props.Name}
                        </MenuText>
                    </NavLink>
                </>
            }
            
            {props.children}
            
        </div>
    );
}

export const MenuText = styled('span', React.forwardRef)(() => {
    return `
        display: flex;
        flex-flow: row nowrap;
        gap: 5px;
        align-items: center;
        
        svg {
            font-size: 0.8em;
            &, * {
                color: var(--textColorRed);
            }
        }
    `;
});

export const WarningIcon = React.forwardRef(function MyComponent(props: Omit<FontAwesomeIconProps, 'icon'>, ref) {
    return (
        <FontAwesomeIcon
            icon={faCircleExclamation}
            forwardedRef={ref}
            {...props}
        />
    );
});