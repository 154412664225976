import React, {
    ForwardedRef,
    HTMLAttributes,
    InputHTMLAttributes, Ref,
    RefAttributes, RefObject,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {
    Controller,
    FieldError,
    FieldPath,
    Path,
    PathValue,
    UseFormRegisterReturn,
    UseFormReturn,
    ValidateResult
} from "react-hook-form";
import {HttpError} from "PlattixUI/PlattixReactCore/CoreTypes";
import {getErrorMessage, isFieldError} from "./formUtil";
import {filterProps} from "PlattixUI/util/ElementProperties";
import NumberFormat, {FormatInputValueFunction, NumberFormatValues} from "react-number-format";
import {getDecimalSeparator, getThousandSeparator} from "PlattixUI/PlattixReactCore/types/Languages";
import htmlRaw from "PlattixUI/util/HtmlRaw";
import {ConfirmButton} from "PlattixUI/core/components/Buttons";
import {t, useTranslation} from "PlattixUI/PlattixReactCore/i18n";
import {PlattixCodeSelect, PlattixSelect, SelectOption} from "PlattixUI/core/components/form/Select";
import {
    AccountPlattixSubmitField,
    AddressPlattixSubmitField,
    BasePlattixSubmitField,
    CodeSelectPlattixSubmitField,
    FilePlattixSubmitField,
    NumberFormatPlattixSubmitField,
    NumberPlattixSubmitField,
    SelectPlattixSubmitField,
    TranslationPlattixSubmitField
} from "PlattixUI/core/components/form/Form";
import {FileUploaderProps, PlattixFileInput} from "PlattixUI/core/components/FileUploader";
import {AddressComponent, AddressComponentProps} from "PlattixUI/core/components/AddressComponent";
import {TranslationPlattixInput} from "PlattixUI/core/components/form/InputFields/TranslationPlattixInput";
import {AccountPlattixInput, ChooseAccountOptions} from "./InputFields/AccountPlattixInput";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Collapse from "@mui/material/Collapse";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import {PercentPlattixInput} from "PlattixUI/core/components/form/InputFields/PercentPlattixInput";
import {Validator} from "PlattixUI/core/forms/Validators/Validator";
import {css} from "goober";

/**
 * Default html input types supported by modern browsers
 */
type DefaultInputTypes =
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';

/**
 * All Input types that are supported
 */
export type PlattixInputTypes =
    | DefaultInputTypes
    | "textarea"
    | "textareaAutoSize"
    | "number-format"
    | "select"
    | "file"
    | 'address'
    | 'account'
    | 'code-select'
    | 'translation'
    | 'percent';

/**
 * Types that use a custom component for rendering and support extra options
 */
type CustomRenderedType =
    | "number-format"
    | "select"
    | "file"
    | 'address'
    | 'code-select'
    | 'translation'
    | 'account'
    | 'percent';

/**
 * All input types that use the default HTML inputs
 */
export type DefaultRenderedTypes = Exclude<PlattixInputTypes, CustomRenderedType>



interface PlattixInputBaseProps extends InputHTMLAttributes<HTMLInputElement> {
    type?: PlattixInputTypes
    label?: string,
    // onChange?: React.ChangeEventHandler<HTMLInputElement>,
    error?: FieldError | string | string[] | undefined | HttpError | null | React.ReactNode,

    description?: JSX.Element | string,
    suffix?: PlattixFormSuffixType,

    readOnly?: boolean,
    clickable?: 1 | 0,

    hideLabel?: boolean,

    actionButtons?: JSX.Element
}

/**
 * Number formatting options for number-format inputs
 *
 * These options are a subset form the component
 * see full docs at {@link https://www.npmjs.com/package/react-number-format}
 */
export type NumberFormatOptions = {
    /**
     * How many decimals to show
     */
    decimalScale: number;
    /**
     * Always show {@see decimalScale} decimals even if all zeros
     * default true
     */
    fixedDecimalScale?: boolean;
    /**
     * Use custom thousands separator
     * default is fetched from user locale
     */
    thousandSeparator?: boolean | string;
    /**
     * Use custom decimal separator
     * default is fetched from user locale
     */
    decimalSeparator?: string;
    /**
     * prefix to prepend on formatted value
     */
    prefix?: string;

    suffix?: string;
    format?: string | FormatInputValueFunction;
    removeFormatting?: (formattedValue: string) => string;
    mask?: string | string[];
    isNumericString?: boolean;
    allowNegative?: boolean;
    allowEmptyFormatting?: boolean;
    allowLeadingZeros?: boolean;
    type?: 'text' | 'tel' | 'password';
    isAllowed?: (values: NumberFormatValues) => boolean;
}

/**
 * Properties needed to register a controlled number-format input
 * {@see https://www.npmjs.com/package/react-number-format}
 */
interface NumberFormatProps extends PlattixInputBaseProps {
    type: "number-format",
    /**
     * Name of property to register
     */
    name: string,
    /**
     * Form hook is needed to correctly register input in form hook
     */
    formHook: UseFormReturn,
    /**
     * Number formatting options
     */
    numberFormatOptions: NumberFormatOptions,
}

/**
 * Properties needed to register a controlled number-format input
 * {@see https://www.npmjs.com/package/react-number-format}
 */
interface SelectInputProps extends PlattixInputBaseProps {
    type: "select",
    /**
     * Name of property to register
     */
    name: string,
    /**
     * Form hook is needed to correctly register input in form hook
     */
    formHook: UseFormReturn<any, object>,
    /**
     * Number formatting options
     */
    options: SelectOption[]

    /**
     * Add an unselectable option that forces the user to pick an option
     */
    chooseOption?: boolean,
    /**
     * Should the 'choose Option' option be unselectable
     */
    chooseOptionDisabled?: boolean,

    isLoading?: boolean,
    isDisabled?: boolean,
    isMulti?: boolean
}

/**
 * Properties needed to register a controlled number-format input
 * {@see https://www.npmjs.com/package/react-number-format}
 */
interface CodeSelectInputProps extends PlattixInputBaseProps {
    type: "code-select",
    /**
     * Name of property to register
     */
    name: string,
    /**
     * Form hook is needed to correctly register input in form hook
     */
    formHook: UseFormReturn<any, object>,
    /**
     * Add an unselectable option that forces the user to pick an option
     */
    chooseOption?: boolean,
    /**
     * Should the 'choose Option' option be unselectable
     */
    chooseOptionDisabled?: boolean,

    namespace?: string,
    tableName: string,

    isLoading?: boolean,
    isDisabled?: boolean,
    isMulti?: boolean
}

/**
 * Properties needed to register a controlled FileUploader
 */
export interface FileInputProps extends Omit<PlattixInputBaseProps, "onChange" | "onBlur"> {
    type: "file",
    /**
     * Name of property to register
     */
    name: string,
    /**
     * Form hook is needed to correctly register input in form hook
     */
    formHook: UseFormReturn<any, object>,
    /**
     * File Uplaod Options
     */
    options: Omit<FileUploaderProps, "setFiles">,
}

/**
 * Properties needed to register a controlled FileUploader
 */
export interface AddressInputProps extends Omit<PlattixInputBaseProps, "onChange" | "onBlur">  {
    type: "address",
    /**
     * Name of property to register
     */
    name: string,
    /**
     * Form hook is needed to correctly register input in form hook
     */
    formHook: UseFormReturn<any, object>,
    /**
     * Address component options
     */
    options: Omit<AddressComponentProps, "form" | "addressModel">
}

/**
 * Properties needed to register a controlled FileUploader
 */
export interface AccountInputProps extends PlattixInputBaseProps  {
    type: "account",
    /**
     * Name of property to register
     */
    name: string,
    /**
     * Form hook is needed to correctly register input in form hook
     */
    formHook: UseFormReturn<any, object>,
    /**
     * Address component options
     */
    options: ChooseAccountOptions
}

/**
 * Properties needed to register a controlled FileUploader
 */
export interface PercentInputProps extends PlattixInputBaseProps  {
    type: "percent",
    /**
     * Name of property to register
     */
    name: string,
    /**
     * Form hook is needed to correctly register input in form hook
     */
    formHook: UseFormReturn<any, object>
}


/**
 * Properties needed to register a controlled translation input
 */
export interface TranslationInputProps extends PlattixInputBaseProps {
    type: "translation",
    /**
     * Name of property to register
     */
    name: string,
    /**
     * Form hook is needed to correctly register input in form hook
     */
    formHook: UseFormReturn,
    required?: boolean,
    collapsable?: boolean
}

/**
 * Properties for a normal registered Plattix input
 */
interface RegisteredProps extends PlattixInputBaseProps {
    register: UseFormRegisterReturn,
    type?: DefaultRenderedTypes
    name?: string,
    autoComplete?: string | undefined;
}

export type PlattixInputProps =
    NumberFormatProps
    | RegisteredProps
    | SelectInputProps
    | FileInputProps
    | AddressInputProps
    | AccountInputProps
    | TranslationInputProps
    | CodeSelectInputProps
    | PercentInputProps


/**
 * Filter out non-default html properties from Props so they are not passed to the component
 * @param props
 */
function filterFormProps(props: any) {
    const invalidHtmlInputAttrs = ['label', 'formHook', 'validation', 'register', 'numberFormatOptions', 'hideLabel', 'actionButtons']
    return filterProps(props, invalidHtmlInputAttrs)
}

/**
 * Creates a controled Number Format input
 * @param props
 * @constructor
 */
function PlattixNumberFormatInput(props: NumberFormatProps) {
    return <Controller
        render={({field}) => {
            if (props.type === 'number-format' && props.formHook && props.name)
                return (
                    <NumberFormat
                        {...field}
                        onValueChange={(c) => {
                            field.onChange(c.value);
                        }}
                        {...props.numberFormatOptions}
                        fixedDecimalScale={props.numberFormatOptions?.fixedDecimalScale ?? true}
                        thousandSeparator={props.numberFormatOptions?.thousandSeparator ?? getThousandSeparator()}
                        decimalSeparator={props.numberFormatOptions?.decimalSeparator ?? getDecimalSeparator()}
                        thousandsGroupStyle={"thousand"}
                        onChange={props.onChange}
                    />
                );
            return <></>
        }}
        name={props.name}
        control={props.formHook.control}
    />
}

export type PlattixInputRefHandler<T> = {
    // pressAlert: () => void;
    ref: RefObject<HTMLInputElement>;
};

export type InnerRef<T> = { innerRef?: Ref<T> };

export const PlattixInput = React.forwardRef<HTMLDivElement, PlattixInputProps>((props: PlattixInputProps, ref) => {

    function getInputErrorMessage() {
        if (props.type === 'address') return null;
        let name;
        if (props.type === 'number-format'
            || props.type === 'select'
            || props.type === 'file'
            || props.type === 'code-select'
            || props.type === 'translation'
            || props.type === 'account'
            || props.type === 'percent'
        ) name = props.name
        else name = props.register.name
        return getErrorMessage(name, props.error)
    }

    function getLabel(){
        if (props.label) return props.label;

        let name;
        if (
            props.type === 'number-format'
            || props.type === 'select'
            || props.type === 'file'
            || props.type === 'code-select'
            || props.type === 'translation'
            || props.type === 'address'
            || props.type === 'account'
            || props.type === 'percent'
        ) name = props.name
        else name = props.register.name

        return t(name);
    }

    const placeholderText = props.placeholder ?? getLabel();

    async function handleChange(event) {
        if (props.type === 'select' || props.type === 'code-select') {
            await props.onChange?.(event);
        } else {
            if (isRegisteredType(props)){
                await props.register.onChange?.(event)
            }
            if (props.type !== "file" && props.type !== "address")
                props.onChange?.(event);
        }


    }
    async function handleBlur(event) {
        if (isRegisteredType(props)){
            await props.register.onBlur(event)
        }
        if (props.type !== "file" && props.type !== "address")
            props.onBlur?.(event);
    }

    function renderInput() {
        switch (props.type) {
            case ('number-format'):
                return <PlattixNumberFormatInput {...props} />
            case ('file'):
                return <PlattixFileInput {...props} />
            case ('address'):
                return <AddressComponent  {...props.options} addressModel={props.name} form={props.formHook}/>
            case ('account'):
                return <AccountPlattixInput
                    {...props.options}
                    required={props.required}
                    readonly={props.readOnly}
                    options={props.options}
                    name={props.name}
                    form={props.formHook}/>
            case ('translation'):
                return <TranslationPlattixInput {...props}/>
            case ('select'):
                return <PlattixSelect<any>
                    {...filterFormProps(props)}
                    name={props.name}
                    form={props.formHook}
                    options={props.options}
                    chooseOption={props.chooseOption}
                    chooseOptionDisabled={props.chooseOptionDisabled}
                    onChange={handleChange}
                    isDisabled={props.readOnly}
                    required={props.required}
                    isLoading={props.isLoading}
                    isMulti={props.isMulti}
                />
            case ('code-select'):
                return <PlattixCodeSelect<any>
                    {...filterFormProps(props)}
                    name={props.name} form={props.formHook}
                    chooseOption={props.chooseOption}
                    chooseOptionDisabled={props.chooseOptionDisabled}
                    namespace={props.namespace}
                    tableName={props.tableName}
                    onChange={handleChange}
                    isDisabled={props.readOnly}
                    required={props.required}
                    isLoading={props.isLoading}
                    isMulti={props.isMulti}
                />
            case ('textarea'):
                return <textarea
                    {...filterFormProps(props)}
                    className={`form-control ${props.clickable ? 'clickable' : ''}`}
                    id={props.id ?? props.name}
                    defaultValue={props.value}
                    placeholder={placeholderText}
                    cols={4}
                    {...props.register}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            case ('textareaAutoSize'):
                return <TextareaAutosize
                    {...filterFormProps(props)}
                    className={`form-control ${props.clickable ? 'clickable' : ''}`}
                    id={props.id ?? props.name}
                    defaultValue={props.value}
                    placeholder={placeholderText}
                    minRows={4}
                    {...props.register}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            case ('percent'):
                return <PercentPlattixInput
                    {...filterFormProps(props)}
                    name={props.name}
                    formHook={props.formHook}
                    id={props.id ?? props.name}
                    defaultValue={props.value}
                    placeholder={placeholderText}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                />
            default:
                return <input
                    {...filterFormProps(props)}
                    type={props.type ?? "text"}
                    className={`form-control ${props.clickable ? 'clickable' : ''}`}
                    id={props.id ?? props.name}
                    defaultValue={props.value}
                    placeholder={placeholderText}
                    autoComplete={props.autoComplete ?? 'off'}
                    // onKeyPress={(e) => {
                    //     e.key === 'Enter' && e.preventDefault();
                    // }}
                    {...props.register}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    step={props.type === 'number' ? "any" : undefined}
                />
        }
    }

    if (props.type === "hidden") {
        return (<div ref={ref}>
            <div>
                <input
                    {...filterFormProps(props)}
                    type={props.type}
                    className="form-control"
                    id={props.id ?? props.name}
                    onChange={(e) => handleChange(e)}
                />
            </div>
        </div>);
    } else {
        return (
            <div className={`form-group row ${props.type === 'checkbox' ? 'form-group-checkbox' : ''}`} ref={ref}>
                <div className="module-tab-content-body-group">
                    <div className={'input-wrapper'}>
                        {(!props.hideLabel && props.type !== 'address') &&
                            <label className="module-tab-content-title" htmlFor={props.id ?? props.name}>
                                {htmlRaw(getLabel())}
                            </label>
                        }
                        <div style={{
                            display: "flex",
                            width: props.type === 'checkbox' ? undefined : "100%",
                            alignItems: props.type === 'checkbox' ? "center" : undefined,
                        }}>
                            <div style={{position: 'relative', flexGrow: 1}} >
                                {renderInput()}
                                {(props.suffix || props.type === 'percent') && <PlattixFormSuffix type={props.suffix ?? 'percent'}/>}
                            </div>

                            {!!props.actionButtons &&
                                <div style={{marginLeft: '10px'}}>
                                    {props.actionButtons}
                                </div>
                            }
                        </div>
                    </div>


                    {(props.error) && <span className="text-danger">{getInputErrorMessage()}</span>}

                    {props.description && <div className="formInputDescription">{props.description}</div>}
                </div>
            </div>
        );
    }
});

function isRegisteredType(props: PlattixInputProps): props is RegisteredProps{
    if (props === undefined) return false;
    return (props as RegisteredProps).register !== undefined;
}

export function PlattixCheckbox(props: RegisteredProps) {
    const {t} = useTranslation(['translation']);
    return (
        <div className="form-group row form-group-checkbox">
            <div className="module-tab-content-body-group">
                <div className={'input-wrapper'}>
                    <label className="module-tab-content-title"
                           htmlFor={props.id ?? props.name}>
                        {htmlRaw(t(props.label ?? props.register.name ))}
                        {/*{props.required && */}
                        {/*    <span className={'required'}>*</span>*/}
                        {/*}*/}
                    </label>
                    <input
                        {...filterFormProps(props)}
                        type={"checkbox"}
                        className="form-control"
                        id={props.id ?? props.name}
                        defaultValue={props.value}
                        placeholder={props.placeholder ?? props.name}
                        {...props.register}
                    />
                </div>

                {(props.error) &&
                    <span className="text-danger">{getErrorMessage(props.register.name, props.error)}</span>}

            </div>
        </div>
    );
}

export interface PlattixSubmitButtonProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string,
    loading?: boolean,
    form?: string,
    onClick?: () => void,
}
export function PlattixSubmitButton(props: PlattixSubmitButtonProps) {
    return (
        // <div className="module-content-tab-btns-2">
        <ConfirmButton
            disabled={props.disabled}
            loading={props.loading}
            type="submit"
            form={props.form}
            onClick={props.onClick}
        >
            {props.name}
        </ConfirmButton>
        // </div>
    )
}

export type ShowIfValue<TModel> = {field: FieldPath<TModel>, value: any};
export type ShowIfSingle<TModel> = FieldPath<TModel> | boolean | ShowIfValue<TModel>;
export type ShowIfType<TModel> = ShowIfSingle<TModel> | ShowIfSingle<TModel>[];


export function isShowIfValue<TModel>(showIf: ShowIfValue<TModel> | ShowIfType<TModel>): showIf is ShowIfValue<TModel> {
    return (showIf as ShowIfValue<TModel> ).field !== undefined ;
}

export type PlattixValidatedInputProps<TFieldValues> = PlattixInputBaseProps & {
    formHook: UseFormReturn<TFieldValues, object>,
    name: FieldPath<TFieldValues>,
    validation?: Validator<TFieldValues> | Validator<TFieldValues>[],
    showIf?: ShowIfType<TFieldValues>,
    readOnly?: boolean,
    onClick?: (any) => any,
} & (
    | NumberFormatPlattixSubmitField
    | NumberPlattixSubmitField
    | SelectPlattixSubmitField
    | CodeSelectPlattixSubmitField
    | BasePlattixSubmitField
    | FilePlattixSubmitField
    | TranslationPlattixSubmitField
    | AddressPlattixSubmitField
    | AccountPlattixSubmitField
    )


export function PlattixValidatedInput<TFieldValues>(props: PlattixValidatedInputProps<TFieldValues>) {
    const {formState: {errors}} = props.formHook;

    const [display, setDisplay] = useState(true)

    let validation: {
        [key: string]: (value: PathValue<TFieldValues, Path<TFieldValues>>)
            => ValidateResult | Promise<ValidateResult>
    } = {}


    const showIfArray = useMemo(() => {

        function showIfToArray(showIf: ShowIfType<TFieldValues> | undefined): FieldPath<TFieldValues>[] {
            if (showIf === undefined) return [];

            if (Array.isArray(showIf)) {
                return showIf.flatMap(v => showIfToArray(v)) as FieldPath<TFieldValues>[];
            }

            if (typeof showIf === 'boolean') return [];
            if (isShowIfValue(showIf)) return [showIf.field]
            if (showIf) return [showIf];
            return []
        }

        return showIfToArray(props.showIf)

    }, [props.showIf])

    const watch = props.formHook.watch(showIfArray)
    useEffect(() => {
        if (props.showIf === undefined) return;

        if (!Array.isArray(props.showIf)) {
            if (typeof props.showIf === 'boolean') {
                setDisplay(props.showIf)
            } else if (isShowIfValue(props.showIf))
                setDisplay(watch[0] === props.showIf.value)
            else {
                setDisplay(watch.every(v => !!v))
            }
        } else {
            setDisplay(
                props.showIf.filter(s => typeof s === 'boolean').every(b => b)
                && props.showIf
                    .filter(s => typeof s !== 'boolean')
                    .every((v, idx) => {
                        if (isShowIfValue(v)) return v.value === watch[idx]
                        return !!watch[idx]
                    })
            )
        }
    }, [props.showIf, watch]);

    if (props.validation) {
        if (Array.isArray(props.validation)) {
            props.validation.forEach(v => v._register(props))
            validation = Object.fromEntries(props.validation.map(v => [v.name, (x) => v.validate(x)]))
        } else if (props.validation instanceof Validator) {
            const validator = props.validation;
            validator._register(props);
            validation[props.validation.name] = (v) => validator.validate(v);
        }
    }


    const fieldError = props.formHook.getFieldState(props.name).error
    const errorMessage = useMemo(() => {
        let errorType = fieldError?.type
        if (props.error && isFieldError(props.error))
            errorType = props.error.type

        if (props.validation) {
            if (Array.isArray(props.validation)) {
                const validator = props.validation.find(v => v.name === errorType)
                if (validator) return validator.getErrorMessage();
            } else {
                if (props.validation.name === errorType && props.validation.getErrorMessage()) return props.validation.getErrorMessage();
            }
        }

        return getErrorMessage(props.name, props.error ?? errors[props.name as string])
            ?? props.formHook.getFieldState(props.name).error?.message

    }, [errors, fieldError, props.error, props.formHook, props.name, props.validation])

    const dateTypes: PlattixInputTypes[] = ["date", "datetime-local"]
    const valueAsDate = dateTypes.includes(props.type ?? 'text')
    const numberTypes: PlattixInputTypes[] = ["number", "number-format"]
    const valueAsNumber = numberTypes.includes(props.type ?? 'text')

    return (
        <Collapse in={display} /*className={'form-group row'}*/ style={{width: '100%'}}>
            <PlattixInput
                {...filterProps(props, ['showIf'])}
                register={
                    props.formHook.register(props.name, {
                        validate: validation,
                        valueAsNumber: valueAsNumber,
                        valueAsDate: valueAsDate
                    })
                }
                formHook={props.formHook as UseFormReturn}
                numberFormatOptions={props.type === 'number-format' ? props.numberFormatOptions : undefined}
                options={
                    props.type === 'file' ? props.fileUploadOptions :
                        (props.type === 'select' ? props.options : (
                            props.type === 'address' ? props.addressOptions : (
                                props.type === 'account' ? props.accountOptions : undefined
                            )))}
                label={props.label}
                error={errorMessage}
                onChange={props.onChange}
            />
        </Collapse>
    );
}

export async function ValidateField<TFieldValues>(form: UseFormReturn<TFieldValues, object>, field: PlattixValidatedInputProps<TFieldValues>) {
    let fieldValid = true

    const fieldValue = form.getValues(field.name)
    if (Array.isArray(field.validation)) {

        for (let i = 0; i < field.validation.length; i++) {
            const valid = await field.validation[i].validate(fieldValue)
            if (!valid) addFormError(form, field.name, field.validation[i].name)
            fieldValid = fieldValid && !!valid
        }
    } else if (field.validation) {
        fieldValid = !!(await field.validation.validate(fieldValue))
        if (!fieldValid) addFormError(form, field.name, field.validation.name)
    }


    return fieldValid

}

export function addFormError<TFieldValues>(form: UseFormReturn<TFieldValues, object>, name: FieldPath<TFieldValues>, error: string | null | undefined) {
    if (error)
        form.setError(name, {message: error})
    else
        form.clearErrors(name)
}

export type PlattixFormSuffixType =
    'euro'
    | 'percent'
    | 'W'
    | 'kW'
    | 'kWh'
    | 'year'
    | 'm2'
    | 'Wp'
    | 'kWp'
    | 'km'
    | 'kWh/100km'
    | 'A'
    | 'hours';

export interface PlattixFormSuffixProps {
    type: PlattixFormSuffixType;
    className?: string;
}

export function PlattixFormSuffix(props: PlattixFormSuffixProps) {
    const {t} = useTranslation()
    if (props.type === 'year')
        return <span className={`suffix ${props.className || ''}`}>{t('Years')}</span>

    return <span className={`suffix-${props.type} ${props.className || ''}`}/>
}

interface SearchInputProps {
    id?: string,
}

export function SearchInput(props: SearchInputProps) {
    const searchInput = useRef<HTMLInputElement>(null);
    const [searchValue, setSearchValue] = useState<string | undefined>('');

    const searchHandler = (e) => {
        setSearchValue(searchInput.current?.value)
        // console.log('Search:', searchInput.current?.value, searchValue)
    };

    return (
        <div className="module-content-search">
            <input
                className="searchBar"
                type="text"
                id={props.id}
                placeholder={t('Search')}
                onInput={searchHandler}
                ref={searchInput}
            />
            <button onClick={searchHandler}>
                <FontAwesomeIcon icon={faSearch} name={'searchIcon'}/>
            </button>
        </div>
    );
}
//
// export interface SameHeightAsInputComponentProps {}
//
// export function SameHeightAsInputComponent(props: PropsWithChildren<SameHeightAsInputComponentProps>) {
//     return (
//         <>
//            
//             <div className={`form-group row`}>
//                 <div className="module-tab-content-body-group">
//                     <div className={'input-wrapper'}>
//                         <label className="module-tab-content-title">
//                             <span>{' '}</span>
//                         </label>
//                         {props.children}
//                     </div>
//
//                     {/*{(props.suffix || props.type === 'percent') && <PlattixFormSuffix type={props.suffix ?? 'percent'}/>}*/}
//
//                     {/*{(props.error) && <span className="text-danger">{getInputErrorMessage()}</span>}*/}
//
//                     {/*{props.description && <div className="formInputDescription">{props.description}</div>}*/}
//                 </div>
//             </div>
//         </>
//     );
// }

// export interface PlattixSingleInputProps extends HTMLAttributes<HTMLInputElement> {
// export interface PlattixSingleInputProps extends HTMLInputElement {
export interface PlattixSingleInputProps extends InputHTMLAttributes<HTMLInputElement> {
// export interface PlattixSingleInputProps extends RefAttributes<HTMLInputElement> {

}

export const PlattixSingleInput = React.forwardRef<HTMLInputElement, PlattixSingleInputProps>(
    (props: PlattixSingleInputProps, ref) => {
        return (
            <input
                {...props}
                ref={ref}
                className={`form-control ${PlattixSingleInputStyling}`}
            />
        );
    }
)

export const PlattixSingleInputStyling = css`
    font-weight: var(--fontWeight2);
    font-size: var(--inputFontSize);
    width: 100%;
    float: left !important;
    border: none;
    color: var(--styleColor4);
    padding: var(--padding4);
    margin: 0;
    background: var(--backgroundColor2);
    border-radius: var(--roundCorner2);
`;
