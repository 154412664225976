import {MarketplaceHeader} from "PlattixSalesUI/Sales/Marketplace/Components/MHeader";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {PlattixCardContainer} from "PlattixUI/core/components/ContentCard";
import {ProductListViewModel, useActiveCart} from "PlattixSalesUI/Sales/MarketplaceUtil";
import React from "react";
import {usePlattixQuery} from "PlattixUI/PlattixReactCore/api/Api";
import {Endpoints} from "configuration/ApiEnpointMap";
import {ProductShowcase} from "PlattixSalesUI/Sales/Products/ProductShowcase";
import {MarketplaceRouteMap} from "PlattixSalesUI/configuration/RouteMap";
import {generatePath} from "react-router-dom";

export function MarketplaceOverview() {
    const activeCartQuery = useActiveCart()

    const productsQuery = usePlattixQuery<ProductListViewModel>(
        // Product list depends on active price structure
        ['productsQuery', activeCartQuery.data?.pricestructureId ?? -1],
        Endpoints.Marketplace.Products(),
        {
            
        }
    )

    return (
        <>
            <MarketplaceHeader
                title={t('Marketplace.Overview.Headline')}
                // description={t('Steak can be garnished with sichuan-style chocolate, also try blending the fritters with rice vinegar.')}
                includeBtns={{
                    checkout: true, 
                    cart: true
                }}
            />

            <PlattixCardContainer>
                {productsQuery.data?.products?.map((product, index) => {
                    return (
                        <ProductShowcase 
                            product={product}
                            endPoints={{
                                addToCart: Endpoints.Marketplace.AddToCart(),
                                productDetail: MarketplaceRouteMap.Product(),
                                updateProductAmount: Endpoints.Marketplace.UpdateProductAmount(),
                                getActiveCart: generatePath(Endpoints.Marketplace.GetActiveCart()),
                            }}
                            key={`${product.productId}_${index}`}
                        />
                    )
                })}
            </PlattixCardContainer>
        </>
    );
}


// TODO: Component van PlattixSalesUI/Sales/Products/ProductShowcase gebruiken!!!
// export function ProductShowcase(props: {product:  ShopProductViewModel}){
//     const product = props.product;
//    
//     const addToCartMutation = usePlattixMutation<ShoppingCartViewModel, {productId: number}>(
//         [ActiveCartQueryKey],
//         Endpoints.Marketplace.AddToCart()
//     );
//    
//
//     const tempFullPrice = ((product.staffoldPrices[0].salesPrice * (product.vat.percentage/100)) + product.staffoldPrices[0].salesPrice).toFixed(2);
//
//     const addToBasketHandler = async (productId: number, productName: string) => {
//         try {
//             await addToCartMutation.mutate({productId: productId});
//         } catch (e) {
//             toast(t('Marketplace.Overview.Product.AddedToCard.Failed', {product: productName}), {type: "success"})
//         } finally {
//             toast(t('Marketplace.Overview.Product.AddedToCard', {product: productName}), {type: "success"})
//         }
//     }
//    
//     return <PlattixShowcaseCard
//         // img={'https://media.energytix.cloud/images/marketplace/products/default/product-placeholder.png'}
//         img={product.filePath}
//         imgFit={'cover'}
//         link={generatePath(MarketplaceRouteMap.Product(), {productId: product.productId})}
//         fillHeight={false}
//     >
//         <ProductTitle>
//             <Link to={generatePath(MarketplaceRouteMap.Product(), {productId: product.productId})}>
//                 <h5>{product.productDescription}</h5>
//             </Link>
//             <p>{product.productDescription2}</p>
//         </ProductTitle>
//
//         <ShowcaseCardSpacer spacing={'none'}/>
//
//         {/*// TODO: Meegeven in de backend om de producten van een samengesteld product weer te geven*/}
//         {product.childProducts?.length &&
//             <>
//                 <ProductChildProductListContainer>
//                     <b>{t('Marketplace.Overview.Product.Contents')}:</b>
//                     <ProductChildProductList>
//                         {product.childProducts.map((productchild, index) => {
//                             return (
//                                 <ProductChildProductListItem key={`${productchild.productId}_${index}`}>
//                                     {productchild.quantity}x {productchild.productDescription}
//                                 </ProductChildProductListItem>
//                             )
//                         })}
//                     </ProductChildProductList>
//                 </ProductChildProductListContainer>
//
//                 <ShowcaseCardSpacer spacing={'none'}/>
//             </>
//         }
//
//         <ProductFooter>
//             <ProductFooterLeft>
//                 <ProductFooterPrice>
//                     <h5>€{tempFullPrice} / {t(product.skuCodeDescription)}</h5>
//                     <p>€{product.staffoldPrices[0].salesPrice} {t('Marketplace.Home.Products.ExcludingVat', {amount: product.vat.percentage})}</p>
//                 </ProductFooterPrice>
//             </ProductFooterLeft>
//             <ProductFooterRight>
//                 <ConfirmButton onClick={() => addToBasketHandler(product.productId, product.productDescription)}>
//                     <FontAwesomeIcon icon={faPlus}/>
//                     <FontAwesomeIcon icon={faCartShopping}/>
//                 </ConfirmButton>
//             </ProductFooterRight>
//
//         </ProductFooter>
//     </PlattixShowcaseCard>
// }