
export enum ErrorType {
    /**
     * Server responded with non 200 status code
      */
    Server,
    /**
     * Request failed due to network failure
      */ 
    Network,
    /**
     * Request was aborted by the user
     */
    Aborted,
    /**
     * Unknown cause
      */ 
    Unknown,
    /**
     * Request could not be sent due to unmet preconditions
     */
    PreconditionFailed
}

export type HttpError = {
    errorType: ErrorType,
    status: number,
    statusText: string,
    title: string,
    detail: string,
    traceId: string,
    errors: { [key: string]: [string] }
}

export function newHttpError(errorType: ErrorType, statusText?: string): HttpError {
    return {
        errorType: errorType,
        status: -1,
        statusText: statusText ?? "",
        title: "",
        detail: "",
        traceId: "",
        errors: {}
    }
}

export interface StoreStatus {
    isInitialised: boolean,
    isLoading: boolean,
    error: HttpError | null | undefined
}

export const InitialStoreStatus = {
    isInitialised: false,
    isLoading: false,
    error: null
}

export interface CancellableStoreStatus extends StoreStatus {
    abortController: AbortController | null
}

export const InitialCancellableStoreStatus = {
    ...InitialStoreStatus,
    abortController: null
}

export function storeReady(storeStatus: StoreStatus) {
    return storeStatus.isInitialised && !storeStatus.isLoading && !storeStatus.error
}