import React, {PropsWithChildren} from 'react';
import {Route, Switch} from "react-router-dom";
import {SidebarLoginComponent} from "PlattixUI/core/SidebarLogin";
import {useSelector} from "react-redux";
import {userLoggedInSelector} from "PlattixUI/PlattixReactCore/UserSlice";
import {AnonymousRoutes} from "PlattixUI/core/pages/Account/CheckLoginComponent";
import {legalEndpoints, LegalRouters} from "PlattixUI/core/pages/Legal/Legal";
import {t} from "PlattixUI/PlattixReactCore/i18n";

export const AnonymousRoutesComponent = (props: PropsWithChildren<any>) => {

    const loggedIn = useSelector(userLoggedInSelector);
    
    if (loggedIn) return props.children;

    return <Switch>
       
        <Route path={"/"}>
            {props.children}
        </Route>
    </Switch>
}



const AnonymousComponentWrapper = (props: PropsWithChildren<any>) => {
    return (<>
            <section className="content">
                <section className="body">
                    {props.children}
                </section>
            </section>
            <SidebarLoginComponent/>
        </>
    )
}

export const anonymousRoutes: AnonymousRoutes = [
    {
        url: legalEndpoints.base,
        component: <LegalRouters />,
        menuItems: [
            {
                Name: t('Menu.Legal'),
                Group: true,
                menuItems: [
                    {
                        Name: t('AlgemeneVoorwaarden'),
                        Link: legalEndpoints.TermsAndConditions(),
                    },
                    {
                        Name: t('PrivacyPolicyPageHeader'),
                        Link: legalEndpoints.PrivacyPolicy(),
                    },
                    {
                        Name: t('CookiePolicy'),
                        Link: legalEndpoints.CookiePolicy(),
                    },
                    {
                        Name: t('CompanyDetailsPageHeader'),
                        Link: legalEndpoints.CompanyDetails(),
                    },
                    {
                        Name: t('ReturnPolicyPageHeader'),
                        Link: legalEndpoints.ReturnPolicy(),
                    },
                ],
            },
        ],
    },
];