export function closeCookiePopup(target) {
    target.closest('.cookiePopup').classList.remove('cookiesShow');
};

export function cookieBanner() {
    if (!localStorage.getItem('cookiesAccepted')) localStorage.setItem('cookiesAccepted', 'false');
    if (!localStorage.getItem('madeDecision')) localStorage.setItem('madeDecision', 'false');
    //if (!localStorage.getItem('trackingCookies')) localStorage.setItem('trackingCookies', 'true');
    //if (!localStorage.getItem('thirdPartyCookies')) localStorage.setItem('thirdPartyCookies', 'true');

    let cookiesAccepted = false;
    let madeDecision = false;
    let trackingCookies = true;
    let thirdPartyCookies = true;

    let acceptBtn = document.querySelectorAll('#cookiesAccept');
    let settingsBtn = document.querySelectorAll('#cookiesSettings');
    let ReadMoreBtn = document.querySelectorAll('#cookiesReadMore');
    let readMorePopup = document.querySelectorAll('#readMorePopup');
    let settingsPopup = document.querySelectorAll('#settingsPopup');
    let banner = document.querySelectorAll('.cookieBanner > .banner');
    let openBanner = document.querySelectorAll('#openBanner');
    //let trackingAcceptBtn = document.getElementById('acceptTrackingCookies');
    //let trackingDeclineBtn = document.getElementById('declineTrackingCookies');
    //let thirdPartyAcceptBtn = document.getElementById('acceptThirdPartyCookies');
    //let thirdPartyDeclineBtn = document.getElementById('declineThirdPartyCookies');
    let statusTrackingCookies = document.getElementById('statusTrackingCookies');
    let statusThirdPartyCookies = document.getElementById('statusThirdPartyCookies');



    //let hideBanner = () => {
    //    banner.forEach((banr) => {
    //        if (window.innerWidth <= 768 && banr.closest('.cookieBanner').classList.contains('cookieOnlyMobile')) {
    //            banr.childNodes.forEach((elem, index) => {
    //                if (elem.nodeType != 3) {
    //                    elem.classList.remove('cookiesHide');
    //                }
    //            });
    //            banr.classList.add('closedCookieBanner');
    //            banr.querySelector('#openBanner').classList.add('cookiesHide');
    //        } else if (window.innerWidth > 768 && !banr.closest('.cookieBanner').classList.contains('cookieOnlyMobile')) {
    //            banr.childNodes.forEach((elem, index) => {
    //                if (elem.nodeType != 3) {
    //                    elem.classList.remove('cookiesHide');
    //                }
    //            });
    //            banr.classList.add('closedCookieBanner');
    //            banr.querySelector('#openBanner').classList.add('cookiesHide');
    //        }
    //    });
    //};

    let hideBanner = () => {
        banner.forEach((banr) => {
            const options = banr.closest('.options');
            //const autoClose = !options.hasAttribute('auto-close');
            if (options.hasAttribute('auto-close')) options.setAttribute('auto-close', 'true')
            options.classList.add('hide');
        });
    };

    let acceptedTrackingCookies = () => {
        // Plaats hier de scripts om de cookies te intalleren bij de gebruiker wanneer de gebruiker accepteert.
        //console.log('Tracking cookies injected');
    };

    let acceptedThirdPartyCookies = () => {
        // Plaats hier de scripts om de cookies te intalleren bij de gebruiker wanneer de gebruiker accepteert.
        //console.log('Third party cookies injected');
    };


    if (localStorage.getItem('madeDecision') === 'true') {
        hideBanner();
    }

    //if (localStorage.getItem('trackingCookies') === 'true' && localStorage.getItem('cookiesAccepted') === 'true') {
    //    acceptedTrackingCookies();
    //}

    //@*if (localStorage.getItem('trackingCookies') === 'true') {
    //    statusTrackingCookies.innerHTML = '@ApplicationModel.Translate("Cookies.CookieStatusTrue")';
    //} else {
    //    statusTrackingCookies.innerHTML = '@ApplicationModel.Translate("Cookies.CookieStatusFalse")';
    //}*@

        //if (localStorage.getItem('thirdPartyCookies') === 'true' && localStorage.getItem('cookiesAccepted') === 'true') {
        //    acceptedThirdPartyCookies();
        //}

    //    @*if (localStorage.getItem('thirdPartyCookies') === 'true') {
    //    statusThirdPartyCookies.innerHTML = '@ApplicationModel.Translate("Cookies.CookieStatusTrue")';
    //} else {
    //    statusThirdPartyCookies.innerHTML = '@ApplicationModel.Translate("Cookies.CookieStatusFalse")';
    //}*@

    //    @* trackingAcceptBtn.addEventListener('click', () => {
    //    localStorage.setItem('trackingCookies', 'true');
    //    statusTrackingCookies.innerHTML = '@ApplicationModel.Translate("Cookies.CookieStatusTrue")';
    //});*@

    //    @* trackingDeclineBtn.addEventListener('click', () => {
    //    localStorage.setItem('trackingCookies', 'false');
    //    statusTrackingCookies.innerHTML = '@ApplicationModel.Translate("Cookies.CookieStatusFalse")';
    //    // Als de gebruiker achteraf deze instelling wijzigt, moeten de cookies worden verwijderd.
    //});*@

    //    @* thirdPartyAcceptBtn.addEventListener('click', () => {
    //    localStorage.setItem('thirdPartyCookies', 'true');
    //    statusThirdPartyCookies.innerHTML = '@ApplicationModel.Translate("Cookies.CookieStatusTrue")';
    //});*@

    //    @* thirdPartyDeclineBtn.addEventListener('click', () => {
    //    localStorage.setItem('thirdPartyCookies', 'false');
    //    statusThirdPartyCookies.innerHTML = '@ApplicationModel.Translate("Cookies.CookieStatusFalse")';
    //    // Als de gebruiker achteraf deze instelling wijzigt, moeten de cookies worden verwijderd.
    //});*@

    acceptBtn.forEach((btn) => {
        btn.addEventListener('click', () => {
            localStorage.setItem('madeDecision', 'true');
            localStorage.setItem('cookiesAccepted', 'true');
            hideBanner();
            //if (localStorage.getItem('trackingCookies') === 'true' && localStorage.getItem('cookiesAccepted') === 'true') {
            //    acceptedTrackingCookies();
            //}
            //if (localStorage.getItem('thirdPartyCookies') === 'true' && localStorage.getItem('cookiesAccepted') === 'true') {
            //    acceptedThirdPartyCookies();
            //}
        });
    });

    ReadMoreBtn.forEach((btn) => {
        btn.addEventListener('click', () => {
            readMorePopup.forEach((popup) => {
                if (window.innerWidth <= 768 && popup.classList.contains('cookieOnlyMobile')) {
                    popup.classList.add('cookiesShow');
                } else if (window.innerWidth > 768 && !popup.classList.contains('cookieOnlyMobile')) {
                    popup.classList.add('cookiesShow');
                }
            });

        });
    });

    settingsBtn.forEach((btn) => {
        btn.addEventListener('click', () => {
            settingsPopup.forEach((popup) => {
                if (window.innerWidth <= 768 && popup.classList.contains('cookieOnlyMobile')) {
                    popup.classList.add('cookiesShow');
                } else if (window.innerWidth > 768 && !popup.classList.contains('cookieOnlyMobile')) {
                    popup.classList.add('cookiesShow');
                }
            });
        });
    });

    openBanner.forEach((openBanr) => {
        openBanr.addEventListener('click', () => {
            openBanr.closest('.banner').childNodes.forEach((elem, index) => {
                if (elem.nodeType != 3) {
                    elem.classList.add('cookiesHide');
                }
            });
            openBanr.closest('.banner').classList.remove('closedCookieBanner');
            openBanr.classList.remove('cookiesHide');
        });
    });

    let openCookieBanner = (target) => {
        document.querySelectorAll('.cookieBanner.cookieOnlyMobile').forEach((cookieBanner) => {
            cookieBanner.querySelector('.banner').childNodes.forEach((elem, index) => {
                if (elem.nodeType != 3) {
                    elem.classList.add('cookiesHide');
                }
            });
            cookieBanner.querySelector('.banner').classList.remove('closedCookieBanner');
            cookieBanner.querySelector('.banner > #openBanner').classList.remove('cookiesHide');
            console.log(cookieBanner.querySelector('.banner > #openBanner'));
        });
    };
}