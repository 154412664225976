import {styled} from "goober";
import React from "react";

export const ControllerFinderContainer = styled('div', React.forwardRef)(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        gap: 25px;
        
        ul {
            margin: 0;
            padding: 0;
            display: flex;
            flex-flow: column nowrap;
            
            li {
                margin: 0;
                padding: 0;
                list-style: none;
                user-select: none;
                cursor: pointer;
                
                .form-group {
                    margin: 0;
                    
                    input {
                        margin: 0 !important;
                    }
                }
            }
        }
    `;
});

export const ControllerFinderEmptySpace = styled('div', React.forwardRef)(() => {
    return `
        width: 100%;
        height: 10px;
    `;
});