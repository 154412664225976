import Swal, {SweetAlertOptions} from "sweetalert2";
import {css} from "goober";
import {useTranslation} from "PlattixUI/PlattixReactCore/i18n";
import withReactContent from 'sweetalert2-react-content';
import React from "react";
import {HttpError} from "PlattixUI/PlattixReactCore/CoreTypes";

export type PlattixSwalConfigType = Omit<SweetAlertOptions, 'html'> & {
    /**
     * Color of the confirm button
     */
    confirmButtonColor?: 'red' | 'green' | 'blue' | 'grey';
    html?: string | React.ReactElement;
}

function GetSwalConfig(config: PlattixSwalConfigType):PlattixSwalConfigType {
    const {t} = useTranslation()

    const baseTitle = {
        'success': t('Swal.Success'),
        'error': t('Swal.Error'),
        'warning': t('Swal.Warning'),
        'info': t('Swal.Info'),
        'question': t('Swal.Question'),
    };

    const buttonColor = {
        'green': 'content-btn content-btn-1',
        'red': 'content-btn content-btn-2',
        'blue': 'content-btn content-btn-4',
        'grey': 'content-btn content-btn-5',
    }

    const titleHandler = () => {
        if (config.title) return config.title;
        if (config.icon) return baseTitle[config.icon];
        return '';
    }

    const buttonColorHandler = () => {
        if (!config.confirmButtonColor) return buttonColor['green'];
        return buttonColor[config.confirmButtonColor];
    }

    return {
        // default values
        title: titleHandler(),
        cancelButtonText: t('cancel'),

        showCloseButton: false,
        showLoaderOnConfirm: true,

        allowOutsideClick: () => !Swal.isLoading(),
        allowEscapeKey: false,
        allowEnterKey: false,
        backdrop: true,

        // Styling
        buttonsStyling: false,
        customClass: {
            popup: SwalPopupStyling,
            confirmButton: buttonColorHandler(),
            denyButton: "content-btn content-btn-2",
            cancelButton: "content-btn content-btn-5",
            icon: SwalIconStyling,
            title: SwalTitleStyling,
            htmlContainer: SwalHtmlContainerStyling,
            actions: SwalActionsStyling,
        },
        
        // overwrite defaults with config
        ...config,
    };
}

/**
 * Fire a Swal with Plattix styling and custom, applicable default values
 * @param props
 */
export function PlattixSwal(props: PlattixSwalConfigType) {
    
    const config = GetSwalConfig(props)
    
    if ( props.html === undefined || typeof (props.html) === 'string'){
        return Swal.fire({...config, html: props.html});
    } else {
        return withReactContent(Swal).fire(config);
    }
}

export function ErrorSwal(error: HttpError){
    return PlattixSwal({
        icon: "error",
        title: error.title,
        text: error.detail
    })
}


export const SwalPopupStyling = css`
    background: var(--backgroundColor1) !important;
    border: none !important;
    border-radius: 10px !important;
    padding: var(--padding2) !important;
    font-family: var(--defaultFontFamilyText) !important;
    font-size: var(--regularFontSize) !important;
    color: var(--styleColor4) !important;
    box-shadow: var(--shadow2) !important;
    gap: 10px;
    animation: none !important;
    
    .swal2-error {
        border-color: var(--buttonColorRed) !important;
        color: var(--buttonColorRed) !important;
            
        .swal2-x-mark {
            color: var(--buttonColorRed) !important;
        }
        
        [class^=swal2-x-mark-line] {
            background-color: var(--buttonColorRed) !important;
        }
    }
    
    .swal2-success {
        border-color: var(--buttonColorGreen) !important;
        color: var(--buttonColorGreen) !important;
        
        .swal2-success-ring {
            border-color: var(--buttonColorGreen) !important;
        }
        
        [class^=swal2-success-line] {
            background-color: var(--buttonColorGreen) !important;
        }
        
    }
    
    .swal2-warning {
        border-color: var(--styleColor3) !important;
        color: var(--styleColor3) !important;        
    }
    
    .swal2-info {
        border-color: var(--buttonColorBlue) !important;
        color: var(--buttonColorBlue) !important;        
    }
    
    .swal2-question {
        border-color: var(--buttonColorBlue) !important;
        color: var(--buttonColorBlue) !important;        
    }
`;

export const SwalIconStyling = css`
    font-size: 4px !important;
    margin: 0 !important;
    
    &, * {
        animation: none !important;
    }
`;

export const SwalTitleStyling = css`
    font-family: var(--defaultFontFamilyHeadline) !important;
    font-weight: var(--fontWeight3) !important;
    font-size: var(--titleFontSize) !important;
    padding: 0 !important;
    margin: 0 !important;
    text-align: left !important;
`;

export const SwalHtmlContainerStyling = css`
    font-size: 1em !important;
    font-weight: 500 !important;
    color: var(--styleColor4) !important;
    padding: 0 !important;
    margin: 0 !important;
    text-align: left !important;
`;

export const SwalActionsStyling = css`
    width: 100% !important;
    justify-content: flex-end !important;
    padding: 0 !important;
    margin: 0 !important;
    display: flex !important;
    gap: 10px !important;
`;