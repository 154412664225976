import {
    MarketplaceAddress,
    MarketplaceAddressContainer,
    MarketplaceAddressDetails
} from "PlattixSalesUI/Sales/MarketplaceStyling";
import {MarketplaceAddressProps, MarketplaceAddressTypeTranslationMap} from "PlattixSalesUI/Sales/MarketplaceUtil";
import {AddressModel, AddressType} from "PlattixUI/core/components/AddressComponent";
import {t} from "PlattixUI/PlattixReactCore/i18n";

export function MarketplaceAddresses(props: MarketplaceAddressProps) {
    return (
        <MarketplaceAddressContainer>
            <MarketplaceAddressComponent address={props.invoiceAddress} type={"invoice"}/>
            <hr />
            <MarketplaceAddressComponent address={props.deliveryAddress} type={"delivery"}/>
        </MarketplaceAddressContainer>
    );
}

export function MarketplaceAddressComponent(props: {address: AddressModel, type: AddressType}){
    return <MarketplaceAddress>
        {!!props.type &&
            <h5>{t(MarketplaceAddressTypeTranslationMap[props.type])}</h5>
        }
        <MarketplaceAddressDetails>
            {/*{!!props.address.title &&*/}
            {/*    <b>{props.address.title}</b>*/}
            {/*}*/}
            <p>{props.address.street} {props.address.address}</p>
            <p>{props.address.postalCodeCity}</p>
            {props.address.country &&
                <p>{props.address.country}</p>
            }
        </MarketplaceAddressDetails>
    </MarketplaceAddress>
}