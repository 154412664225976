import {MarketplaceCartProps, useActiveCart} from "PlattixSalesUI/Sales/MarketplaceUtil";
import {MarketplaceHeader} from "PlattixSalesUI/Sales/Marketplace/Components/MHeader";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {PlattixCard, PlattixCardContainer} from "PlattixUI/core/components/ContentCard";
import React, {useEffect} from "react";
import {ProductList} from "PlattixSalesUI/Sales/MarketplaceStyling";
import {LoadingScreen} from "PlattixUI/core/components/Loader";
import {MarketplaceProductOverview} from "PlattixSalesUI/Sales/Marketplace/Components/MProductOverview";

export function MarketplaceCart(props: MarketplaceCartProps) {
    const cartQuery = useActiveCart()

    useEffect(() => {
        console.log(cartQuery.data)
    }, [cartQuery.data]);
        
    if (cartQuery.isLoading) return <LoadingScreen />;
    
    const cartElement = cartQuery.data ? (
        <>            
            <MarketplaceProductOverview
                products={cartQuery.data.selectedItems}
                editable={props.editable ?? true}
                totalProductsExcl={cartQuery.data.totalProductsExcl}
                totalVat={cartQuery.data.totalVat}
                totalProductsIncl={cartQuery.data.totalProductsIncl}
                transportCost={cartQuery.data.transportCost}
                total={cartQuery.data.total}
            />
        </>
    ) : (
        <>
            <ProductList>
                <p>{t('Cart.IsEmpty')}</p>
            </ProductList>
        </>
    );
    
    if (props.page) return (
        <>
            <MarketplaceHeader
                title={t('Cart')}
                includeBtns={{
                    checkout: true, 
                    continueShopping: true,
                }}
            />
            <PlattixCardContainer>
                <PlattixCard
                    width={'full'}
                    header={t('Marketplace.Totals.Overview')}
                >
                    {cartElement}
                    
                </PlattixCard>
            </PlattixCardContainer>
        </>
    );

    return cartElement;
}