import React, {useState} from "react";
import {t} from "../../PlattixReactCore/i18n";
import htmlRaw from "../../util/HtmlRaw";
import {CancelButton, ConfirmButton} from "./Buttons";
import {ContentCardButtons} from "./ContentCard";
import {PlattixForm} from "PlattixUI/core/components/form/Form";
import {PlattixSubmitButton} from "PlattixUI/core/components/form/Input";
import {UseFormReturn} from "react-hook-form";

export interface PlattixMultiStepFormProps {
    onSubmission: any,
    onCancel?: any,
    loading?: boolean,
    form: UseFormReturn
}

export interface PlattixMultiStepFormStepProps {
    step: number,
    title?: string,
    description?: string,
    maxSteps?: number,
    currentStep?: number,
    onNext?: any,
    onPrevious?: any,
    onSubmission?: any,
    onCancel?: any,
    loading?: boolean,
    errors?: any[]
}

interface MultistepFormIndicatorProps<TModel> {
    currentStep: number,
    maxStep: number,
    steps: Array<Exclude<any, boolean | null | undefined>> | null | undefined,
    onStepClick: (number) => void
}

function MultistepFormIndicator<TModel>(props: MultistepFormIndicatorProps<TModel>) {
    function getClass(idx: number) {
        if (idx === props.currentStep) return 'active'
        if (idx > props.maxStep) return 'disabled'
        return ''
    }

    return (
        <div className={"module-content-steps"}>
            <ul className={'setup-panel'}>
                {
                    props.steps?.map((step, idx) => (
                        <li key={idx + 1} className={getClass(idx + 1)}
                            onClick={idx + 1 <= props.maxStep ? () => props.onStepClick(idx + 1) : undefined}>
                            <a>
                                <span className="step-bullet"/>
                                <h4 className="module-content-step">{t(step.title)}
                                </h4>
                            </a>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export function PlattixMultiStepForm(props: React.PropsWithChildren<PlattixMultiStepFormProps>) {
    const currentStepState = useState(1);
    const [currentStep, setCurrentStep] = currentStepState;

    const steps: Array<Exclude<any, boolean | null | undefined>> | null | undefined = React.Children?.map(props.children,
        (x) => {
            if (!React.isValidElement(x)) return
            return x?.props
        });

    const countChildren = steps?.length ?? 0;


    const next = async () => {
        const stepValid = await props.form.trigger()
        
        if (stepValid) setCurrentStep(currentStep + 1);
    }

    const previous = () => {
        setCurrentStep(currentStep - 1);
    }

    async function goToStep(step: number) {
        if (step <= 1) step = 1;
        if (step >= countChildren) step = countChildren;

        if (step < currentStep) {
            setCurrentStep(step)
            return;
        }

        /* if (!await validateStep(currentStep)){
             return;
         }*/

        setCurrentStep(step)
    }


    /*  async function validateStep(step: number): Promise<boolean> {
  
          const fields = props.steps[step].fields;
  
          const result = await form.trigger(fields.map(f => f.name))
  
          // let valid: boolean = true
          // for (let i = 0; i < fields.length; i++) {
          //     valid &&= await ValidateField(form, {label: '', name: fields[i].name, validation:fields[i].validation, formHook: form})
          // }
          return result
  
      }
  */
    return (
        <>

            <MultistepFormIndicator currentStep={currentStep} maxStep={countChildren} steps={steps}
                                    onStepClick={goToStep}/>
            <PlattixForm onSubmit={props.onSubmission}>
                {
                    React.Children.map(props.children, child => {
                        if (React.isValidElement(child))
                            return React.cloneElement(child, {
                                maxSteps: countChildren,
                                currentStep: currentStep,
                                onCancel: props.onCancel,
                                onNext: next,
                                onPrevious: previous,
                                onSubmission: props.onSubmission,
                                loading: props.loading || props.form.formState.isValidating,
                            })
                    })
                }
            </PlattixForm>


        </>
    )
}

export function PlattixMultiStepFormStep(props: React.PropsWithChildren<PlattixMultiStepFormStepProps>) {

    if (props.step === props.currentStep) {
        return (
            <>
                <h4 className="step-title">{props.title ? t(props.title) : ""}</h4>

                {
                    (props.description) &&
                    <div className="step-description-container">
                        {htmlRaw(props.description ? t(props.description) : "")}
                    </div>
                }

                {
                    props.children
                }
                 
                <ContentCardButtons>
                    {(props.onCancel) &&
                        <CancelButton className={'next'} onClick={props.onCancel}>
                            {t("Cancel")}
                        </CancelButton>
                    }

                    {((props.currentStep ?? -1) > 1 && (props.currentStep ?? -1) <= (props.maxSteps ?? 0)) &&
                        <ConfirmButton className={'next'} onClick={props.onPrevious}>
                            {t("Previous")}
                        </ConfirmButton>
                    }

                    {(props.currentStep ?? -1) >= 1 && ((props.currentStep ?? -1) < (props.maxSteps ?? 0)) &&
                        <ConfirmButton className={'next'} onClick={props.onNext} loading={props.loading}>
                            {t("Next")}
                        </ConfirmButton>
                    }

                    {(props.currentStep === (props.maxSteps ?? 0)) &&
                        // <ConfirmButton onClick={props.onSubmission} loading={props.loading}>
                        //     {t("Submit")}
                        // </ConfirmButton>
                        <PlattixSubmitButton name={t("Submit")}  loading={props.loading}/>
                    }
                    
                </ContentCardButtons>
            </>
        )
    }
    return (
        <>
        </>
    )
}
