import {
    CheckoutOrderQueryKey,
    MarketplaceAddressTypeTranslationMap,
    MarketplaceCheckoutModel,
    MarketplaceCheckoutProps,
    useActiveCart
} from "PlattixSalesUI/Sales/MarketplaceUtil";
import {generatePath} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {MarketplaceHeader} from "PlattixSalesUI/Sales/Marketplace/Components/MHeader";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {ContentCardButtons, PlattixCard, PlattixCardContainer} from "PlattixUI/core/components/ContentCard";
import {useForm} from "react-hook-form";
import {MarketplaceProductOverview} from "PlattixSalesUI/Sales/Marketplace/Components/MProductOverview";
import {PlattixValidatedInput} from "PlattixUI/core/components/form/Input";
import {doPost, isHttpError, usePlattixQuery} from "PlattixUI/PlattixReactCore/api/Api";
import {Endpoints} from "configuration/ApiEnpointMap";
import {PlattixForm} from "PlattixUI/core/components/form/Form";
import {ConfirmButton} from "PlattixUI/core/components/Buttons";
import {PlattixSwal} from "PlattixUI/core/components/Swal";
import {MarketplaceAddressComponent} from "PlattixSalesUI/Sales/Marketplace/Components/MAddresses";
import {MarketplaceCheckoutAddress, MarketplaceCheckoutAddressContainer} from "PlattixSalesUI/Sales/MarketplaceStyling";
import {faMoneyBill} from "@fortawesome/free-solid-svg-icons";
import {history} from "PlattixUI/PlattixReactCore/store"
import {MarketplaceRouteMap} from "PlattixSalesUI/configuration/RouteMap";

export function MarketplaceCheckout(props: MarketplaceCheckoutProps) {
    const cartQuery = useActiveCart()

    const checkoutDataQuery = usePlattixQuery<MarketplaceCheckoutModel>(
        [CheckoutOrderQueryKey, cartQuery.data?.customerAccountId],
        generatePath(Endpoints.Marketplace.Checkout(cartQuery.data?.shoppingCartId)), {},
        {
            enabled: !cartQuery.isLoading && !!cartQuery.data?.customerAccountId
        }
    )
    const mailingAddress = checkoutDataQuery.data?.mailingAddress;
    const form = useForm<MarketplaceCheckoutModel>({defaultValues: checkoutDataQuery.data as any, mode: 'all'});
    const [deliverySame, invoiceSame] = form.watch(["deliveryAddressSame", "invoiceAddressSame"])
    
    // const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = props.isSubmittingState;

    useEffect(() => {
        if (checkoutDataQuery.data) {
            form.reset(checkoutDataQuery.data)
        }
    }, [checkoutDataQuery.data, form])

    async function onSubmitHandler (model: MarketplaceCheckoutModel) {
        setIsSubmitting(true)

        const result = await doPost<{paymentUrl: string, orderId: number}>(Endpoints.Marketplace.Checkout(cartQuery.data?.shoppingCartId ?? -1), model)
        
        if (isHttpError(result)) {
            setIsSubmitting(false)
            return PlattixSwal({
                title: result.detail ?? result.title
            })
        } 
        
        if (result.paymentUrl) {
            window.location.href = result.paymentUrl
        }
        else {
            history.push(generatePath(MarketplaceRouteMap.OrderDetail(), {orderId: result.orderId}))
        }
        
        setIsSubmitting(false)
    }
    
    const emptyPageLayout: (title: string) => JSX.Element = (title) => {
        if (!props.page) return <p>{title}</p>;
        return (
            <>
                <MarketplaceHeader
                    title={title}
                    includeBtns={{
                        cart: true,
                        continueShopping: true
                    }}
                />
            </>
        );
    }
    
    console.log(`mailingAddress`, mailingAddress);

    // if (!cartQuery.data)
    //     return <h1>Geen winkelmandje</h1>

    // if (!cartQuery.data.selectedItems.length)
    //     return <h1>Mandje is leeg</h1>

    // if (!cartQuery.data.customerAccountId)
    //     // TODO
    //     return <h1>Kies eerst een account</h1>
    
    const paymentButtons = (
        <ContentCardButtons>
            <ConfirmButton
                loading={isSubmitting}
                type={"submit"}
                icon={faMoneyBill}
                form={'checkoutForm'}
            >
                {t('Shop.Checkout.ConfirmOrder.Btn')}
            </ConfirmButton>
        </ContentCardButtons>
    );
    
    const AddressFormInputs = (
        <MarketplaceCheckoutAddressContainer>

            <MarketplaceCheckoutAddress>
                {!cartQuery.data?.servicepointId &&
                    <h5>{t("Shop.Checkout.Servicepoint.NoServicepoint")}</h5>
                }

                {cartQuery.data?.servicepointId &&
                    <>
                        {/*<MarketplaceAddressComponent address={}  type={"mailing"}/>*/}
                    </>
                }
            </MarketplaceCheckoutAddress>

            <hr/>

            <MarketplaceCheckoutAddress>
                {!!mailingAddress && <MarketplaceAddressComponent address={mailingAddress} type={"mailing"}/> }
            </MarketplaceCheckoutAddress>

            <hr/>

            <MarketplaceCheckoutAddress>
                <h5>{t('Shop.Checkout.Shipping.Title')}</h5>

                <PlattixValidatedInput<MarketplaceCheckoutModel>
                    formHook={form}
                    name={"deliveryAddressSame"}
                    label={t('Shop.Checkout.Shipping.SameAddress', {address: t(MarketplaceAddressTypeTranslationMap['account'])})}
                    type={"checkbox"}
                />

                {!deliverySame &&
                    <PlattixValidatedInput<MarketplaceCheckoutModel>
                        formHook={form}
                        name={"deliveryAddress"}
                        type={"address"}
                    />
                }
            </MarketplaceCheckoutAddress>

            <hr/>

            <MarketplaceCheckoutAddress>
                <h5>{t('Shop.Checkout.Invoice.Title')}</h5>

                <PlattixValidatedInput<MarketplaceCheckoutModel>
                    formHook={form}
                    name={"invoiceAddressSame"}
                    label={t('Shop.Checkout.Invoice.SameAddress', {address: t(MarketplaceAddressTypeTranslationMap['account'])})}
                    type={"checkbox"}
                />

                {!invoiceSame &&
                    <PlattixValidatedInput<MarketplaceCheckoutModel>
                        formHook={form} name={"invoiceAddress"}
                        type={"address"}
                    />
                }
            </MarketplaceCheckoutAddress>
        </MarketplaceCheckoutAddressContainer>
    );
    
    console.log(cartQuery.data)
    
    if (!cartQuery.data) return emptyPageLayout(t('Cart.IsEmpty'));
    if (!cartQuery.data.selectedItems.length) return emptyPageLayout(t('Cart.IsEmpty'));
    if (!cartQuery.data.customerAccountId) return emptyPageLayout(t('Account.Choose.OrMakeOne')); // TODO
    
    if (props.page) return (
        <>
            <MarketplaceHeader
                title={t('Checkout')}
                includeBtns={{
                    cart: true,
                    continueShopping: true
                }}
            />
            <PlattixForm onSubmit={form.handleSubmit(onSubmitHandler)} id={'checkoutForm'}>
                <PlattixCardContainer>

                    <PlattixCard width={"span2"} header={t("Shop.Checkout.Account.Header")}>
                        {AddressFormInputs}
                    </PlattixCard>

                    <PlattixCard header={t("Shop.Checkout.Products.Overview.Header")}>
                        <MarketplaceProductOverview
                            products={cartQuery.data.selectedItems}
                            editable={false}
                            totalProductsExcl={cartQuery.data.totalProductsExcl}
                            totalVat={cartQuery.data.totalVat}
                            totalProductsIncl={cartQuery.data.totalProductsIncl}
                            transportCost={cartQuery.data.transportCost}
                            total={cartQuery.data.total}
                            smallLayout
                        />
                        {paymentButtons}
                    </PlattixCard>

                </PlattixCardContainer>
            </PlattixForm>
        </>
    );

    return (
        <PlattixForm onSubmit={form.handleSubmit(onSubmitHandler)} id={'checkoutForm'}>
            {AddressFormInputs}
            {!props.hidePayBtn && paymentButtons}
        </PlattixForm>
    )
}