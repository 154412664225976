import 'PlattixUI/extensions/date.extensions'

export const Slugs = {
    Developer: '/Developer',
    Admin: '/Admin',
    Account: '/Account',
};

export const NotificationsPath = '/Notifications';

export const NotificationsRouteMap = {
    overview: NotificationsPath,
}
