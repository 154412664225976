import {InvoiceTypeCode, SalesType} from "PlattixSalesUI/core/types/Sales";
import {doGet, throwOnHttpError} from "PlattixUI/PlattixReactCore/api/Api";

let tactixUrl = '/';

throwOnHttpError(doGet('/clientsettings.json', undefined, {
    remote: true,
    throwOnError: true
})).then(response => tactixUrl = response['Platforms']?.['Tactix']?.url);

export const SalesRoutes = {
    Slug: '/Sales',
    SlugForType: (type: SalesType, typeCode: InvoiceTypeCode | undefined) => {
        
        if (type === "Invoice"){
            if (typeCode === InvoiceTypeCode.Invoice) return SalesRoutes.Invoice.Slug
            if (typeCode === InvoiceTypeCode.CreditNote) return SalesRoutes.CreditNote.Slug
        }

        return SalesRoutes[type].Slug
    },
    Invoice: {
        Slug: `/Sales/:accountId/Invoice`,
        Detail: `/Sales/:accountId/Invoice/:id`
    },
    Contract: {
        Slug: `/Sales/Contract`,
        Detail: `/Sales/Contract/:id`
    },
    CreditNote: {
        Slug: `/Sales/:accountId/CreditNote`,
        Detail: `/Sales/:accountId/CreditNote/:id`
    },
    AccountContract: {
        Slug: `/Sales/:accountId/AccountContract`,
        Detail: `/Sales/:accountId/AccountContract/:id`
    },
    Offer: {
        Slug: `/Sales/:accountId/Quotation`,
        Detail: `/Sales/:accountId/Quotation/:id`
    },
    Order: {
        Slug: `/Sales/:accountId/Order`,
        Detail: `/Sales/:accountId/Order/:id`
    },
    Product: {
        Slug: `/Sales/:accountId/Product`,
        Detail: `/Sales/:accountId/Product/:id`
    },
    PriceStructure: {
        Slug: `/Sales/:accountId/Pricestructure`,
        Detail: `/Sales/:accountId/Pricestructure/:id`,
        PriceStructureDetail: `/Sales/:accountId/Pricestructure/:id/Detail/:detailId`
    },
    Detail: (type: SalesType, typeCode: InvoiceTypeCode | undefined) => {
        if (type === "Invoice"){
            if (typeCode === InvoiceTypeCode.Invoice) return SalesRoutes.Invoice.Detail
            if (typeCode === InvoiceTypeCode.CreditNote) return SalesRoutes.CreditNote.Detail
        }
        
        return SalesRoutes[type].Detail
    },
}

export const SalesEndpoints = {
    Detail: (type: SalesType, id: number) =>
        `/Sales/${type}/${id}`,
    SalesDetail: (type: SalesType, salesId: number, detailId: number) =>
        `/Sales/${type}/${salesId}/Detail/${detailId}`,
    DownloadPDF: (type: SalesType, salesId: number) =>
        `${tactixUrl}/Downloads` + SalesEndpoints.Detail(type, salesId),

    Text: {
        Detail: (type: SalesType | undefined, salesId: number | undefined, textId: number | undefined) =>
            `/Sales/${type ?? 'sales'}/${salesId ?? -1}/Text/${textId ?? -1}`,
        MoveDetails: (type: SalesType | undefined, salesId: number | undefined, textId: number | undefined) =>
            `/Sales/${type ?? 'sales'}/${salesId ?? -1}/Text/${textId ?? -1}/Move`,
        Reorder: (type: SalesType | undefined, salesId: number | undefined) =>
            `/Sales/${type ?? 'sales'}/${salesId ?? -1}/Text/Reorder`,
        ReorderDetail: (type: SalesType | undefined, salesId: number | undefined, textId: number | undefined) =>
            SalesEndpoints.Text.Detail(type, salesId, textId) + `/ReorderDetail`,
    },
    
    Products: (type: SalesType | undefined, salesId: number | undefined) =>
        `/Sales/${type ?? 'sales'}/${salesId ?? -1}/Products`,
    DeleteProducts: (type: SalesType | undefined, customerId: number | undefined) =>
        `/Sales/${type ?? 'sales'}/${customerId ?? -1}/Products`,
    Product: (ownerId: number | undefined, id: number | undefined) =>
        `/Sales/${ownerId ?? -1}/Product/${id ?? -1}`,
    CalculatePrice: (ownerId: number | undefined, customerId: number | undefined, productId: number | undefined) =>
        `/Sales/CalculatePrice/${ownerId}/${customerId}/${productId}`,
    
    ProductComposition: (accountId: number | undefined | null, productId: number | undefined | null) =>
        `/Sales/${accountId??-1}/Product/${productId ?? -1}/Composition`,
    ProductCompositionDetail: (accountId: number | undefined | null, productId: number | undefined | null, detailId: number | undefined | null) =>
        `/Sales/${accountId??-1}/Product/${productId ?? -1}/Composition/Detail/${detailId ?? -1}`,
    ProductCompositionDetailDeleteRange: (accountId: number | undefined | null, productId: number | undefined | null) =>
        `/Sales/${accountId??-1}/Product/${productId ?? -1}/Composition/Range`,
    
    Invoice: {
        CreateCreditNote: (invoiceId: number|null|undefined) => `/Sales/Invoice/${invoiceId ?? -1}/CreditNote`,
        SendInvoices: () => `/EnergytixInvoice/SendInvoices`,
    },
    
    Order: {
        CreateInvoice: (orderId: number|null|undefined) => `/Sales/Order/${orderId ?? -1}/CreateInvoice`,
    }
};
