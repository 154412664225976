import {t} from "PlattixUI/PlattixReactCore/i18n";
import {Link} from "react-router-dom";
import * as React from "react";

export interface LoginOptionsProps {
    hide?: {
        login?: boolean;
        register?: boolean;
        forgotPassword?: boolean;
        TwoFactorAuth?: boolean;
    };
}

export function LoginOptions(props: LoginOptionsProps) {
    return (
        <div className="login-content-group-container-bottom">
            
            {!props.hide?.login &&
                <div className="login-content-group">
                    <p>{t('LoginOptions.Login.Title')}</p>
                    <Link to="/login">{t('LoginOptions.Login.Description')}</Link>
                </div>
            }
            
            {!props.hide?.forgotPassword &&
                <div className="login-content-group">
                    <p>{t('ForgotPassword?')}</p>
                    <Link to="/forgot-password">{t('Reset')}</Link>
                </div>
            }

            {!props.hide?.TwoFactorAuth &&
                <div className="login-content-group">
                    <p>{t('Forgot2FA')}</p>
                    <Link to="/2fa-reset">{t('Reset2FA')}</Link>
                </div>
            }

            {!props.hide?.register &&
                <div className="login-content-group">
                    <p>{t('NoAccount')}</p>
                    <Link to="/register">{t('Register')}</Link>
                </div>
            }
        </div>
    );
}