import {t} from "PlattixUI/PlattixReactCore/i18n";
import {FieldPath, Path, PathValue, ValidateResult} from "react-hook-form";
import {doGet, isHttpError} from "PlattixUI/PlattixReactCore/api/Api";
import {Validator} from "PlattixUI/core/forms/Validators/Validator";

/**
 * Validate using a remote function
 */
export class RemoteValidator<TFieldValues> extends Validator<TFieldValues> {
    private url: string;
    private includeValues: FieldPath<TFieldValues>[] | undefined
    private valid: boolean = true;
    private loading: boolean = false;
    
    constructor(url: string, includeValues?: FieldPath<TFieldValues>[]) {
        super();
        this.name = 'remote'
        this.message = t('Validation.Error.Remote')
        this.url = url;
        this.includeValues = includeValues;
    }
    
    protected get isValid(): boolean  {
        return this.valid;
    }
    
    get isLoading(): boolean {
        // by default, validators are synchronous
        return this.loading
    }

    validate(value: PathValue<TFieldValues, Path<TFieldValues>>): ValidateResult | Promise<ValidateResult> {
        if (!this.form || !this.field) throw new Error("Form and/or field not set");
        this.valid = true;
        this.loading = true;
        
        this.form.getValues()
        // TODO: aantal requests proberen beperken
        const params: { [key: string]:any } = this.includeValues?.reduce(
            (a,b) => {
                a[b as string] = this.form?.getValues(b);return a}, {})
        ?? {}
        params[this.field] = value

        return new Promise<ValidateResult>((resolve, reject) => {
            doGet<RemoteValidationResult>(this.url, params)
                .then(response => {
                    if (isHttpError(response)) {
                        this.message = response.detail ?? response.title
                        return false
                    }

                    this.message = response.error;
                    this.valid = response.valid;
                    resolve(response.valid);
                })
                .catch(reject)
                .finally(() => this.loading = false) 
        })

    }
}

export type RemoteValidationResult = {
    valid: boolean,
    error?: string
}