import {IPermissioned} from "PlattixUI/types/AccountTypes";
import {hasPlatformPermissionSelector, isAdminSelector} from "PlattixUI/PlattixReactCore/UserSlice";
import {store} from "PlattixUI/PlattixReactCore/store";

export type PlattixPermissionType =
    // Platform
    | "Settings.CRM"
    | "Settings.System"
    | "Settings.Sales"
    | "Invitation"
    | "Roles"
    // Energytix
    | "Servicepoint"
    | "ServicepointAsset"
    | "ServicepointState"
    | "EnergytixGateway"
    | "EnergyCommunity"
    | "Account"
    // Advisory
    | "AdvisoryInstallation"
    | "AdvisorySimulation"
    // Mobility
    | "Mobility"
    | "MobilityChargingTransactionOverview"
    | "Analytics"
    ;

export type PermissionAction = "View" | "Edit" | "Create" | "Delete" | "Admin" | "List"

export type PermissionsType = {
    entity: "Platform" | IPermissioned | null | undefined,
    permission: PlattixPermissionType,
    action: PermissionAction
}

export type PlatformPermissionsType = PermissionsType & {
    entity: "Platform"
}

export type EntityPermissionsType = PermissionsType & {
    entity: IPermissioned | null | undefined,
}

/**
 * Checks whether the users claims on the entity include the provided permission and action.
 * For an admin, all permissions are granted
 * 
 * Important note. If the claims are not available in the frontend (i.e. not fetched from backend), no permissions will be granted.
 * @param entity: entity to check permisison on
 * @param permission: Permission to check
 * @param action: action type for permission to check
 */
export function hasEntityPermission(entity: IPermissioned | null | undefined, permission: PlattixPermissionType, action: PermissionAction){
    if (!entity) return false;
    
    if (isAdminSelector(store.getState())) return true
    
    return entity.claims.includes(`${permission}.${action}`)
}

export function hasPermission(entity: "Platform" | IPermissioned | null | undefined, permission: PlattixPermissionType, action: PermissionAction) {
    if (!entity) return false;

    if (isAdminSelector(store.getState())) return true
    
    if (entity === "Platform") 
        return hasPlatformPermissionSelector(store.getState())(permission);
    else 
        return hasEntityPermission(entity, permission, action);
}

