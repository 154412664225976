import iconFull from 'PlattixUI/images/branding/energytix/icon/disassembledIcon/IconForAnimation.svg';
import iconBackground from 'PlattixUI/images/branding/energytix/icon/disassembledIcon/IconForAnimation1.svg';
import iconBlueRect from 'PlattixUI/images/branding/energytix/icon/disassembledIcon/IconForAnimation2.svg';
import iconYellowShape from 'PlattixUI/images/branding/energytix/icon/disassembledIcon/IconForAnimation3.svg';
import iconBlueBolt from 'PlattixUI/images/branding/energytix/icon/disassembledIcon/IconForAnimation4.svg';
import {css, styled} from "goober";
import {useEffect, useState} from "react";
import {useImmer} from "use-immer";

export interface ErrorBaseProps {
    title: string;
}

export function ErrorBase(props: ErrorBaseProps) {    
    return (
        <>
            <h1>{props.title}</h1>
            <InteractiveIcon />
        </>
    );
}

export function Error404() {
    return (
        <>
            <ErrorCodeContainer>
                <h6>4</h6>
                <InteractiveIcon />
                <h6>4</h6>
            </ErrorCodeContainer>
        </>
    );
}

export const IconContainer = styled('div')(() => {
    return `
        &, svg {
            height: 300px;
        }
    `;
});

export const ErrorCodeContainer = styled('div')(() => {
    return `
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        
        h6 {
            font-size: calc(300px * 1.3);
            font-weight: 600;
            color: #333;
            margin: 0;
            padding: 0;
        }
    `;
});

export interface IconImgProps {
    rx: string;
    ry: string;
    tx: string;
    ty: string;
    dsx: string;
    dsy: string;
    dsz: string;
}

export const GRotation = styled('g')((props: IconImgProps) => {
    return `
        transform: rotateX(${props.ry}) rotateY(${props.rx}) translate(${props.tx}, ${props.ty});
        transform-origin: center;
        filter: drop-shadow(${props.dsx} ${props.dsy} ${props.dsz} rgb(0 0 0 / 0.3));
    `;
});

export const iconSvgStyling = css`
    overflow: visible;
    
    .e {
        fill:#f4f4f4;
    }
    
    .f {
        fill:#3db293;
    }
    
    .g {
        fill:#fbbc12;
    }
    
    .h {
        fill:#264653;
    }
`;

export function InteractiveIcon() {
    const [rotations, setRotations] = useState({
        elem1: {rX: '0deg', rY: '0deg', tX: '0px', tY: '0px', dsX: '0px', dsY: '0px', dsZ: '0px'},
        elem2: {rX: '0deg', rY: '0deg', tX: '0px', tY: '0px', dsX: '0px', dsY: '0px', dsZ: '0px'},
        elem3: {rX: '0deg', rY: '0deg', tX: '0px', tY: '0px', dsX: '0px', dsY: '0px', dsZ: '0px'},
        elem4: {rX: '0deg', rY: '0deg', tX: '0px', tY: '0px', dsX: '0px', dsY: '0px', dsZ: '0px'},
    });
    
    const getRotation = (e, inputValues: {[key: string]: number}) => {
        const centerPoint = {
            x: window.innerWidth / 2,
            y: window.innerHeight / 2,
        };
        
        const mousePos = {
            x: e.clientX,
            y: e.clientY,
        };

        const multiplier = {
            rX: (centerPoint.x - mousePos.x) < 0 ? 1 : -1,
            rY: (centerPoint.y - mousePos.y) < 0 ? -1 : 1,
            tX: (centerPoint.x - mousePos.x) < 0 ? 1 : -1,
            tY: (centerPoint.y - mousePos.y) < 0 ? 1 : -1,
            dsX: (centerPoint.x - mousePos.x) < 0 ? -1 : 1,
            dsY: (centerPoint.y - mousePos.y) < 0 ? -1 : 1,
            dsZ: (centerPoint.y - mousePos.y) < 0 ? -1 : 1,
        };

        const positionPercentage = {
            x: ((100 * Math.abs(centerPoint.x - mousePos.x)) / centerPoint.x),
            y: ((100 * Math.abs(centerPoint.y - mousePos.y)) / centerPoint.y),
        };

        const rotationDeg = {
            x: ((inputValues.rX * positionPercentage.x) / 100) * multiplier.rX,
            y: ((inputValues.rY * positionPercentage.y) / 100) * multiplier.rY,
        };
        
        const translationSize = {
            x: ((inputValues.tX * positionPercentage.x) / 100) * multiplier.tX,
            y: ((inputValues.tY * positionPercentage.y) / 100) * multiplier.tY,
        };
        
        const dropShadowSize = {
            x: ((inputValues.dsX * positionPercentage.x) / 100) * multiplier.dsX,
            y: ((inputValues.dsY * positionPercentage.y) / 100) * multiplier.dsY,
            z: Math.abs(((inputValues.dsZ * Math.max(positionPercentage.x, positionPercentage.y)) / 100) * multiplier.dsZ),
        };

        const values = {
            rX: `${rotationDeg.x.toFixed(0)}deg`,
            rY: `${rotationDeg.y.toFixed(0)}deg`,
            tX: `${translationSize.x.toFixed(0)}px`,
            tY: `${translationSize.y.toFixed(0)}px`,
            dsX: `${dropShadowSize.x.toFixed(0)}px`,
            dsY: `${dropShadowSize.y.toFixed(0)}px`,
            dsZ: `${dropShadowSize.z.toFixed(0)}px`,
        };
        
        return values;
    };
    
    const rotationHandler = (e) => {
        const elementRotations = {
            elem1: getRotation(e, {rX: 30, rY: 30, tX: 20, tY: 20, dsX: 20, dsY: 20, dsZ: 10}),
            elem2: getRotation(e, {rX: 35, rY: 35, tX: 40, tY: 40, dsX: 40, dsY: 40, dsZ: 10}),
            elem3: getRotation(e, {rX: 40, rY: 40, tX: 70, tY: 70, dsX: 70, dsY: 70, dsZ: 10}),
            elem4: getRotation(e, {rX: 45, rY: 45, tX: 100, tY: 100, dsX: 100, dsY: 100, dsZ: 10}),
        };

        setRotations(elementRotations);
    }

    useEffect(() => {
        window.addEventListener("mousemove", rotationHandler);
        return () => window.removeEventListener("mousemove", rotationHandler);
    });
    
    return (
        <IconContainer onMouseMove={rotationHandler}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" className={iconSvgStyling}>
                <g id="a"/>
                <g id="b"/>
                <g id="c"/>
                <g id="d">
                    <g>
                        <GRotation
                            rx={rotations.elem1.rX}
                            ry={rotations.elem1.rY}
                            tx={rotations.elem1.tX}
                            ty={rotations.elem1.tY}
                            dsx={rotations.elem1.dsX}
                            dsy={rotations.elem1.dsY}
                            dsz={rotations.elem1.dsZ}
                        >
                            {/*<rect*/}
                            {/*    className="e"*/}
                            {/*    x="0"*/}
                            {/*    width="500"*/}
                            {/*    height="500"*/}
                            {/*    rx="113.18"*/}
                            {/*    ry="113.18"*/}
                            {/*/>*/}
                            <circle
                                className="e"
                                cx="250"
                                cy="250"
                                r="250"
                            />
                        </GRotation>
                        <GRotation
                            rx={rotations.elem2.rX}
                            ry={rotations.elem2.rY}
                            tx={rotations.elem2.tX}
                            ty={rotations.elem2.tY}
                            dsx={rotations.elem1.dsX}
                            dsy={rotations.elem1.dsY}
                            dsz={rotations.elem1.dsZ}
                        >
                            <polygon
                                className="g"
                                points="250 219.18 250 75.65 99 162.82 99 280.82 250 280.82 250 424.35 400.99 337.18 400.99 219.18 250 219.18"
                            />
                        </GRotation>
                        <GRotation
                            rx={rotations.elem3.rX}
                            ry={rotations.elem3.rY}
                            tx={rotations.elem3.tX}
                            ty={rotations.elem3.tY}
                            dsx={rotations.elem1.dsX}
                            dsy={rotations.elem1.dsY}
                            dsz={rotations.elem1.dsZ}
                        >
                            <rect
                                className="f"
                                x="99.01"
                                y="162.82"
                                width="301.99"
                                height="174.35"
                                transform="translate(-91.51 341.51) rotate(-60)"
                            />
                        </GRotation>
                        <GRotation
                            rx={rotations.elem4.rX}
                            ry={rotations.elem4.rY}
                            tx={rotations.elem4.tX}
                            ty={rotations.elem4.tY}
                            dsx={rotations.elem1.dsX}
                            dsy={rotations.elem1.dsY}
                            dsz={rotations.elem1.dsZ}
                        >
                            <polygon
                                className="h"
                                points="250 219.18 250 75.65 131.54 280.81 250 280.81 250 424.35 250 424.35 368.45 219.18 250 219.18"
                            />
                        </GRotation>
                        
                    </g>
                </g>
            </svg>
        </IconContainer>
    );
}