import {Page} from "PlattixUI/core/Header";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {usePlattixQuery} from "PlattixUI/PlattixReactCore/api/Api";
import {ProductListViewModel, useActiveCart} from "PlattixSalesUI/Sales/MarketplaceUtil";
import {Endpoints} from "configuration/ApiEnpointMap";
import React, {useEffect, useMemo} from "react";
import {PlattixCard, PlattixCardContainer} from "PlattixUI/core/components/ContentCard";
import {PlattixValidatedInput} from "PlattixUI/core/components/form/Input";
import {FieldPath, useForm} from "react-hook-form";
import {MarketplaceHeader} from "PlattixSalesUI/Sales/Marketplace/Components/MHeader";
import {ControllerFinderContainer, ControllerFinderEmptySpace} from "pages/Controllers/ControllerFinderStyling";
import {ProductShowcase} from "PlattixSalesUI/Sales/Products/ProductShowcase";
import {MarketplaceRouteMap} from "configuration/RouteMap";
import {generatePath} from "react-router-dom";

interface ControllerFinderFilter {
    "License": boolean,
    "Basic": boolean,
    "Advanced": boolean,
    "Self-installation": boolean,
    "Startkit": boolean,
    "License-limited": boolean,
    "License-unlimited": boolean,
    "Controller": boolean,
}

interface ProductGroup {
    title: string,
    description: string,
    tags: ProductGroupTag[]
}

interface ProductGroupTag {
    id: FieldPath<ControllerFinderFilter>,
    name: string
}

const productGroups: ProductGroup[] = [
    {
        title: "Soort controller",
        description: "Met een basis controller kunt u maximaal 10 devices verbinden terwijl u er met een geavanceerde controller er maximaal 50 aan kunt verbinden.",
        tags: [
            {
                id: "Basic",
                name: "ControllerFinder.Tag.Basic"
            },
            {
                id: "Advanced",
                name: "ControllerFinder.Tag.Advanced"
            },
        ]
    },
    {
        title: "Startkit of enkel product",
        description: "Een startkit is de perfecte keuze voor mensen die pas beginnen en volop willen genieten van Energytix. Heeft u al een licentie? Ga dan voor een controller.",
        tags: [
            {
                id: "Startkit",
                name: "ControllerFinder.Tag.StartKit"
            },
            {
                id: "Controller",
                name: "ControllerFinder.Tag.Controller"
            },
        ]
    },
    {
        title: "Licentieduur",
        description: "Met een levenslange licentie hoeft u zich in de toekomst geen zorgen meer te maken over de hernieuwing hiervan. Voor de twijfelaars is er ook een licentie van kortere duur.",
        tags: [
            {
                id: "License-limited",
                name: "ControllerFinder.Tag.LicenceLimited"
            },
            {
                id: "License-unlimited",
                name: "ControllerFinder.Tag.LicenceUnlimited"
            },
        ]
    },
]

export function ControllerFinder() {
    const activeCartQuery = useActiveCart()
    const form = useForm<ControllerFinderFilter>()

    const productsQuery = usePlattixQuery<ProductListViewModel>(
        // Product list depends on active price structure
        ['productsQuery', activeCartQuery.data?.pricestructureId ?? -1],
        Endpoints.Marketplace.Products(),
        {
            category: '40'
        }
    )

    const products = productsQuery.data?.products
    const watches = form.watch();
    const filteredProducts = useMemo(() => {
        const selected = Object.keys(watches).filter(k => watches[k]);
        
        if (!products?.length) return [];

        let filteredProducts = products;
        for(let group of productGroups){
            const selectedGroupTags = group.tags.map(g => g.id).filter(g => selected.includes(g))
            if (selectedGroupTags.length){
                filteredProducts = filteredProducts.filter(p => p.tags.includes(selectedGroupTags[0]))
            }
        }

        return filteredProducts
    }, [products, watches])
    
    function toggleOther(tags: ProductGroupTag[], clickedTag: ProductGroupTag){
        tags.map(g => g.id).filter(g => g !== clickedTag.id).forEach(g => form.setValue(g, false))
    }


    return (
        <Page 
            loading={productsQuery.isLoading} 
            error={productsQuery.error}
        >
        <MarketplaceHeader
            title={t('ControllerFinder.Header')}
            includeBtns={{
                checkout: true,
                cart: true
            }}
        />
            
        <PlattixCardContainer>

            <PlattixCard header={"keuzemenu"} width={"single"}>
                <ControllerFinderContainer>
                    {
                        productGroups.map(pg =>
                            <ul key={pg.title}>
                                <h5>{pg.title}</h5>
                                <p>{pg.description}</p>
                                <ControllerFinderEmptySpace />
                                {
                                    pg.tags.map(tag => {
                                        return (
                                            <li key={tag.id}>
                                                <PlattixValidatedInput 
                                                    formHook={form} 
                                                    name={tag.id} 
                                                    type={"checkbox"}
                                                    label={t(tag.name)}
                                                    onClick={() =>toggleOther(pg.tags, tag)}
                                                />
                                            </li>
                                        );
                                    })
                                }
                            </ul>)
                    }
                </ControllerFinderContainer>
            </PlattixCard>

            {filteredProducts.map((product, index) => {
                return (
                    <ProductShowcase
                        product={product}
                        endPoints={{
                            addToCart: Endpoints.Marketplace.AddToCart(),
                            productDetail: MarketplaceRouteMap.Product(),
                            updateProductAmount: Endpoints.Marketplace.UpdateProductAmount(),
                            getActiveCart: generatePath(Endpoints.Marketplace.GetActiveCart()),
                        }}
                        key={`${product.productId}_${index}`}
                    />
                )
            })}
            
            {filteredProducts.length === 0 && <h5>Geen controllers gevonden</h5>}
        </PlattixCardContainer>


    </Page>
    )
}