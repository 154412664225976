export const SPSlug = '/SP';
export const ECSlug = '/EC';

export const SpSlugWithId = SPSlug + "/:spId";

export const MarketplaceRouteMap = {
    base: () => ``,
    ControllerFinder: () => `${MarketplaceRouteMap.base()}/Controllers`,
    Overview: () => `${MarketplaceRouteMap.base()}/`,
    Product: () => `${MarketplaceRouteMap.base()}/Product/:productId`,
    Categories: () => `${MarketplaceRouteMap.base()}/Categories`,
    Cart: () => `${MarketplaceRouteMap.base()}/Cart`,
    Checkout: () => `${MarketplaceRouteMap.base()}/Checkout`,
    BackToShop: () => MarketplaceRouteMap.base(),
    Orders: () => `${MarketplaceRouteMap.base()}/Order`,
    OrderDetail: () => `${MarketplaceRouteMap.Orders()}/:orderId`,
}