
import {css} from "goober";

export const fieldset = css`
    width: 100%;
    border: none;
    padding: 0;
    margin: 0 0 30px 0;
    border-radius: 0;
    
    legend {
        padding: var(--padding5);
        padding-bottom: 10px;
        margin: 0 0 10px 0;
        width: 100%;
        max-width: unset;
        color: var(--styleColor4);
        font-weight: var(--fontWeight3);
        font-size: 1.3em;
        border-bottom: var(--borderBottom1);
    }
`;
