import {MarketplaceHeaderProps, useActiveCart} from "PlattixSalesUI/Sales/MarketplaceUtil";
import {PageHeader} from "PlattixUI/core/Header";
import {Actions, ActionsLeft, ActionsRight} from "PlattixUI/core/components/ActionBar";
import {ConfirmButton, EditButton} from "PlattixUI/core/components/Buttons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBagShopping, faCartShopping, faCashRegister} from "@fortawesome/free-solid-svg-icons";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import React, {Fragment, PropsWithChildren, useEffect} from "react";
import {generatePath} from "react-router-dom";
import {MarketplaceRouteMap} from "PlattixSalesUI/configuration/RouteMap";
import {MarketplaceHeaderChildrenContainer} from "PlattixSalesUI/Sales/MarketplaceStyling";

export function MarketplaceHeader(props: PropsWithChildren<MarketplaceHeaderProps>) {
    const cartQuery = useActiveCart()

    return (
        <>
            {/*<MarketplaceHeaderContainer>*/}
            <PageHeader 
                title={props.title} 
                subheader={props.subheader} 
                description={props.description} 
                breadcrumbs={props.breadcrumbs}
                backButton={props.backButton}
            />

            {!!props.children &&
                <MarketplaceHeaderChildrenContainer>
                    {props.children}
                </MarketplaceHeaderChildrenContainer>
            }

            {!props.hideActions &&
                <>
                    {/*<ResponsiveComponent devices={['desktop']}>*/}
                        <Actions>
                            {!!props.actions?.left?.length &&
                                <ActionsLeft>
                                    {/* TODO: Zoekfunctie toevoegen */}
                                    {/*{!props.hideElements?.search &&*/}
                                    {/*    <SearchInput/>*/}
                                    {/*}*/}

                                    {props.actions?.left?.map((action, index) => <Fragment key={`action_left_${index}`}>{action}</Fragment>)}
                                </ActionsLeft>
                            }

                            <ActionsRight>
                                {props.includeBtns?.continueShopping &&
                                    <EditButton link={generatePath(MarketplaceRouteMap.BackToShop())}>
                                        <FontAwesomeIcon icon={faBagShopping}/>
                                        {t("ContinueShopping")}
                                    </EditButton>
                                }
                                {props.includeBtns?.cart &&
                                    <ConfirmButton 
                                        link={generatePath(MarketplaceRouteMap.Cart())} 
                                        notification={cartQuery.data?.selectedItems?.reduce((a,b) => a + b.amount, 0)}
                                    >
                                        <FontAwesomeIcon icon={faCartShopping}/>
                                        {t("Cart")}
                                    </ConfirmButton>
                                }
                                {/*{props.includeBtns?.continuePayment &&*/}
                                {/*    <ConfirmButton onClick={continuePaymentHandler}>*/}
                                {/*        <FontAwesomeIcon icon={faCashRegister}/>*/}
                                {/*        {t("ContinuePayment")}*/}
                                {/*    </ConfirmButton>*/}
                                {/*}*/}
                                {(props.includeBtns?.checkout && !!cartQuery.data?.selectedItems.length) &&
                                    <ConfirmButton link={generatePath(MarketplaceRouteMap.Checkout())}>
                                        <FontAwesomeIcon icon={faCashRegister}/>
                                        {t("Checkout")}
                                    </ConfirmButton>
                                }

                                {props.actions?.right?.map((action, index) => <Fragment key={`action_left_${index}`}>{action}</Fragment>)}
                            </ActionsRight>
                        </Actions>
                    {/*</ResponsiveComponent>*/}
                </>
            }
            
        {/*</MarketplaceHeaderContainer>*/}
        </>
    );
}