import React, {Fragment, PropsWithChildren, useEffect, useState} from "react";
import {loginUser, userLoggedInSelector, userSelector} from "../../../PlattixReactCore/UserSlice";
import {useAppDispatch, useAppSelector} from "../../../PlattixReactCore/hooks";
import {LoadingScreen} from "../../components/Loader";
import {ContentLoginComponent} from "../../ContentLogin";
import {SidebarLoginComponent} from "../../SidebarLogin";
import {Route, Switch} from "react-router-dom";
import {Logout} from "./Logout";
import {deviceDimensions, ResponsiveComponent} from "../../components/Responsive";
import i18n from "../../../PlattixReactCore/i18n";
import {useMediaQuery} from "@mui/material";
import {CustomPlattixNavigationItemProps, PlattixNavigationItemProps} from "PlattixUI/core/components/MenuComponents";
import {NavigationComponent} from "PlattixUI/core/Navigation";

export type HideAnonymousRouteElements = 'sidebar';

export type AnonymousRoute = {
    url: string;
    component: React.ReactElement;
    menuItems?: CustomPlattixNavigationItemProps[];
    hide?: Partial<Record<HideAnonymousRouteElements, boolean>>
}

export type AnonymousRoutes = AnonymousRoute[]

export type OverwriteRoute = {
    url: string,
    component: React.ReactElement
}
export type OverwriteRoutes = OverwriteRoute[]

type Props = {
    anonymousRoutes?: AnonymousRoutes,
    overwriteRoutes?: OverwriteRoutes,
    noBackground?: boolean | undefined,
    noSidebarComponents?: boolean | undefined,
    showLoading?: boolean
}


/**
 * Router that will check if user is logged in.
 * If not, it will redirect to the login page
 *
 * */
export function CheckLoginComponent(props: React.PropsWithChildren<Props>) {
    const dispatch = useAppDispatch()

    const userState = useAppSelector(userSelector);
    const loggedIn = useAppSelector(userLoggedInSelector);
    const status = userState.userStatus;
    
    const [transLoaded, setTransLoaded] = useState(false);

    i18n.on('initialized', function (lng: string) {
        setTransLoaded(true);
    })

    useEffect(() => {
        if (!status.isInitialised && !status.isLoading) {
            dispatch(loginUser(null))
        }
    });

    if (loggedIn) return <>
        <Switch>
            <Route exact path="/logout" component={Logout}/>
            <Route path="/">
                {props.children}
            </Route>
        </Switch>

    </>

    if (!status.isInitialised || props.showLoading) return <LoadingScreen/>

    return <>
        <Switch>
            {props.anonymousRoutes && props.anonymousRoutes.map(r => (
                <Route key={r.url} path={r.url}>
                    {/*<AnonymousComponentWrapper menuItems={r.menuItems}>*/}
                    <AnonymousComponentWrapper {...r} >
                        {r.component}
                    </AnonymousComponentWrapper>
                </Route>)
            )}

            <>
                <ContentLoginComponent 
                    userState={userState} 
                    noBackground={props.noBackground}
                    anonymousRoutes={props.anonymousRoutes} 
                    overwriteRoutes={props.overwriteRoutes}
                />
                {!props.noSidebarComponents &&
                    <ResponsiveComponent devices={['desktop']}>
                        <SidebarLoginComponent/>
                    </ResponsiveComponent>
                }
            </>

        </Switch>
    </>

}


const AnonymousComponentWrapper = (props: PropsWithChildren<AnonymousRoute>) => {
    const desktop = useMediaQuery(deviceDimensions.desktop);
    
    return (
        <>
            {!props.hide?.sidebar &&
                <SidebarLoginComponent showBackground={desktop} anonymous={true} />
            }
            <ResponsiveComponent devices={['desktop']}>
                {!!props.menuItems &&
                    <NavigationComponent menuItems={props.menuItems} />
                }
            </ResponsiveComponent>
            <section className="content">
                <section className="body">
                    {props.children}
                </section>
            </section>
        </>
    )
}