import React, {Suspense, useEffect, useState} from 'react';
import {SidebarComponent} from 'PlattixUI/core/Sidebar';
import {NavigationComponent} from 'PlattixUI/core/Navigation';
import {ContentComponent} from 'PlattixUI/core/Content';

import 'PlattixUI/scss/Index.scss';
import {setup} from "goober";
import {CheckLoginComponent} from "PlattixUI/core/pages/Account/CheckLoginComponent";
import {userSelector} from "PlattixUI/PlattixReactCore/UserSlice";
import {useAppSelector} from "PlattixUI/PlattixReactCore/hooks";

import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'

import 'moment/locale/nl-be'
import 'moment/locale/fr'
import 'moment/locale/en-in'
import 'moment/locale/de'
import 'react-toastify/dist/ReactToastify.css';
import {ResponsiveComponent} from "PlattixUI/core/components/Responsive";
import {QueryClient, QueryClientProvider, useQueryClient} from '@tanstack/react-query';
import {setLicenseKeys} from 'PlattixUI/core/License';
import {prefix} from 'goober/prefixer';
import * as moment from "moment";
import {PlattixToastContainer} from "PlattixUI/core/components/ToastContent";
import {ResponsiveChecker} from "PlattixUI/core/components/ResponsiveChecker";
import {useNumberSearchParams} from "PlattixUI/util/useIdParam";
import {ActiveCartQueryKey, useActiveCart} from "PlattixSalesUI/Sales/MarketplaceUtil";
import {doPost, isHttpError} from "PlattixUI/PlattixReactCore/api/Api";
import {history} from "PlattixUI/PlattixReactCore/store";
import {Endpoints} from "configuration/ApiEnpointMap";
import {anonymousRoutes} from "pages/Routers/AnonymousRoutes";

// @ts-ignore
library.add(fas)

setup(React.createElement);
setup(React.createElement, prefix);

const queryClient = new QueryClient()

function App() {

    const {user, userStatus} = useAppSelector(userSelector)
    
    // license keys componenten ophalen
    setLicenseKeys();

    useEffect(() => {
        if (user) {
            moment.locale(user.locale)
        }
    }, [user])
     
    return (
        <QueryClientProvider client={queryClient}>
            <Suspense fallback="loading">
                <div id="root" className={userStatus.isLoggedIn ? "" : "login-body"}>
                    <WebshopQueryRedirect />
                </div>
            </Suspense>
        </QueryClientProvider>
    );
}

function WebshopQueryRedirect(){
    const cart = useActiveCart()
    const [loading, setLoading] = useState(false);
    
    const qcl = useQueryClient()

    const params = useNumberSearchParams<{accountId: number, spId: number}>();

    useEffect(() => {
        if (params.spId || params.accountId){
            setLoading(true)

            if (cart.data){
                if (cart.data.servicepointId !== params.spId) {
                    doPost(Endpoints.Marketplace.ChangeServicepoint(), {newServicepointId: params.spId})
                        .then((model) => {
                            if (!isHttpError(model)) qcl.setQueryData([ActiveCartQueryKey], model);
                            history.replace(window.location.pathname)
                            setLoading(false);
                        })
                    return;
                }
                if (cart.data.customerAccountId !== params.accountId) {
                    doPost(Endpoints.Marketplace.ChangeCustomerAccount(), {NewCustomerAccountId: params.spId})
                        .then((model) => {
                            if (!isHttpError(model)) qcl.setQueryData([ActiveCartQueryKey], model);
                            history.replace(window.location.pathname)
                            setLoading(false);
                        })
                    return;
                }
            }

            setLoading(false)
        }

    }, [cart.data, params]);
    
    
    return <>

        <CheckLoginComponent 
            showLoading={loading} 
            anonymousRoutes={anonymousRoutes}
        >
            <ContentComponent/>

            <ResponsiveComponent devices={['desktop']}>
                <NavigationComponent/>
                <SidebarComponent/>
            </ResponsiveComponent>
        </CheckLoginComponent>
        <PlattixToastContainer />
        <ResponsiveChecker show={false} />
    </>
}


export default App;
