import React, {useState} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {MarketplaceRouteMap} from 'configuration/RouteMap';
import {MarketplaceCategoryOverview} from 'PlattixSalesUI/Sales/Marketplace/Pages/MCategoryOverview';
import {MarketplaceCart} from 'PlattixSalesUI/Sales/Marketplace/Pages/MCart';
import {MarketplaceOverview} from "PlattixSalesUI/Sales/Marketplace/Pages/MOverview";
import {MarketplaceProduct} from "PlattixSalesUI/Sales/Marketplace/Pages/MProduct";
import {MarketplaceCheckout} from "PlattixSalesUI/Sales/Marketplace/Pages/MCheckout";
import {MarketplaceOrders} from "PlattixSalesUI/Sales/Marketplace/Pages/MOrders";
import {MarketplaceOrderDetails} from "PlattixSalesUI/Sales/Marketplace/Pages/MOrderDetails";
import {Error404} from "PlattixUI/core/ErrorPages/ErrorPages";
import {ControllerFinder} from "pages/Controllers/ControllerFinder";


export const rootUrl = ''

export function RoutersComponent() {
    const isSubmittingState = useState<boolean>(false);
    
    const enableCategories = false;

    return (
        <>
            <section className="body">
                <Switch>
                    
                    {/*<Redirect exact strict from="/" to={SPSlug}/>*/}

                    <Route path={`${MarketplaceRouteMap.ControllerFinder()}`} component={ControllerFinder} />

                    {enableCategories &&
                        <Route path={`${MarketplaceRouteMap.Categories()}`} component={MarketplaceCategoryOverview} />
                    }
                    <Route path={`${MarketplaceRouteMap.Product()}`} component={MarketplaceProduct} />
                    <Route path={`${MarketplaceRouteMap.Cart()}`}>
                        <MarketplaceCart
                            page={true}
                            editable={true}
                        />
                    </Route>
                    <Route path={`${MarketplaceRouteMap.Checkout()}`}>
                        <MarketplaceCheckout
                            page={true}
                            isSubmittingState={isSubmittingState}
                        />
                    </Route>
                    <Route path={`${MarketplaceRouteMap.OrderDetail()}`} component={MarketplaceOrderDetails} />
                    <Route path={`${MarketplaceRouteMap.Orders()}`} component={MarketplaceOrders} />
                    <Route exact path={`${MarketplaceRouteMap.Overview()}`} component={MarketplaceOverview} />

                    {/*Error pages*/}
                    <Route path="/404" component={Error404} />
                    <Redirect to={MarketplaceRouteMap.base()}/>
                    {/*<Redirect to={'/404'}/>*/}
                    
                </Switch>
                {/*</Router>*/}
            </section>
        </>
    );
}

