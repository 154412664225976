
export type PermissionedType = "Servicepoint" | "Account" | 'User';

export const Endpoints = {
    Account: {
        Login: "/Account/Login",
        ReLogin: "/Account/ReLogin",
        Logout: "/Account/Logout",
        Register: "/Account/Register",
        Forgot2FA: "/Account/Forgot2FA",
        ResetPassword: "/Account/ResetPassword",
        ResendVerification: "/Account/ResendVerification",
        ForgotPassword: "/Account/ForgotPassword",
        UpdateLanguage: "/Account/UpdateLanguage"
    },
    Feedback: "/Feedback/SendFeedbackMail",
    Marketplace: {
        base: () => `/shop`,
        Products: () => Endpoints.Marketplace.base() + "/product",
        Product: () => Endpoints.Marketplace.base() + "/product/:productId",
        GetActiveCart: () => Endpoints.Marketplace.base() + "/cart",
        UpdateProductAmount: () => Endpoints.Marketplace.GetActiveCart() + "/updateAmount",
        AddToCart: () => Endpoints.Marketplace.GetActiveCart() + "/add",
        SwitchShoppingCart: () => Endpoints.Marketplace.base() + "/cart",
        Checkout: (shoppingCartId: number|null|undefined) => Endpoints.Marketplace.base() + "/checkout/" + shoppingCartId,
        Categories: () => Endpoints.Marketplace.base() + "/category",
        
        ChangeShareCode: () => Endpoints.Marketplace.base() + "/cart/Change/ShareCode",
        ChangeServicepoint: () => Endpoints.Marketplace.base() + "/cart/Change/Servicepoint",
        ChangeCustomerAccount: () => Endpoints.Marketplace.base() + "/cart/Change/Customer",
        AlternativeParams: () => Endpoints.Marketplace.base() + "/cart/Change/alternatives",
        
        Order: () => "/Sales/Order",
        OrderDetails: () => Endpoints.Marketplace.Order() + "/:orderId",
    },
}