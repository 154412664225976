import {useTranslation} from "../../../PlattixReactCore/i18n";
import React, {useState} from "react";
import {isHttpError} from "../../../PlattixReactCore/api/Api";
import {Button} from "../../components/Buttons";
import {doResendVerification} from "../../../PlattixReactCore/api/AccountApi";
import {HttpError} from "../../../PlattixReactCore/CoreTypes";
import {ContentCardButtons} from "../../components/ContentCard";
import {LoginOptions} from "PlattixUI/core/pages/Account/LoginOptions";


interface ResendVerificationMailProps {
    email: string
}

export function ResendVerificationMail(props: ResendVerificationMailProps) {    
    const {t} = useTranslation();
    
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState<HttpError|null>(null);
    const [success, setSuccess] = useState(false);
    
    async function resendInvitation(){
        setLoading(true);
        
        const response = await doResendVerification({email: props.email});
        
        if (isHttpError(response)){
            setError(response)
        } else {
            setSuccess(true);
        }
        
        setLoading(false);
    }
    
    if (success) return <p>{t('RegistrationSuccessful.ResendMail.Confirmation')}</p>;
    
    return <>
        <div>
            <h5>{t('RegistrationSuccessful.AccountNotVerified.Title')}</h5>
            <p>{t('RegistrationSuccessful.ResendMail.Msg')}</p>
            <hr />
        </div>
        {/*<p>{t('RegistrationSuccessful.ResendMail.Title')}</p>*/}
        <ContentCardButtons>
            <Button loading={isLoading} onClick={resendInvitation}>{t('RegistrationSuccessful.ResendMail.Btn')}</Button>
        </ContentCardButtons>
        {error && 
            <p className="login-error">{error.detail ?? error.title}</p>
        }
    </>
}