import loginBackground from 'images/branding/energytix/background/login.png';
import vlaioLogo from 'images/branding/external/vlaio/logo/logo.png';
import {IPlattixConfiguration} from "PlattixUI/PlattixReactCore/types/PlattixConfiguration";
import {isAdminSelector} from "PlattixUI/PlattixReactCore/UserSlice";

export const ApiRootUrl = (process.env.NODE_ENV === 'production' ? '' : 'http://localhost:57022') + '/api';

export const PlattixConfiguration: IPlattixConfiguration = {
    platformName: 'Energytix',
    clientName: 'Energytix',
    
    // applicationAccess: ['Energytix', 'Tactix', 'Plattix'],

    hideInMenu: {
        'Tactix': isAdminSelector,
    },

    appIcon: 'a36e42a61a594e1aadf82d83ae2a8189/Tekengebied 10.png',
    mainLogo: '28f0c824bd114fb6bd92d90c5a6d8ffd/Tekengebied 3.png',
    
    loginScreen: {
        background: loginBackground,
        sponsors: [{
            logo: vlaioLogo,
            name: "Vlaio"
        }]
    }
}