import HtmlToReactParser from 'html-to-react'


export default function htmlRaw(htmlInput: string) {
    if (!htmlInput) return '';
    
    // check if input contains a html tag
    if (htmlInput.includes('</') || htmlInput.includes('/>')){
        const htmlToReactParser = new HtmlToReactParser.Parser();
        return htmlToReactParser.parse(htmlInput, {}, {});
    }
    return htmlInput;
}