import {css, styled} from "goober";
import {forwardRef} from "react";
import {dataGridStyling} from "PlattixUI/core/grid/gridStyling/GridStyling";

export const SettingsRowInputsGap = '20px';
export const SettingsRowInputsInputMinWidth = '25%';

export const SettingsRow = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        gap: 20px;
        padding-bottom: 50px;
    `;
});

export const SettingsRowHeader = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 10px;
        
        h5 {
            margin-right: auto;
        }
    `;
});

export interface SettingsRowInputsProps {
    // hidden?: boolean;
}

export const SettingsRowInputs = styled('div')((props: SettingsRowInputsProps) => {
    // return `
    //     width: 100%;
    //     display: flex;
    //     flex-flow: row wrap;
    //     gap: ${SettingsRowInputsGap};
    // `;
    
    return `
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: ${SettingsRowInputsGap};
    `;
});

export interface SettingsRowInputProps {
    hidden?: boolean;
    columnWidth?: string;
}

export const SettingsRowInput = styled<SettingsRowInputProps>('div')(props => ({
    width: '100%',
    display: !!props.hidden ? 'none' : 'block',
    columnWidth: props.columnWidth ?? '1'
}))

export const SalesConfigurationProductListGridStyling = {
    ...dataGridStyling,
    '.MuiDataGrid-columnHeaders': {
        'minHeight': '10px !important',
        'borderBottom': '2px solid #333 !important',
        '.MuiDataGrid-columnHeader': {
            '.MuiDataGrid-columnHeaderTitle': {
                'fontWeight': '700 !important',
            },
        },
    },
    '.MuiDataGrid-main': {
        'borderBottom': '2px solid #333 !important',
    },
    '.MuiDataGrid-detailPanel': {
        'overflow': 'auto',
    },
}

export const SalesConfigurationProductListGridContainer = styled('div')(() => {
    return `
        width: 100%;
    `;
});

export const SalesConfigurationProductListTotalsContainer = styled('div')(() => {
    return `
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        padding-top: 15px;
    `;
});

export const SalesConfigurationProductListTotalsButtons = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        gap: 10px;
        justify-content: flex-start;
    `;
});

export const SalesConfigurationProductListTotals = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        margin-left: auto;
        grid-column-end: -1;
        gap: 5px;
    `;
});

export const SalesConfigurationProductListTotalsRow = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        gap: 10px;
        justify-content: space-between;
    `;
});

export const SalesConfigurationDraggablesContainer = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        gap: 25px;
    `;
});

export const SalesConfigurationDraggablesFooter = styled('div')(() => {
    return `
        width: 100%;
    `;
});

export const SalesConfigurationDraggableItemContainer = styled('div', forwardRef)(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        gap: 10px;
        border: 5px solid #EEE;
        border-radius: 20px;
        padding: 10px;
        background: #FFF;
    `;
});

export const SalesConfigurationDraggableItemContent = styled('div')(() => {
    return `
        width: 100%;
    `;
});

export const SalesConfigurationDraggableItemGrip = styled('div')(() => {
    return `
        width: fit-content;
        cursor: ns-resize;
        border-right: var(--borderBottom1);
        padding-right: 10px;
        
        svg {
            font-size: 15px;
            color: #AAA;
        }
    `;
});

export const SalesConfigurationContainer = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        gap: 25px;
    `;
});

export const SalesConfigurationGeneralOverview= styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        gap: 10px;
        align-items: flex-start;
        margin-bottom: 15px;
        
        dl {
            &, dt, dd {
                margin: 0;
                padding: 0;
            }
            
            dt {
                font-weight: 700;
            }
            
            dd {
                
            }
        }
    `;
});

export const SalesConfigurationGeneralOverviewStatus = styled('div')(() => {
    return `
        width: fit-content;
        background: var(--buttonColorGreen);
        border-radius: 10px;
        padding: var(--padding1);
        
        &, * {
            color: #fff;
        }
    `;
});

export const SalesConfigurationGeneralOverviewInfo = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        gap: 10px;
        align-items: flex-start;
        justify-content: space-between;
        
        dl {
            display: flex;
            flex-flow: column nowrap !important;
            gap: 5px !important;
            background: #f5f5f5;
            border-radius: 10px;
            padding: 5px 10px;
            
            dd {
                text-align: left !important;
                display: flex;
                flex-flow: column nowrap;
                gap: 3px;
                margin: 0 !important;
            }
        }
        
        & > svg {
            position: relative;
            top: 50%;
        }
        
        button {
            margin-left: auto;
        }
    `;
});

export const ShowInputModalAccordion = css`
    width: 100%;
    box-shadow: none !important;
    
    svg {
        color: #333 !important;
    }
    
    .MuiAccordionSummary-root {
        border-radius: 10px !important;
        padding: var(--padding1) !important;
        background: var(--backgroundColor2) !important;
        
        &, .MuiAccordionSummary-content {
            margin: 0 !important;
            min-height: unset !important;
            height: fit-content !important;
        }
        
        .MuiAccordionSummary-content {
            padding: 0 !important;
        }
    }
        
    .MuiAccordionDetails-root {
        padding: 0 !important;
        margin-top: 10px;
    }
`;

export const ShowInputModalButton = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
        border-radius: 10px;
        padding: var(--padding1);
        
        &:hover {
            background: var(--backgroundColor2);
        }
    `;
});

export const ShowInputModalButtonIcon = styled('div')(() => {
    return `
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
    `;
});

export const SalesConfigurationProductFormContainer = styled('div')(() => {
    return `
        width: 100%;
        padding: 15px;
        margin-top: 15px;
        border: 5px solid var(--styleColor1);
        border-radius: 10px;
        
        .MuiCollapse-root {
            border: none !important;
        }
    `;
});

export const SalesConfigurationProductFormCombinedInputContainer = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        gap: 10px;
    `;
});

export interface SalesConfigurationProductFormCombinedInputProps {
    width?: string;
}

export const SalesConfigurationProductFormCombinedInput = styled('div')((props: SalesConfigurationProductFormCombinedInputProps) => {
    return `
        width: ${props.width ?? '100%'};
        display: flex;
        flex-flow: row nowrap;
        gap: 10px;
        align-items: center;
        
        .MuiCollapse-root {
            width: 100%;
        }
    `;
});

