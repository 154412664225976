import {PageHeaderProps} from "PlattixUI/core/Header";
import {usePlattixQuery} from "PlattixUI/PlattixReactCore/api/Api";
import {generatePath} from "react-router-dom";
import {Endpoints} from "configuration/ApiEnpointMap";
import {AddressModel} from "PlattixUI/core/components/AddressComponent";
import React from "react";

export const ActiveCartQueryKey = 'activeCart';
export const CheckoutOrderQueryKey = 'checkoutOrder';
export const OrderDetailsQueryKey = 'OrderDetails';
export const EnergytixOrderDetailsQueryKey = 'EnergytixOrderDetails';

export function useActiveCart() {
    return usePlattixQuery<ShoppingCartViewModel>(
        [ActiveCartQueryKey],
        generatePath(Endpoints.Marketplace.GetActiveCart())
    )
}

// Test

export type MarketplaceHeaderIncludeBtnsType = {
    search?: boolean,
    // sharecode?: boolean,
    cart?: boolean,
    checkout?: boolean,
    continueShopping?: boolean,
    // continuePayment?: boolean,
}

export interface MarketplaceHeaderType {
    left?: React.ReactNode[],
    right?: React.ReactNode[],
}

export interface MarketplaceHeaderProps extends PageHeaderProps {
    includeBtns?: MarketplaceHeaderIncludeBtnsType,
    hideActions?: boolean,
    actions?: MarketplaceHeaderType,
}

export interface CategoryType {
    img?: string,
    title: string,
    description: string,
    link?: string,
    onClick?: () => void,
    subCategories?: CategoryType[],
}

export type MarketplaceCategoryLinkType = {
    title: string,
    link?: string,
    onClick?: () => void,
};

export type MarketplaceCategoryListProps = {
    subCategories: MarketplaceCategoryLinkType[],
};

export type MarketplaceProductParams = {
    productId: string,
};

export type ImageGalleryImageType = {
    src: string,
    alt: string,
}

export interface ImageGalleryProps {
    images: ImageGalleryImageType[],
}

export type ProductVariablesType = {
    title: string,
    onClick: () => void,
    disabled?: boolean,
}

export interface ProductVariableProps {
    title: string,
    variables: ProductVariablesType[],
}

export const productVariableVariableSample = {
    title: 'Variable',
    onClick: () => console.log('Variable'),
    disabled: false,
};

export const productVariableSample = {
    title: 'Variable 1',
    variables: Array(Math.floor(Math.random() * 5) + 1).fill(productVariableVariableSample),
};

export const productVariables = [
    {...productVariableSample},
    {
        ...productVariableSample,
        title: 'Variable 2'
    },
];


export interface MarketplaceCartProps {
    page: boolean,
    editable: boolean,
    // cart: MarketplaceCartType,
}

export interface MarketplaceCheckoutProps {
    page: boolean,
    hidePayBtn?: boolean,
    isSubmittingState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    // cart: MarketplaceCartType,
}

export interface MarketplaceTotalsProps {
    
}

export interface MarketplaceTotalsProductListProps {
    products: ShoppingCartItem[];
    editable: boolean;
    smallLayout?: boolean;
}

export interface MarketplaceTotalsProductListItemProps {
    product: ShoppingCartItem;
    editable: boolean;
    smallLayout?: boolean;
}

export interface ProductListViewModel {
    totalCount: number;
    filteredCount: number;
    products: ShopProductViewModel[];
    filter: ProductFilterModel;
}

export enum  OrderStatusCode {
    Created = 'CREATED',
    Paid = 'PAID',
    PaymentFailed = 'PAYMENTFAILED'
}

export interface ShopProductViewModel {
    pricestructureDetailId: number;
    pricestructureId: number;
    productId: number;
    productCompositionTypeCode: string;
    skuSalesPriceCalculationMethodCode: string;
    showSequenceNumberInMarketplace: number | null;
    staffoldPrices: StaffoldPrice[];
    productCode: string;
    productDescription: string;
    productDescription2: string;
    salesMultiple: number;
    quantity: number;
    minQuantity: number;
    skuCodeDescription: string;
    filePath: string;
    childProducts?: ShopProductViewModel[];
    vat: VatModel,
    tags: string[];
}

export interface VatModel {
    code: string,
    description: string,
    percentage: number
}

export interface ProductFilterModel {
    category: string;
    sort: FilterSort;
    dir: FilterDirection;
    page: number;
    size: number;
}

export enum FilterDirection {
    Asc,
    Desc
}

export enum FilterSort {
    Price,
    Availability,
    Popularity,
    Rating
}

export interface StaffoldPrice {
    id: number;
    quantity: number;
    salesPrice: number;
}

export interface ShoppingCartViewModel {
    selectedItems: ShoppingCartItem[];
    shoppingCartId: number | null;
    customerAccountId: number | null;
    customerAccountName: string;
    requiresServicepoint: boolean;
    servicepointId: number | null;
    servicepointName: string;
    pricestructureId: number;
    pricestructureShareCode: string;
    ownerAccountId: number;
    ownerAccountName: string;
    otherOpenCarts: ShoppingCartViewModel[];
    totalProductsIncl: number;
    totalProductsExcl: number;
    totalVat: number;
    total: number;
    transportCost: number;
}

export interface ShoppingCartItem {
    productId: number;
    filePath: string;
    description1: string;
    description2: string;
    requiresServicepoint: boolean;
    amount: number;
    salesMultiple: number;
    minQuantity: number;
    sku: string;
    skuSingleSalesPriceExcl: number;
    skuSingleSalesPriceIncl: number;
    skuTotalSalesPriceExcl: number;
    skuTotalSalesPriceIncl: number;
    vatPercent: number;
}

// TODO: Type aanvullen als er gecommuniceerd kan worden met de backend
// export type MarketplaceCheckoutModel = any;
export interface MarketplaceCheckoutModel {
    // shoppingCartViewModel: ShoppingCartViewModel;
    shoppingCartId: number,
    // requestViewModel: AccountViewModel;
    // customerAccountId: number;
    // ownerAccount: Account;
    // servicepoint: Servicepoint;
    // pricestructureShareCode: string;
    reference1: string;
    reference2: string;
    mailingAddress: AddressModel,
    invoiceAddressSame: boolean,
    invoiceAddress: AddressModel,
    deliveryAddressSame: boolean,
    deliveryAddress: AddressModel,
    
}

export interface MarketplaceCustomerInfoProps {
    data?: MarketplaceCheckoutModel; //TODO: Vervangen door hetgeen dat uit de checkoutQuery komt
}

export interface MarketplaceAddressProps {
    invoiceAddress: AddressModel,
    deliveryAddress: AddressModel,
}

export const MarketplaceAddressTypeTranslationMap = {
    account: 'MarketplaceAddressType.Account.Title',
    delivery: 'MarketplaceAddressType.Shipping.Title',
    invoice: 'MarketplaceAddressType.Invoice.Title',
    servicepoint: 'MarketplaceAddressType.servicepoint.Title',
};

export interface MarketplaceProductOverviewProps extends MarketplaceTotalsProductListProps {
    totalProductsExcl: number | string;
    totalVat: number | string;
    totalProductsIncl: number | string;
    transportCost: number | string;
    total: number | string;
    smallLayout?: boolean;
}

export interface ChangeShoppingCartModel {
    newServicepointId: number | null;
    newCustomerAccountId: number | null;
    newShareCode: string;
    force: boolean;
}

export type ChangeShoppingCartFieldsType = 'sharecode' | 'servicepoint' | 'customer';
