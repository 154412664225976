import {generatePath, useParams} from "react-router-dom";
import {ActiveCartQueryKey, MarketplaceProductParams, ShoppingCartViewModel} from "PlattixSalesUI/Sales/MarketplaceUtil";
import {MarketplaceHeader} from "PlattixSalesUI/Sales/Marketplace/Components/MHeader";
import React, {useEffect, useState} from "react";
import {usePlattixMutation, usePlattixQuery} from "PlattixUI/PlattixReactCore/api/Api";
import {Endpoints} from "configuration/ApiEnpointMap";
import {PlattixCardContainer, PlattixEmptyCard, PlattixList} from "PlattixUI/core/components/ContentCard";
import {
    NumberInputStyling,
    ProductDetailOrder,
    ProductDetailPrice,
    ProductPageContainer,
    ProductPageDetails,
} from "PlattixSalesUI/Sales/MarketplaceStyling";
import {ImageGallery} from "PlattixSalesUI/Sales/Marketplace/Components/MImageGallery";
import {ConfirmButton} from "PlattixUI/core/components/Buttons";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import NumberInput from 'semantic-ui-react-numberinput';
import {useQueryClient} from "@tanstack/react-query";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartShopping, faPlus} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";
import {formatEuro} from "PlattixUI/util/numberUtil";

export function MarketplaceProduct() {
    const {productId} = useParams<MarketplaceProductParams>();
    const defaultQty = 1;
    const [productQty, setProductQty] = useState<number>(defaultQty);

    const productQuery = usePlattixQuery<any>(
        ['productQuery', parseInt(productId)],
        generatePath(Endpoints.Marketplace.Product(), {
            productId: parseInt(productId),
        })
    )
        
    const product = productQuery.data;

    const addToCartMutation = usePlattixMutation<ShoppingCartViewModel, {productId: number, amount: number}>(
        [ActiveCartQueryKey],
        Endpoints.Marketplace.AddToCart()
    );
    
    useEffect(() => {
        if (!productQuery.data) return;
        let qty = productQuery.data.minQuantity <= 0 ? 1 : productQuery.data.minQuantity;
        setProductQty(qty.toString())
        
        // Fix voor ontbrekende icons in NumberInput
        if (!document.querySelector('#amountSelector .button .icon.minus .fa-minus')) {
            const minusIcon = document.createElement("i");
            minusIcon.className = "fa fa-minus";
            document.querySelector('#amountSelector .button .icon.minus')?.appendChild(minusIcon);
        }

        // Fix voor ontbrekende icons in NumberInput
        if (!document.querySelector('#amountSelector .button .icon.plus .fa-plus')) {
            const plusIcon = document.createElement("i");
            plusIcon.className = "fa fa-plus";
            document.querySelector('#amountSelector .button .icon.plus')?.appendChild(plusIcon);
        }
        
    }, [productQuery.data]);
    
    // region DummyData

    // const categories = [
    //     {
    //         title: 'Category 1',
    //         url: '/'
    //     },
    //     {
    //         title: 'Category 1b',
    //         url: '/'
    //     },
    // ];

    const categories = [];

    // const productDetails = [
    //     {
    //         title: 'Key',
    //         description: 'Value'
    //     },
    //     {
    //         title: 'Key',
    //         description: 'Value'
    //     },
    //     {
    //         title: 'Key',
    //         description: 'Value'
    //     },
    //     {
    //         title: 'Key',
    //         description: 'Value'
    //     },
    // ];

    const productDetails = [];
    
    // endregion


    const addToBasketHandler = async (productId: number, productName: string) => {
        try {
            await addToCartMutation.mutate({productId: productId, amount: productQty});
        } catch (e) {
            toast(t('Marketplace.Overview.Product.AddedToCard.Failed', {product: productName}), {type: "success"})
        } finally {
            toast(t('Marketplace.Overview.Product.AddedToCard', {product: productName}), {type: "success"})
        }
    }
    
    const productQtyHandler = (qty) => {
        setProductQty(qty)
    };
    
    if (!product) return <></>;

    const getStaffoldPrice = () => {        
        const currentPrice = product.staffoldPrices?.reverse().find(sp => productQty >= sp.quantity)?.salesPrice ?? 0;
        return currentPrice;
    }

    const productImages = [
        {
            src: product.filePath,
            alt: `Product image of ${product.productDescription}`
        }
    ]; // TODO: Moet een array van foto's worden die van de backend wordt verkregen

    const tempFullPrice = ((getStaffoldPrice() * 0.21) + getStaffoldPrice()).toFixed(2); // TODO: Opvragen van backend
    
    return (
        <>
            <MarketplaceHeader
                title={product?.productDescription}
                breadcrumbs={categories}
                backButton
                includeBtns={{
                    checkout: true, 
                    cart: true
                }}
            />
            
            <PlattixCardContainer>
            
                <PlattixEmptyCard>
                    <ProductPageContainer>
                        <ImageGallery images={productImages}/>
                    </ProductPageContainer>
                </PlattixEmptyCard>
            
                <ProductPageDetails className={'module-container'}>
                    <ProductDetailPrice>
                        <h5>{formatEuro(parseInt(tempFullPrice))} / {t(product.skuCodeDescription)}</h5>
                        <p>{formatEuro(getStaffoldPrice())} {t('Marketplace.Home.Products.ExcludingVat', {amount: 21})}</p>
                        {/*<p>Verkocht door Energytix</p>*/}
                    </ProductDetailPrice>
            
                    <ProductDetailOrder>
                        <NumberInput 
                            value={productQty}
                            minValue={product?.minQuantity >= 0 ? defaultQty : product?.minQuantity}
                            stepAmount={product?.salesMultiple >= 0 ? 1 : product?.salesMultiple}
                            onChange={productQtyHandler}
                            buttonPlacement="leftAndRight"
                            className={NumberInputStyling}
                            id={'amountSelector'}
                        />
                        
                        <ConfirmButton onClick={() => addToBasketHandler(product.productId, product.productDescription)}>
                            <FontAwesomeIcon icon={faPlus}/>
                            <FontAwesomeIcon icon={faCartShopping}/>
                        </ConfirmButton>
                    </ProductDetailOrder>
            
                    {/*<ProductDetails list={productDetails} title={t('Product Details')} description={'Squeeze lettuce carefully salty, then mix with white wine and serve thoroughly in bowl.'}/>*/}
                    <PlattixList list={productDetails} title={t('Product.Details')} description={product.productDescription2} />
            
                    {/*{productVariables.map((variable, index) => {*/}
                    {/*    return <ProductVariable*/}
                    {/*        key={`${variable.title}_${index}`}*/}
                    {/*        title={variable.title}*/}
                    {/*        variables={variable.variables}*/}
                    {/*    />*/}
                    {/*})}*/}
                </ProductPageDetails>
            
            
            
            </PlattixCardContainer>
        </>
    );
}