import {ImageGalleryProps} from "PlattixSalesUI/Sales/MarketplaceUtil";
import React, {useState} from "react";
import SwiperClass from "swiper/types/swiper-class";
import {FreeMode, Navigation, Thumbs} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import {ProductPageImage} from "PlattixSalesUI/Sales/MarketplaceStyling";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

export function ImageGallery(props: ImageGalleryProps) {
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass>();

    return (
        <ProductPageImage>
            <Swiper
                spaceBetween={10}
                // navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className={'enlarged-image'} //mySwiper2
            >
                {props.images.map((image, index) =>
                    <SwiperSlide key={`${image.alt}_${index}`}>
                        <img src={image.src} alt={image.alt} />
                    </SwiperSlide>
                )}
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                navigation={true}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className={'image-selector'} //mySwiper
            >
                {props.images.map((image, index) =>
                    <SwiperSlide key={`${image.alt}_${index}`}>
                        <img src={image.src} alt={image.alt} />
                    </SwiperSlide>
                )}
            </Swiper>
        </ProductPageImage>
    );
}