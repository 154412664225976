import React, {PropsWithChildren} from 'react';
import {Breadcrumbs} from '@mui/material';
import {Link, useHistory} from "react-router-dom";
import {css, styled} from "goober";
import {t} from "../PlattixReactCore/i18n";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {HttpError} from "PlattixUI/PlattixReactCore/CoreTypes";
import {filterProps} from "PlattixUI/util/ElementProperties";
import {LoadingScreen} from "PlattixUI/core/components/Loader";
import {ErrorMessage} from "PlattixUI/core/components/ActionBar";

export type BreadcrumbsType = {
    title: string,
    url: string,
}

export interface PageHeaderProps {
    title?: string,
    subheader?: string,
    description?: string
    breadcrumbs?: BreadcrumbsType[],
    backButton?: boolean,
    backButtonLocation?: string,
}
export function PageHeader(props: PageHeaderProps) {
    const history = useHistory();
    
    
    const showBackButton = !!props.backButton;
    
    const goBackHandler = () => {
        if (props.backButtonLocation) history.push(props.backButtonLocation)
        else history.goBack();
    };
    
    return (
            <div className="info">
                {props.title &&
                    <h1 className={`${props.breadcrumbs ? TitleWithBreadcrumbs : ''}`}>{props.title}</h1>
                }
                {props.subheader &&
                    <h5 className={`${props.breadcrumbs ? TitleWithBreadcrumbs : ''}`}>{props.subheader}</h5>
                }
                {(props.breadcrumbs || props.backButton) &&
                    <Breadcrumbs aria-label="breadcrumb" className={BreadcrumbStyling}>

                        {props.backButton &&
                            <BackButton onClick={goBackHandler}>
                                <FontAwesomeIcon icon={faArrowLeft}/>
                                {t('Back')}
                            </BackButton>
                        }
                        
                        { props.breadcrumbs &&
                            props.breadcrumbs.map((bc, index) => {
                                return <Link to={bc.url} key={`bc_${bc.title}_${index}`}>{bc.title}</Link>
                            })
                        }
                    </Breadcrumbs>
                }
                {props.description && <p>{props.description}</p>}
                
                {/*<ResponsiveComponent devices={['mobile', 'tablet']}>*/}
                {/*    <hr />*/}
                {/*</ResponsiveComponent>*/}
            </div>
    );
}

const BreadcrumbStyling = css`
    font-size: unset !important;
    margin: 0 0 15px 0 !important;
    
    a {
        color: var(--textColorDark);
        transition: 0.1s ease-in-out;
        
        &:hover {
            color: var(--styleColor1);
            text-decoration: underline;
        }
    }
`;

const TitleWithBreadcrumbs = css`
    margin: 0 0 5px 0;
`;

export const BackButton = styled('p')(() => {
    return `
        padding: 2px 7px;
        background: var(--buttonColorGrey);
        border-radius: 10px;
        color: #fff;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
        width: fit-content;
    `;
});

interface PageProps extends PageHeaderProps {
    loading?: boolean,
    loadingMessage?: string,
    error?: HttpError | null,
    notFound?: boolean | string,
}
export function Page(props: PropsWithChildren<PageProps>){
    
    return <>
        <PageHeader {...filterProps(props, ["loading", "error", "loadingMessage"])} title={props.title}/>
        {!!props.notFound && <p>{typeof props.notFound === "string" ? props.notFound: t('NotFound')}</p>}
        {props.loading && <LoadingScreen message={props.loadingMessage} />}
        {!props.loading && props.error && <HttpErrorMessage error={props.error}/>}
        {!props.notFound && !props.loading && !props.error && props.children}
    </>
    
}

interface HttpErrorMessageProps {
    error: HttpError
}
export function HttpErrorMessage(props: HttpErrorMessageProps){
    return <ErrorMessage>
        <h1>{props.error.title}</h1>
        <p>{props.error.detail}</p>
    </ErrorMessage>
}