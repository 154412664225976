import {FieldError} from "react-hook-form";
import {HttpError} from "PlattixUI/PlattixReactCore/CoreTypes";
import {isHttpError} from "PlattixUI/PlattixReactCore/api/Api";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import React from 'react';

export function isFieldError(error: FieldError | any): error is FieldError {
    return (error as FieldError).type !== undefined && (error as FieldError).type !== undefined;
}

export function getErrorMessage(fieldName: string, error: React.ReactNode | FieldError | string | string[] | HttpError | null | undefined ): React.ReactNode | null {
    if (!error) return null;


    if (isHttpError(error)) {
        return getErrorMessage(fieldName, error.errors?.[fieldName]);
    }

    if (Array.isArray(error)) {
        return error.join(", ")
    }

    if (typeof error === 'string'){
        return error;
    }
    
    if (React.isValidElement(error)) return error
    
    if (isFieldError(error)) {
        switch (error.type) {
            case "required":
                return t('Validation.Error.Required');
            default:
                return error.type
        }
    }
    
    return error;
    
    // if (error.message) return error.message;

    // return 'error';

}