import React, {PropsWithChildren} from "react";
import {filterProps} from "../../util/ElementProperties";

export type ComparisonListItemProps ={
    label: string,
    value: string | number | undefined | null,
    class?: string
}

export interface ComparisonListProps {
    title?: string,
    titleClass?: string
}

export function ComparisonList(props: PropsWithChildren<ComparisonListProps>) {
    return (
        <>
            {props.title &&
                <div className={`module-content-comparison-listItem-title ${props.titleClass}`}>
                    <label>{props.title}</label>
                </div>
            }
            <ul className="module-content-values">
                {props.children}
            </ul>
        </>
    )
}

export function ComparisonListItem(props: ComparisonListItemProps ){
    return <li className={props.class}>
        <p>{props.label}:</p>
        <p>{props.value ?? '--,--'}</p>
    </li>
}


export interface ComparisonListGroupProps extends React.HTMLAttributes<HTMLDivElement> { }

export function ComparisonListGroup(props: PropsWithChildren<ComparisonListGroupProps>) {
    return (
        <ul className="module-content-comparison-list">
            {React.Children.map(props.children, (c => c ? <li {...filterProps(props, [])}>{c}</li> : <></>)) }
        </ul>
    )
}
