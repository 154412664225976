import Modal, {ModalBaseProps} from "PlattixUI/core/components/Modal";
import {GridProps, PlattixDataGrid} from "PlattixUI/core/grid/PlattixDataGrid";
import {PlattixCardText} from "PlattixUI/core/components/ContentCard";
import React from "react";


export interface ChooseFromGridModalProps extends ModalBaseProps, Pick<GridProps, "includeLanguageCode" | "parameters"> {
    gridCode: string,
    title: string,
    description?: string,

    onSelect: (id, row) => void,

}

export function ChooseFromGridModal(props: ChooseFromGridModalProps) {
    return <Modal
        show={props.show}
        onClose={props.onClose}
        closeOnOutsideClick={props.closeOnOutsideClick ?? false}
        showCancelButton={false}
        showConfirmButton={false}
        title={props.title}
    >
        {props.description && <PlattixCardText text={props.description} textStyle={{centered: 'true'}}/>}
        <PlattixDataGrid
            gridCode={props.gridCode}
            onCellDoubleClick={(cell) => {
                props.onSelect(cell.id, cell.row);
                props.onClose()
            }}
            includeLanguageCode={props.includeLanguageCode}
            parameters={props.parameters}
            defaultPageSize={10}
            defaultDensity={'compact'}
            focusQuickSearch
        />
    </Modal>
}