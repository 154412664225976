import React, {PropsWithChildren} from 'react';
import {LoginPage} from "./pages/Account/Login";
import {Route, Switch, useLocation} from "react-router-dom";
import {RegisterPage} from "./pages/Account/Register";
import {ResetPasswordPage} from "./pages/Account/ResetPassword";
import {Reset2FAPage} from "./pages/Account/Reset2FA";
import {Logout} from "./pages/Account/Logout";
import {UserState} from "../PlattixReactCore/UserSlice";
import {ForgotPasswordPage} from "./pages/Account/ForgotPasswordPage";
import {PlattixConfiguration} from "../../configuration/Configuration";
import {useTranslation} from "PlattixUI/PlattixReactCore/i18n";
import {useTheme} from "@mui/material/styles";
import {useMediaQuery} from "@mui/material";
import {ResponsiveComponent} from "./components/Responsive";
import {SidebarLoginComponent} from "./SidebarLogin";
import {AnonymousRoutes, OverwriteRoutes} from "PlattixUI/core/pages/Account/CheckLoginComponent";
import {PlattixImage} from "PlattixUI/util/thumbor/PlattixImage";

type ContentLoginProps = {

    /**
     * Routes that may be access if a user is not logged in
     */
    anonymousRoutes?: AnonymousRoutes,
    overwriteRoutes?: OverwriteRoutes,
    userState: UserState,
    noBackground?: boolean
}

export function ContentLoginComponent(props: ContentLoginProps) {
    const {t} = useTranslation();
    let location = useLocation();
    
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    const tablet = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const desktop = useMediaQuery(theme.breakpoints.up('lg'));
    
    // if (location.pathname.toLowerCase().startsWith('/legal')) return (
    //     <>
    //         <Switch>
    //             <Route path="/Legal" component={Legal} />
    //         </Switch>
    //     </>
    // );

    return (
        <>
            {!props.noBackground &&
                <ResponsiveComponent devices={['tablet', 'desktop']}>
                    <div className="background">
                        <img src={PlattixConfiguration.loginScreen.background} alt="Background"/>
                    </div>
                </ResponsiveComponent>
            }

            <section className="content-container-login" id="content-container">
                <div className="login-logo">
                    <a href="/">
                        <PlattixImage
                            width={0}
                            height={50}
                            fileSource={PlattixConfiguration.mainLogo}
                            alt={`Logo ${PlattixConfiguration.platformName}`}
                        />
                    </a>
                </div>
                <>
                    <Switch>
                        {props.overwriteRoutes && props.overwriteRoutes.map(r => (
                            <Route key={r.url} path={r.url}>
                                {r.component}
                            </Route>)
                        )}
                        <Route path='/logout' component={Logout}/>
                        <Route path='/register' component={RegisterPage}/>
                        <Route path='/password-reset' component={ResetPasswordPage}/>
                        <Route path='/forgot-password' component={ForgotPasswordPage}/>
                        <Route path='/2fa-reset' component={Reset2FAPage}/>

                        {props.anonymousRoutes && props.anonymousRoutes.map(r => (
                            <Route key={r.url} path={r.url}>
                                {r.component}
                            </Route>)
                        )}
                        
                        <Route path='/'>
                            <LoginPage userState={props.userState}/>
                        </Route>
                    </Switch>
                </>
                {
                    PlattixConfiguration.loginScreen.sponsors?.length &&
                    <>
                        <ResponsiveComponent devices={['mobile', 'tablet']}>
                            <hr/>
                        </ResponsiveComponent>

                        <div className="sponsors">
                            <div className="sponsor">
                                <p>{t('Plattix.Sponsor.WithSupportFrom')}</p>
                                {
                                    PlattixConfiguration.loginScreen.sponsors.map(s =>
                                        <img key={s.name} src={s.logo} alt={`Logo ${s.name}`}/>
                                    )
                                }
                            </div>
                        </div>
                    </>


                }

                <ResponsiveComponent devices={['mobile', 'tablet']}>
                    <hr/>
                    <SidebarLoginComponent/>
                </ResponsiveComponent>

            </section>
        </>
    );
}


const AnonymousComponentWrapper = (props: PropsWithChildren<any>) => {
    return (<>
            <SidebarLoginComponent/>
            <section className="content">
                <section className="body">
                    {props.children}
                </section>
            </section>
        </>
    )
}