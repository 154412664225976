import {
    GridApi,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps, 
    gridFilteredSortedRowIdsSelector,
    GridFilterModel,
    GridFilterPanel,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector, GridToolbarExportContainer, gridVisibleColumnFieldsSelector, useGridApiContext
} from "@mui/x-data-grid-pro";
import {ButtonProps, Collapse, IconButton, InputAdornment, MenuItem, TextField} from "@mui/material";
import {useTranslation} from "../../PlattixReactCore/i18n";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter, faSearch} from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import {useState} from "react";
import {SavedFilter} from "PlattixUI/core/grid/types/GridFilterTypes";
import {DataGridToolButton} from "./gridStyling/GridStyling";

interface SearchToolbarProps {
    disableFilter: boolean,
    enableDataExport: boolean,
    quickSearch: QuickSearchToolbarProps,
    advancedSearch: AdvancedSearchToolbarProps,

    useQuickSearch: boolean,
    setUseQuickSearch: (value: (((prevState: boolean) => boolean) | boolean)) => void,
}

interface AdvancedSearchToolbarProps {
    filter: GridFilterModel,
    savedFilters: SavedFilter[],
    setAdvancedFilter: React.Dispatch<React.SetStateAction<GridFilterModel>>
}


interface QuickSearchToolbarProps {
    hasQuickSearch: boolean,
    hasRegexQuickSearch: boolean,
    hasCaseSensitiveQuickSearch: boolean,

    clearSearch: () => void,
    onChange: () => void,
    value: string,
    regex: boolean,
    toggleRegex: () => void,
    caseSensitive: boolean,
    toggleCaseSensitive: () => void,

    advancedSearch: GridFilterModel

    focusQuickSearch?: boolean,
}

export function SearchToolbar(props: SearchToolbarProps) {
    const {t} = useTranslation()
    const [selectedSavedFilter, setSelectedSavedFilter] = useState<number>(-1);
    
    const savedFilterOptions: SavedFilter[] = [
        {
            id: -1,
            default: false,
            saveResults: false,
            criterium: {logic: 'or', criteria: []},
            name: t('Opgeslagen filters')
        },
        ...(props.advancedSearch.savedFilters ?? [])
    ]

    return <>
        <GridToolbarContainer>
            <GridToolbarColumnsButton/>
            <GridToolbarDensitySelector/>
            
            {!props.disableFilter && 
                <>
                    <button onClick={() => props.setUseQuickSearch(!props.useQuickSearch)} className={DataGridToolButton}>
                        <span className={'MuiButton-startIcon MuiButton-iconSizeSmall'}>
                            <FontAwesomeIcon icon={faFilter} className={'MuiSvgIcon-root MuiSvgIcon-fontSizeMedium'}/>
                        </span>
                        {t('Filter')}
                    </button>
                </>
            }

            {props.enableDataExport &&
                <CustomExportButton />
            }

            {(!props.disableFilter && props.useQuickSearch) &&
                <QuickSearch {...props.quickSearch}/>
            }

        </GridToolbarContainer>
        <Collapse in={!props.useQuickSearch}>
            <GridFilterPanel
                filterFormProps={{
                    // Customize inputs by passing props
                    linkOperatorInputProps: {
                        size: 'small',
                    },
                    columnInputProps: {
                        size: 'small',
                        sx: {mt: 'auto'},
                    },
                    operatorInputProps: {
                        size: 'small',
                        sx: {mt: 'auto'},
                    },
                    deleteIconProps: {
                        sx: {
                            '& .MuiSvgIcon-root': {color: '#d32f2f'},
                        },
                    }
                }}
            />
            {/*<AdvancedSearch {...props.advancedSearch}/>*/}
        </Collapse>
    </>

}

function JsonExportMenuItem(props: GridExportMenuItemProps<{}>) {
    const apiRef = useGridApiContext();

    const { hideMenu } = props;

    const getJson = (apiRef: React.MutableRefObject<GridApi>) => {
        // Select rows and columns
        const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

        // Format the data. Here we only keep the value
        const data = filteredSortedRowIds.map((id) => {
            const row: Record<string, any> = {};
            visibleColumnsField.forEach((field) => {
                row[field] = apiRef.current.getCellParams(id, field).value;
            });
            return row;
        });

        // Stringify with some indentation
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#parameters
        return JSON.stringify(data, null, 2);
    };

    const exportBlob = (blob: Blob, filename: string) => {
        // Save the blob in a json file
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();

        setTimeout(() => {
            URL.revokeObjectURL(url);
        });
    };

    return (
        <MenuItem
            onClick={() => {
                const jsonString = getJson(apiRef);
                const blob = new Blob([jsonString], {
                    type: 'text/json',
                });
                exportBlob(blob, 'export.json');

                // Hide the export menu after the export
                hideMenu?.();
            }}
        >
            Export JSON
        </MenuItem>
    );
}

function CustomExportButton(props: ButtonProps) {
    const csvOptions: GridCsvExportOptions = {
        delimiter: ';',
        utf8WithBom: true,
        fileName: 'export',
    };
    
    return (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
            <JsonExportMenuItem />
        </GridToolbarExportContainer>
    );
}

function QuickSearch(props: QuickSearchToolbarProps) {
    const {t} = useTranslation();
    if (!props.hasQuickSearch)
        return <></>

    return (
        <div className="module-content-search" style={{maxWidth: '200px', marginRight: '5px'}}>

            <TextField
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder={t('QuickSearch')}
                style={{boxShadow: "none"}}
                autoFocus={props.focusQuickSearch}
                InputProps={{
                    startAdornment: <FontAwesomeIcon icon={faSearch}/>,
                    endAdornment: (
                        <InputAdornment position="end">
                            {
                                props.hasRegexQuickSearch && (
                                    <IconButton
                                        aria-label="toggle regex"
                                        onClick={props.toggleRegex}
                                        edge="end"
                                        style={{color: props.regex ? 'black' : 'lightgrey'}}
                                    >
                                        .*
                                    </IconButton>
                                )}
                            {
                                props.hasCaseSensitiveQuickSearch && (
                                    <IconButton
                                        aria-label="toggle case sensitive"
                                        onClick={props.toggleCaseSensitive}
                                        edge="end"
                                        style={{color: props.caseSensitive ? 'black' : 'lightgrey'}}
                                    >
                                        <abbr title={t('Grid.Filter.CaseSensitive')}>aA</abbr>
                                    </IconButton>
                                )
                            }
                        </InputAdornment>
                    ),
                }}
                sx={{
                    width: {
                        xs: 1,
                        sm: 'auto',
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    '& .MuiSvgIcon-root': {
                        mr: 0.5,
                    },
                    '& .MuiInput-underline:before': {
                        borderBottom: 1,
                        borderColor: 'divider',
                    },
                }}
            />
        </div>
    );
}

