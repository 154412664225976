import {styled} from "goober";

export interface TranslationDescriptionContainerProps {
    closed?: boolean
}

export const TranslationDescriptionContainer = styled('div')((props: TranslationDescriptionContainerProps) => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        margin-bottom: var(--marginBottom3);
        border-left: var(--borderRight1);
        gap: 15px;
    `;
});

export const TranslationDescription = styled('div')(() => {
    return `
        width: 100%;
        padding: var(--padding5);
        padding-bottom: 15px;
        &:not(:last-child) {
            border-bottom: var(--borderBottom1);
        }
    `;
});

export const TranslationDescriptionTitle = styled('p')(() => {
    return `
        width: 100%;
        font-weight: 700;
        font-size: 1.1em;
    `;
});

export const TranslationDescriptionText = styled('p')(() => {
    return `
        width: 100%;
        
        ul {
            margin: 0;
            padding: var(--padding5);
        }
    `;
});