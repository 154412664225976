import {ArgumentOutOfRangeError} from "rxjs";
import moment from "moment";


export function formatEuro(number: number, decimals: number | undefined = 2) {
    return '€' + toFixedDecimal(number, decimals)
}

/**
 * Round a number to a certain number of decimal places. This function returns a number
 * 
 * For string formatting use {@see toFixedDecimal} 
 * @param number
 * @param decimals
 */
export function round(number: number, decimals?: number | undefined): number {
    decimals = decimals ?? 2;
    const power = (10 ** decimals )
    return Math.round((number + Number.EPSILON) * power) / power
}

/**
 * Format a number in the browsers locale with a fixed number of decimals
 * @param number - the number to format
 * @param decimals - number of decimal places (Default: 2)
 */
export function toFixedDecimal(number: number|null|undefined, decimals?: number | undefined): string {
    decimals = decimals ?? 2
    return number === null || number === undefined 
        ? "" : 
        // TODO: use locale of user
        number.toLocaleString(undefined, {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        })
}

const groupWeight = 1000;
const unitMultipliers = " kMGTPEZY";
const unitDivider = " mµnpfqzy";
export function metricPrefixNumber(value: number, unit: string | undefined, numDecimals?: number)
{
    if (!unit) return toFixedDecimal(value);
    if (value === 0) return `${value} ${unit}`;
    if (!isFinite(value)) return "∞" + unit;
    
    let baseUnit = unit;
    let baseMultiplier = 1;

    for (let i = 0; i < unitMultipliers.length; i++) {
        if (unit[0] === unitMultipliers[i]){
            baseMultiplier = Math.pow(1000, i)
        }
    }

    for (let i = 0; i < unitDivider.length; i++) {
        if (unit[0] === unitDivider[i]){
            baseMultiplier = Math.pow(1000, -i)
        }
    }
    if (baseMultiplier !== 1){
        baseUnit = unit.slice(1);
        value *= baseMultiplier;
    }
    
    let sign = "";
    if (value < 0) {
        sign = "-";
        value = -value;
    }


    let numberWeight = Math.floor(Math.log(value) / Math.log(groupWeight));
    let unitWeightSymbol: string = '';


    if (numberWeight >= 2 && (unit.toLowerCase() === "kg" || unit.toLowerCase() === "g"))
    {
        // from gram to ton = factor 10^6
        return `${sign}${(value / 1000_000).toFixed(numDecimals)} ton`;
    }

    if (numberWeight > 0)
    {
        numberWeight = Math.min(numberWeight, unitMultipliers.length - 1);
        unitWeightSymbol = unitMultipliers[numberWeight];
    }
    else if (numberWeight < 0)
    {
        numberWeight = Math.min(-numberWeight, unitDivider.length - 1);
        unitWeightSymbol = unitDivider[numberWeight];
    }

    value = value / Math.pow(groupWeight, numberWeight);
    return `${sign}${value.toFixed(numDecimals)} ${unitWeightSymbol}${baseUnit}`;
}


/**
 * Get a number to a specified precision.
 * 
 * The precision must be passed as a base 10 number.
 * e.g.: 0.001, or 10
 * @param value: the value to round
 * @param precision: the precision to round to
 */
export function toPrecision(value: number, precision: number = 0): string {
    if (precision < 0) throw new ArgumentOutOfRangeError();
    if (precision < 1){
        const decimalPlaces = -Math.round(Math.log(precision) / Math.log(10))
        return toFixedDecimal(value, decimalPlaces)
    }
    
    return String(Math.round(value / precision) * precision)
}

/**
 * Calculates modulo of very large numbers 
 * 
 * source and explanation: https://www.geeksforgeeks.org/how-to-compute-mod-of-a-big-number/
 * @param num: input
 * @param a: modulo
 */
export function mod(num: number | string, a: number)
{
    num = num.toString();

    // Initialize result
    let res = 0;

    // One by one process
    // all digits of 'num'
    for(let i = 0; i < num.length; i++)
        res = (res * 10 + parseInt(num[i])) % a;

    return res;
}

export const calculateAverageTime = (times: Date[]) => {
    
    // const totalDurations = times.slice(1)
    //     .reduce((previous, current) => moment.duration(current).add(previous),
    //         moment.duration(times[0]))
    
    // return moment.utc(totalDurations.asMilliseconds()).format("HH:mm:ss");

    // const averageTime = moment(Math.round(times.reduce((a, b) => a + moment(b), 0) / times.length)).format();
    // console.log(averageTime)
    //
    // return averageTime;
    return 'TBD';
}