import {GridRowParams} from "@mui/x-data-grid-pro";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import * as React from "react";


export type GridRowOptions = GridRowOption[];
export type GridRowOption = GridRowOptionLink | GridRowOptionAction;
export type IGridGrowOption = {
    /**
     * Font Awesome icon to display
     * 
     * Required if option not shown in menue
     */
    icon?: IconProp,
    /**
     * Label of option
     */
    label: string,
    /**
     * Optional - Only show option if function evaluates to true
     */
    showIf?: ((cell: GridRowParams) => boolean),
    /**
     * true: Show the option in a dropdown menu
     * false: Show option in cell
     */
    showInMenu?: boolean
} & (
    {
        showInMenu: false;
        icon: React.ReactElement;
    } | (
        {
            showInMenu?: true;
        }
    )
)
/**
 * Will create a link to another page
 */
export type GridRowOptionLink =  IGridGrowOption  & {
    /**
     * address to link to.
     * Must be a string or a function that will return a string that gets the row as input
     * This can be used to create a link specific for each row
     */
    href: string | ((cell: GridRowParams) => string),
}
/**
 * Create an option action
 */
export type GridRowOptionAction =  IGridGrowOption & {
    /**
     * Function called when action is clicked 
     * @param cell {@type GridRowParams} contains all row data
     */
    onClick: (cell: GridRowParams) => void,
}

export function isGridRowOptionLink(link: GridRowOptionLink | any): link is GridRowOptionLink {
    if (link === undefined) return false;
    return (
        (link as GridRowOptionLink).href !== undefined
        && (link as GridRowOptionLink).label !== undefined
    )
}

export function isGridRowOptionAction(action: GridRowOptionAction | any): action is GridRowOptionAction {
    if (action === undefined) return false;
    return (
        (action as GridRowOptionAction).onClick !== undefined
        && (action as GridRowOptionAction).label !== undefined
    )
}