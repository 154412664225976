import {t} from "PlattixUI/PlattixReactCore/i18n";
import {ShoppingCartViewModel, ShopProductViewModel, useActiveCart} from "PlattixSalesUI/Sales/MarketplaceUtil";
import {NumberInputStyling} from "PlattixSalesUI/Sales/MarketplaceStyling";
import {doGet, isHttpError, usePlattixMutation, usePlattixQuery} from "PlattixUI/PlattixReactCore/api/Api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ConfirmButton} from "PlattixUI/core/components/Buttons";
import {faCartShopping, faPlus} from "@fortawesome/free-solid-svg-icons";
import {PlattixShowcaseCard} from "PlattixUI/core/components/ContentCard";
import {generatePath, Link} from "react-router-dom";
import {toast} from "react-toastify";
import {styled} from "goober";
import {ShowcaseCardSpacer} from "PlattixUI/core/components/ContentCardStyling";
import React, {useEffect, useState} from "react";
import NumberInput from 'semantic-ui-react-numberinput';
import {ActiveCartQueryKey} from "PlattixSalesUI/Sales/MarketplaceUtil";
import {Endpoints} from "configuration/ApiEnpointMap";
import {Spinner} from "PlattixUI/core/components/Loader";
import {formatEuro} from "PlattixUI/util/numberUtil";

export interface ProductShowcaseEndpointsType {
    addToCart: string;
    productDetail: string;
    updateProductAmount: string;
    getActiveCart: string;
}

export interface ProductShowcaseProps {
    product: ShopProductViewModel;
    endPoints: ProductShowcaseEndpointsType;
    className?: string;
    disableLink?: boolean;
    showAddToCartBtn?: boolean;
    showAmountInput?: boolean;
    // startAmountFromZero?: boolean;
    AutoAddToCart?: boolean;
}

export function ProductShowcase(props: ProductShowcaseProps){
    const product = props.product;
    const defaultQty = product?.salesMultiple >= 0 ? 1 : product?.salesMultiple;
    const showAddToCartBtn = props.showAddToCartBtn ?? true;
    const showAmountInput = props.showAmountInput ?? false;
    const [productQty, setProductQty] = useState<number>(defaultQty);
    const numberInputId = `amountSelector_${product.productId}`;
    const [loading, setLoading] = useState<boolean>(false);
    const [amountLoading, setAmountLoading] = useState<boolean>(false);
    
    const cartQuery = async () => {
        setAmountLoading(true);
        
        const response = await doGet<ShoppingCartViewModel>(props.endPoints.getActiveCart);
        if (isHttpError(response)) {
            return cleanup();
        }
        
        const currentProductFormCart = response.selectedItems?.find(si => si.productId === product.productId)
        // if (currentProductFormCart) setProductQty(currentProductFormCart.amount);

        function cleanup() {
            setAmountLoading(false);
            if (currentProductFormCart && props.AutoAddToCart) setProductQty(currentProductFormCart.amount)
            else setProductQty(defaultQty);
        }
        
        return cleanup();
    };
    
    useEffect(() => {
        cartQuery();
    }, []);

    const addToCartMutation = usePlattixMutation<ShoppingCartViewModel, {productId: number, amount: number}>(
        [ActiveCartQueryKey],
        props.endPoints.addToCart
    );

    const productAmountMutation = usePlattixMutation<ShoppingCartViewModel, {productId: number, amount: number}>(
        [ActiveCartQueryKey],
        props.endPoints.updateProductAmount
    );
    
    const tempFullPrice = ((product.staffoldPrices[0].salesPrice * (product.vat.percentage/100)) + product.staffoldPrices[0].salesPrice).toFixed(2);

    const addToBasketHandler = async (productId: number, productName: string) => {
        try {
            await addToCartMutation.mutate({productId: productId, amount: productQty});
        } catch (e) {
            toast(t('Marketplace.Overview.Product.AddedToCard.Failed', {product: productName}), {type: "success"})
        } finally {
            toast(t('Marketplace.Overview.Product.AddedToCard', {product: productName}), {type: "success"})
            if (!props.AutoAddToCart) setProductQty(defaultQty)
        }
    }

    const productQtyHandler = (qty: number) => {
        setProductQty(qty)
        if (props.AutoAddToCart) productAmountMutation.mutateAsync({productId: props.product.productId, amount: qty})
    };

    useEffect(() => {
        if (amountLoading) return;
        // setProductQty(props.showAmountInput ? '0' : product.minQuantity.toString())

        // Fix voor ontbrekende icons in NumberInput
        if (!document.querySelector(`#${numberInputId} .button .icon.minus .fa-minus`)) {
            const minusIcon = document.createElement("i");
            minusIcon.className = "fa fa-minus";
            document.querySelector(`#${numberInputId} .button .icon.minus`)?.appendChild(minusIcon);
        }

        // Fix voor ontbrekende icons in NumberInput
        if (!document.querySelector(`#${numberInputId} .button .icon.plus .fa-plus`)) {
            const plusIcon = document.createElement("i");
            plusIcon.className = "fa fa-plus";
            document.querySelector(`#${numberInputId} .button .icon.plus`)?.appendChild(plusIcon);
        }

    }, [amountLoading]);
    
    return (
        <PlattixShowcaseCard
            img={product.filePath}
            imgFit={'cover'}
            link={props.disableLink ? undefined : generatePath(props.endPoints.productDetail, {productId: product.productId})}
            fillHeight={false}
            className={props.className}
        >
            <ProductTitle>
                {props.disableLink ?
                    <>
                        <h5>{product.productDescription}</h5>
                    </>
                    :
                    <>
                        <Link to={generatePath(props.endPoints.productDetail, {productId: product.productId})}>
                            <h5>{product.productDescription}</h5>
                        </Link>
                    </>
                }
                <p>{product.productDescription2}</p>
            </ProductTitle>
    
            <ShowcaseCardSpacer spacing={'none'}/>
    
            {/*// TODO: Meegeven in de backend om de producten van een samengesteld product weer te geven*/}
            {product.childProducts?.length &&
                <>
                    <ProductChildProductListContainer>
                        <b>{t('Marketplace.Overview.Product.Contents')}:</b>
                        <ProductChildProductList>
                            {product.childProducts.map((productchild, index) => {
                                return (
                                    <ProductChildProductListItem key={`${productchild.productId}_${index}`}>
                                        {productchild.quantity}x {productchild.productDescription}
                                    </ProductChildProductListItem>
                                )
                            })}
                        </ProductChildProductList>
                    </ProductChildProductListContainer>
    
                    <ShowcaseCardSpacer spacing={'none'}/>
                </>
            }
    
            <ProductFooter>
                <ProductFooterLeft>
                    <ProductFooterPrice>
                        <h5>{formatEuro(parseInt(tempFullPrice))} / {t(product.skuCodeDescription)}</h5>
                        <p>{formatEuro(product.staffoldPrices[0].salesPrice)} {t('Marketplace.Home.Products.ExcludingVat', {amount: product.vat.percentage})}</p>
                    </ProductFooterPrice>
                </ProductFooterLeft>
                <ProductFooterRight>
                    {showAmountInput &&
                        amountLoading ?
                        <Spinner size={'small'} />
                        :
                        <NumberInput
                            value={productQty}
                            // minValue={product?.minQuantity}
                            minValue={0}
                            stepAmount={product?.salesMultiple >= 0 ? 1 : product?.salesMultiple}
                            onChange={productQtyHandler}
                            buttonPlacement="leftAndRight"
                            className={NumberInputStyling}
                            id={numberInputId}
                        />
                    }
                    {showAddToCartBtn &&
                        <ConfirmButton onClick={() => addToBasketHandler(product.productId, product.productDescription)}>
                            <FontAwesomeIcon icon={faPlus}/>
                            <FontAwesomeIcon icon={faCartShopping}/>
                        </ConfirmButton>
                    }
                </ProductFooterRight>
    
            </ProductFooter>
        </PlattixShowcaseCard>
    );
}

export const ProductTitle = styled('div')(() => {
    return `
        width: 100%;
        padding: 0;
        margin: 0;
        
        a {
            cursor: pointer;
        }
    `;
});

export const ProductChildProductListContainer = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        gap: 5px;
        margin: 0;
        padding: 0;
    `;
});

export const ProductChildProductList = styled('ul')(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        gap: 5px;
        margin: 0;
        padding: 0 0 0 15px;
    `;
});

export const ProductChildProductListItem = styled('li')(() => {
    return `
        width: 100%;
        padding: 0;
        margin: 0;
    `;
});

export const ProductFooter = styled('div')(() => {
    return `
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
    `;
});

export const ProductFooterLeft = styled('div')(() => {
    return `
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: flex-end;
    `;
});

export const ProductFooterRight = styled('div')(() => {
    return `
        width: fit-content;
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
        align-items: flex-end;
        gap: 10px;
    `;
});

export const ProductFooterPrice = styled('div')(() => {
    return `
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        flex-flow: column nowrap;
        gap: 5px;
        
        h5 {
            font-size: 1.3em;
            font-weight: 700;
            margin: 0;
            padding: 0;
        }
        
        p {
            font-style: italic;
            opacity: 0.8;
        }
    `;
});