import PasswordStrengthBar, {PasswordStrengthBarProps} from "react-password-strength-bar";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import React from "react";


export function PasswordStrengthIndicator(props: PasswordStrengthBarProps) {
    const scoreWords = [
        t('Validation.PasswordStrength.VeryWeak'),
        t('Validation.PasswordStrength.Weak'),
        t('Validation.PasswordStrength.Okay'),
        t('Validation.PasswordStrength.Good'),
        t('Validation.PasswordStrength.Strong'),
    ]
    
    return <PasswordStrengthBar
        minLength={8}
        shortScoreWord={t('Validation.PasswordStrength.TooShort')}
        scoreWords={scoreWords}
        className={'form-group row'}
        {...props}
    />
}
