import {useQuery} from '@tanstack/react-query';
import {doGet, throwOnHttpError} from "PlattixUI/PlattixReactCore/api/Api";
import {SelectOption} from "PlattixUI/core/components/form/Select";

export function useCodesQuery(code: string, namespace?: string){
    return useQuery(
        ['Codes', code, namespace ?? 'DataAccessLayer.Data'],
        () => throwOnHttpError(
            doGet<SelectOption[]>('/Code/PlattixSelectCodeList', {
                Namespace: namespace ?? 'DataAccessLayer.Data',
                TableName: code
            })
        )
    )
}