import React from 'react';
import {PlattixNavigation, PlattixNavigationItem} from "PlattixUI/core/components/MenuComponents";
import {t} from 'PlattixUI/PlattixReactCore/i18n';
import {MarketplaceRouteMap} from "configuration/RouteMap";
import {WebshopSelector} from "Energyix/components/WebshopSelector";

export function MainMenu() {

    return (
        <>
            <PlattixNavigation>
                
                <WebshopSelector/>
                <hr/>

                <PlattixNavigationItem
                    Name={t('Menu.Marketplace.General')}
                    Group={true}
                >
                    <PlattixNavigationItem
                        Name={t('Menu.Marketplace.General.Overview')}
                        Link={MarketplaceRouteMap.Overview()}
                    />
                    <PlattixNavigationItem
                        Name={t('Menu.Marketplace.Order.Overview')}
                        Link={MarketplaceRouteMap.Orders()}
                    />
                    <PlattixNavigationItem
                        Name={t('Menu.Marketplace.ControllerFinder')}
                        Link={MarketplaceRouteMap.ControllerFinder()}
                    />
                </PlattixNavigationItem>

                {false &&
                    <>
                        <PlattixNavigationItem
                            Name={t('Menu.Marketplace.Categories')}
                            Group={true}
                        >
                            <PlattixNavigationItem
                                Name={t('Category 1')}
                                Link={`/`}
                            >
                                <PlattixNavigationItem
                                    Name={t('Subcategory 1')}
                                    Link={`/`}
                                />
                                <PlattixNavigationItem
                                    Name={t('Subcategory 2')}
                                    Link={`/`}
                                />
                            </PlattixNavigationItem>
                            <PlattixNavigationItem
                                Name={t('Category 2')}
                                Link={`/`}
                            />
                        </PlattixNavigationItem>

                        <PlattixNavigationItem
                            Name={t('Menu.Marketplace.Product.Filter')}
                            Group={true}
                        >
                            <PlattixNavigationItem
                                Name={t('Filter 1')}
                                Link={`/`}
                            />
                            <PlattixNavigationItem
                                Name={t('Filter 2')}
                                Link={`/`}
                            />
                            <PlattixNavigationItem
                                Name={t('Filter 3')}
                                Link={`/`}
                            />
                            <PlattixNavigationItem
                                Name={t('Filter 2')}
                                Link={`/`}
                            />
                            <PlattixNavigationItem
                                Name={t('Filter 3')}
                                Link={`/`}
                            />
                            <PlattixNavigationItem
                                Name={t('Filter 2')}
                                Link={`/`}
                            />
                            <PlattixNavigationItem
                                Name={t('Filter 3')}
                                Link={`/`}
                            />
                        </PlattixNavigationItem>

                        <PlattixNavigationItem
                            Name={t('Menu.Marketplace.Product.Filter')}
                            Group={true}
                        >
                            <PlattixNavigationItem
                                Name={t('Filter 1')}
                                Link={`/`}
                            />
                            <PlattixNavigationItem
                                Name={t('Filter 2')}
                                Link={`/`}
                            />
                            <PlattixNavigationItem
                                Name={t('Filter 3')}
                                Link={`/`}
                            />
                            <PlattixNavigationItem
                                Name={t('Filter 2')}
                                Link={`/`}
                            />
                            <PlattixNavigationItem
                                Name={t('Filter 3')}
                                Link={`/`}
                            />
                            <PlattixNavigationItem
                                Name={t('Filter 2')}
                                Link={`/`}
                            />
                            <PlattixNavigationItem
                                Name={t('Filter 3')}
                                Link={`/`}
                            />
                        </PlattixNavigationItem>
                    </>
                }
                
            </PlattixNavigation>
        </>
    );

}