import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "PlattixUI/PlattixReactCore/store";

interface SelectedSalesAccountStatus {
    accountId: number
}

const initialState: SelectedSalesAccountStatus = {
    accountId: 0
};


export const SelectedSalesAccountSlice = createSlice({
    name: 'selectedSalesAccount',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setSelectedSalesAccountId(state, action: PayloadAction<number> ) {
            state.accountId = action.payload;
        }
    },
});


export const selectedSalesAccountSelector = (state: RootState) => state.selectedSalesAccount.accountId;

export default SelectedSalesAccountSlice.reducer;
export const {
    setSelectedSalesAccountId
} = SelectedSalesAccountSlice.actions
