import React, { useEffect } from "react";
import {EnergytixOrderDetailsQueryKey, OrderStatusCode} from "PlattixSalesUI/Sales/MarketplaceUtil";
import {MarketplaceHeader} from "PlattixSalesUI/Sales/Marketplace/Components/MHeader";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {mirrorIcon, OrderDetailItem,} from "PlattixSalesUI/Sales/MarketplaceStyling";
import {ConfirmButton, DownloadFileButton, EditButton} from "PlattixUI/core/components/Buttons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCashRegister, faFileArrowDown, faHammer, faTruckArrowRight} from "@fortawesome/free-solid-svg-icons";
import {PlattixCard, PlattixCardContainer} from "PlattixUI/core/components/ContentCard";
import {MarketplaceAddresses} from "PlattixSalesUI/Sales/Marketplace/Components/MAddresses";
import {useNumberParams} from "PlattixUI/util/useIdParam";
import {NotFoundError} from "PlattixUI/core/ErrorPages/NotFoundError";
import {LoadingScreen} from "PlattixUI/core/components/Loader";
import {usePlattixQuery} from "PlattixUI/PlattixReactCore/api/Api";
import {EnergytixOrder} from "PlattixSalesUI/Sales/Marketplace/Types/Order";
import {generatePath} from "react-router-dom";
import {Endpoints} from "configuration/ApiEnpointMap";
import {SalesConfigurationProductList} from "PlattixSalesUI/Sales/Components/SalesConfigurationProductList";
import {toast} from "react-toastify";

export function MarketplaceOrderDetails() {
    let {orderId} = useNumberParams<{orderId: number}>();
    
    const orderQuery = usePlattixQuery<EnergytixOrder>(
        [EnergytixOrderDetailsQueryKey],
        generatePath(Endpoints.Marketplace.OrderDetails(), {
            orderId: orderId ?? -1
        })
    );
    const order = orderQuery.data;
    
    const downloadInvoiceHandler = () => {
        console.log('downloadInvoice');
    };
    
    const continuePaymentHandler = () => {
        console.log('continuePaymentHandler');
    }
    
    const actions = [
        // <EditButton onClick={downloadInvoiceHandler}>
        //     <FontAwesomeIcon icon={faTruckArrowRight} className={mirrorIcon}/>
        //     {t("Product.Retour")}
        // </EditButton>,
        // <EditButton onClick={downloadInvoiceHandler}>
        //     <FontAwesomeIcon icon={faHammer}/>
        //     {t("Product.Repair")}
        // </EditButton>,
        <DownloadFileButton
            url={""}
            label={t(`Invoice.DownloadPDF`)}
            requestConfig={{withCredentials: true}}
            onDownloadError={() => toast.error(t('File.Download.Error'))}
        />,
        <>
            {(
                    order?.statusCode === OrderStatusCode.Created ||
                    order?.statusCode === OrderStatusCode.PaymentFailed
                ) &&
                <ConfirmButton onClick={continuePaymentHandler}>
                    <FontAwesomeIcon icon={faCashRegister}/>
                    {t("ContinuePayment")}
                </ConfirmButton>
            }
        </>
    ];
    
    if (orderQuery.isLoading)
        return <LoadingScreen />
    
    if (!order)
        return <NotFoundError />

    return (
        <>
            <MarketplaceHeader
                title={t('OrderDetail.Headline')}
                subheader={`${t('OrderNumber')}: ${orderId}`}
                includeBtns={{
                    cart: false, 
                    continueShopping: false
                    // continuePayment: true,
                }}
                // hideActions
                actions={{right: actions}}
            />
            
            <PlattixCardContainer>
                
                <PlattixCard 
                    header={t('MarketplaceOrderDetails.Info.Title')}
                >
                    <OrderDetailItem>
                        <dl>
                            <dt>{t('MarketplaceOrderDetails.OrderStatus')}</dt>
                            <dd>{order.statusCodeDescription}</dd>
                        </dl>
                        <hr/>

                        <dl>
                            <dt>{t('MarketplaceOrderDetails.OrderDate')}</dt>
                            <dd>{new Date().toLocaleString()}</dd>
                        </dl>
                        <dl>
                            <dt>{t('MarketplaceOrderDetails.Payment.Gateway')}</dt>
                            <dd>{order.paymentMethodDetail}</dd>
                        </dl>
                        <dl>
                            <dt>{t('MarketplaceOrderDetails.Payment.Method')}</dt>
                            <dd>{order.paymentMethodCodeDescription}</dd>
                        </dl>
                        <dl>
                            <dt>{t('MarketplaceOrderDetails.Shipping.Method')}</dt>
                            <dd>Shipping method</dd>
                        </dl>
                        <dl>
                            <dt>{t('MarketplaceOrderDetails.TrackTrace')}</dt>
                            <dd>Track & Trace link + code</dd>
                        </dl>
                    </OrderDetailItem>
                </PlattixCard>
                
                <PlattixCard 
                    header={t('MarketplaceOrderDetails.Shipping.Title')}
                >
                    <MarketplaceAddresses invoiceAddress={order.invoiceAddress} deliveryAddress={order.deliveryAddress} />
                </PlattixCard>
                
                <PlattixCard 
                    header={t('MarketplaceOrderDetails.Productoverview.Title')}
                    width={'full'}
                >
                    
                    <SalesConfigurationProductList 
                        model={order} 
                        readonly
                        salesTextId={0}
                    />
                        
                </PlattixCard>
            </PlattixCardContainer>
        </>
    );
}