import {useParams} from "react-router";
import {useLocation} from "react-router-dom";
import {useMemo} from "react";

type IdParamType = {
    id: string
}

/**
 * Get the id from the route
 * 
 * If the url id is 'new', -1 will be returned
 */
export function useIdParam(): { id: number | null } {
    const {id: idString} = useParams<IdParamType>()
    
    if (idString === 'new') return {id: -1};

    if (!idString.match(/[0-9]+/)) {
        return {id: null};
    }

    return {id: idString ? parseInt(idString) : null};
}

export function useNumberParams<Params extends { [K in keyof Params]?: number } = {}>(): { [p in keyof Params]: number | null } {
    const stringParams = useParams<{ [K in keyof Params]?: string }>()

    let numberParams = {};
    
    function getValue(key: string){
        let val = stringParams[key];

        if (val === undefined) return null;

        if (!val.match(/[0-9]+/)){
            if (val === 'new') return -1;
            else return null;
        }

        return parseInt(val);
    }

    Object.keys(stringParams).forEach(key => {            
        numberParams[key] = getValue(key);
    })

    return numberParams as { [K in keyof Params]: number | null};
}

export function useSearchParams<T extends {[key: string]: string}>(): T {
    const { search } = useLocation();

    return useMemo(
        () => {
            const params = new URLSearchParams(search)
            return Array.from(params.keys()).reduce((a,b) => ({...a, [b]:params.get(b)}),{}) as T
        },
        [search]
    );
}
export function useNumberSearchParams<T extends {[key: string]: number}>(): T {
    const { search } = useLocation();

    return useMemo(
        () => {
            const params = new URLSearchParams(search)
            return Array.from(params.keys()).reduce((a,b) => ({...a, [b]: parseInt(params.get(b) ?? '') || null}),{}) as T
        },
        [search]
    );
}