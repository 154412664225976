import {PropsWithChildren} from "react";
import {Link} from "react-router-dom";

export interface PlattixLinkProps {
    link?: string | undefined | null,
    onClick?: () => void
}

export function PlattixLink(props: PropsWithChildren<PlattixLinkProps>){
    
    if (props.link)
        return <Link to={props.link} >{props.children}</Link>
    
    if (props.onClick)
        return <div className={ 'pointer'} onClick={props.onClick}>{props.children}</div>
    
    return <>{props.children}</>
}