import React from 'react';

import {ErrorFallbackScreen} from "./components/ErrorFallbackScreen";
import {ErrorBoundary} from "react-error-boundary";
import {Router} from "./pages/Routers/Routers";

export function BodyComponent() {
        
    return (
        <ErrorBoundary FallbackComponent={ErrorFallbackScreen}>
            <section className="body" id={'body'}>
                <Router/>
            </section>
        </ErrorBoundary>
    );
}