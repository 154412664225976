import {doDelete, isHttpError, usePlattixMutation, usePlattixQuery} from "PlattixUI/PlattixReactCore/api/Api";
import {ICalculatePrice, SalesDetail, SalesModel, SalesText, SalesType} from "PlattixSalesUI/core/types/Sales";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {formatEuro} from "PlattixUI/util/numberUtil";
import {
    DataGridPro,
    GRID_DETAIL_PANEL_TOGGLE_FIELD,
    GridActionsCellItem,
    GridCallbackDetails,
    GridColDef,
    GridRowOrderChangeParams,
    GridSelectionModel,
    MuiEvent,
    useGridApiRef
} from "@mui/x-data-grid-pro";
import {GridActionsColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {
    SalesConfigurationProductListGridContainer,
    SalesConfigurationProductListGridStyling,
    SalesConfigurationProductListTotals,
    SalesConfigurationProductListTotalsButtons,
    SalesConfigurationProductListTotalsContainer,
    SalesConfigurationProductListTotalsRow
} from "PlattixSalesUI/Sales/SalesConfiguration/SalesConfigurationStyling";
import {ContentCardButtons, PlattixCardText} from "PlattixUI/core/components/ContentCard";
import {
    CancelButton,
    ConfirmButton,
    DeleteButton,
    DeleteIconButton,
    EditButton
} from "PlattixUI/core/components/Buttons";
import {useQueryClient} from "@tanstack/react-query";
import {DeleteWithConfirm} from "PlattixUI/PlattixReactCore/api/ApiWithSwal";
import {SalesConfigurationProductForm} from "PlattixSalesUI/Sales/Components/SalesConfigurationProductForm";
import {useImmer} from "use-immer";
import {PlattixSwal} from "PlattixUI/core/components/Swal";
import {toast} from "react-toastify";
import {SalesEndpoints} from "PlattixSalesUI/Sales/configuration/RouteMap";
import {ToastContent} from "PlattixUI/core/components/ToastContent";
import {faUpDownLeftRight} from "@fortawesome/free-solid-svg-icons/faUpDownLeftRight";
import {useForm} from "react-hook-form";
import {PlattixSubmitButton, PlattixValidatedInput} from "PlattixUI/core/components/form/Input";
import {PlattixAutoFormModal, PlattixForm, PlattixSubmitField} from "PlattixUI/core/components/form/Form";
import {ModalBaseProps} from "PlattixUI/core/components/Modal";
import {DataGridProProps} from "@mui/x-data-grid-pro/models/dataGridProProps";
import {SelectOption} from "PlattixUI/core/components/form/Select";


export interface SalesConfigurationProductListProps {
    model: SalesModel;
    readonly?: boolean,
    salesTextId: number,
}

export type SalesProductDataModel = { prices: ICalculatePrice, products: SalesDetail[], texts: SalesText[] }
export function useSalesProductsQuery(type: SalesType|undefined, salesId: number|undefined){
    const queryClient = useQueryClient();
    
    const url = SalesEndpoints.Products(type, salesId);
    const queryKey = ['Sales', type, 'Detail', salesId];
    const productQuery = usePlattixQuery<SalesProductDataModel>(
        queryKey,
        url,
        {},
        {
            enabled: !!salesId && salesId > 0
        }
    )
    
    const products = useMemo(
        () => productQuery.data?.products ?? [],
        [productQuery.data?.products])

    
    return {
        ...productQuery, 
        prices: productQuery.data?.prices,
        products: products,
        texts: (productQuery.data?.texts ?? []) as SalesText[],
        reload: () => queryClient.invalidateQueries(queryKey),
        queryKey: queryKey
    }
}

/* Dit is de component met de inhoud van een productGroupItem. */
export function SalesConfigurationTextGroup(props: SalesConfigurationProductListProps){
    
    return (
        <>
            {/* De tekstinput. */}
            <SalesConfigurationText {...props} />
            
            <hr />
            
            {/* De productlijst. */}
            <SalesConfigurationProductList {...props} />
        </>
    );
}

/* Dit is de tekstinput die getoond zal worden in een productGroupItem. */
export function SalesConfigurationText(props: SalesConfigurationProductListProps){
    
    // region Hooks
    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------

    const form = useForm<SalesText>();
    
    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------
    // endregion

    // region Queries
    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------

    /* Query dat de opgeslagen producten, teksten en totaalprijzen gaat ophalen. */
    const salesProductsQuery = useSalesProductsQuery(props.model.salesType, props.model.salesId);
    const salesProducts = salesProductsQuery.data;

    /* Mutation query om de tekst op te slaan. */
    const mutation = usePlattixMutation<SalesProductDataModel, SalesText>(
        salesProductsQuery.queryKey,
        SalesEndpoints.Text.Detail(props.model.salesType, props.model.salesId, props.salesTextId),
        {},
        {onError: (e) => {toast.error(e.title)}}
    )
    
    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------
    // endregion
    
    // region Variables
    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------

    const text = useMemo(() => salesProducts?.texts?.find(t => t.salesTextId === props.salesTextId), [props.salesTextId, salesProducts?.texts]);
    // const hasProds = useMemo(() => salesProducts?.products?.some(t => t.textId === props.salesTextId), [props.salesTextId, salesProducts?.products]);
    
    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------
    // endregion
    
    // region Handlers
    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------

    // function deleteText() {
    //     if (hasProds) return toast.error(t('Sales.Text.Delete.StillProducts'));
    //     DeleteWithConfirm(SalesEndpoints.Text.Detail(props.model.salesType, props.model.salesId, props.salesTextId), t('Sales.Text.Delete.Title'), {
    //         onSuccess: salesProductsQuery.reload
    //     });
    // }
    
    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------
    // endregion
    
    useEffect(() => {
        if (text) form.reset(text)
    }, [form, text])
    
    return <>
        <PlattixForm onSubmit={form.handleSubmit(model => {mutation.mutate(model)})}>
            <PlattixValidatedInput<SalesText> formHook={form} name={"salesId"} type={"hidden"} error={mutation.error}/>
            <PlattixValidatedInput<SalesText> formHook={form} name={"description"} type={"textareaAutoSize"} error={mutation.error} />
            <PlattixValidatedInput<SalesText> formHook={form} name={"noPrintOut"} type={"checkbox"} error={mutation.error} />
            
            <ContentCardButtons padding={['bottom']}>
                {/*<DeleteIconButton onClick={deleteText}>{t('Sales.Text.Delete')}</DeleteIconButton>*/}
                
                {form.formState.isDirty &&
                    <>
                        <PlattixSubmitButton loading={mutation.isLoading} name={t('save')}/>
                        <CancelButton onClick={() => form.reset()}>{t('cancel')}</CancelButton>
                    </>
                }
            </ContentCardButtons>

        </PlattixForm>
    </>
}

/**
 * Component van de productenlijst en de totalen
 * */
export function SalesConfigurationProductList(props: SalesConfigurationProductListProps) {
    
    // region States
    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------

    /* State om de toegevoegde producten bij te houden. */
    const [rows, setRows] = useImmer<SalesDetail[] | []>([]);
    /* State om de geselecteerde producten bij te houden. */
    const [selectedRows, setSelectedRows] = useState<GridSelectionModel>([]);
    /* State om de inputfields van een nieuw product te tonen. */
    const [addNewProduct, setAddNewProduct] = useState<boolean>(false);
    /* State om de detailId bij te houden. */
    const [editedSalesDetailId, setEditedSalesDetailId] = useState<number>(-1);
    /* State om de modal te laten zien waarbij het geselecteerde product van groep kan veranderen. */
    const [showMoveDetailModal, setShowMoveDetailModal] = useState(false);

    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------
    // endregion
    
    // region Hooks
    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------

    /* Ref om de grid aan te spreken. */
    const apiRef = useGridApiRef();
    /* Ref van de button om een nieuw product toe te voegen. */
    const newProductButtonRef = useRef<HTMLButtonElement>(null);
    
    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------
    // endregion
    
    // region Queries
    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------

    /* Query dat de opgeslagen producten, teksten en totaalprijzen gaat ophalen. */
    const salesProductsQuery = useSalesProductsQuery(props.model.salesType, props.model.salesId);
    const salesProducts = salesProductsQuery.data;

    /* Mutation query om de volgorde van de producten op te slaan. */
    const reorderMutation = usePlattixMutation<SalesProductDataModel, number[]>(
        salesProductsQuery.queryKey,
        SalesEndpoints.Text.ReorderDetail(props.model.salesType, props.model.salesId, props.salesTextId)
    );
    
    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------
    // endregion
    
    // region Variables
    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------

    /* Variable om na te kijken of de productengroep producten heeft. */
    const hasProducts = useMemo(() => salesProducts?.products?.some(t => t.textId === props.salesTextId), [props.salesTextId, salesProducts?.products]);

    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------
    // endregion
    
    // region Handlers
    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------

    function onEditProduct(detailId?: number) {
        if (props.readonly) return;
        setEditedSalesDetailId(detailId ?? -1)

        if (detailId) return;
        setAddNewProduct(true);
    }

    const onSubmitHandler = () => {
        setAddNewProduct(false);
        if (newProductButtonRef.current) newProductButtonRef.current.focus();
    };

    function editProductHandler(row) {
        apiRef.current.toggleDetailPanel(row.id);
    }

    function onDeleteProduct(detailId?: number) {
        if (props.readonly) return;
        DeleteWithConfirm(
            SalesEndpoints.SalesDetail(props.model?.salesType ?? "Order", props.model?.salesId??-1, detailId ??-1),
            t('Sales.Detail.Delete.ConfirmTitle'),
            {
                onSuccess: salesProductsQuery.reload
            }
        )
    }

    function onDeleteProducts(detailIds?: number[]) {
        if (props.readonly) return;
        async function apiCall(){
            if (!detailIds) return;
            for(const detailId of detailIds){
                const response = await doDelete(SalesEndpoints.SalesDetail(props.model?.salesType ?? "Order",  props.model?.salesId??-1, detailId))
                if (isHttpError(response)){
                    toast.error(<ToastContent title={response.title} body={response.detail} />)
                }
            }

            await salesProductsQuery.reload();
        }

        PlattixSwal({
            title: t('Sales.Details.Delete.ConfirmTitle'),
            icon: "warning",
            showLoaderOnConfirm: true,
            showCancelButton: true,
            confirmButtonColor: 'red',
            preConfirm: apiCall,
        })
    }

    function deleteText() {
        if (hasProducts) return toast.error(t('Sales.Text.Delete.StillProducts'));
        DeleteWithConfirm(SalesEndpoints.Text.Detail(props.model.salesType, props.model.salesId, props.salesTextId), t('Sales.Text.Delete.Title'), {
            onSuccess: salesProductsQuery.reload
        });
    }

    /* Content van de DetailPanel */
    function getDetailPanelContent (row) {
        if (props.model && props.model.customerAccountId)
            return <SalesConfigurationProductForm
                salesType={props.model.salesType}
                salesId={props.model.salesId}
                ownerId={props.model.ownerAccountId}
                customerId={props.model.customerAccountId}
                detailId={row.id as number}
                apiRef={apiRef}
                nextSequence={rows.length + 1}

                textId={props.salesTextId}
            />
        return <h5>{t('No.Model.Defined')}</h5>;
    };

    /* Handelt af met het verplaatsen van de rows in de grid */
    const handleRowOrderChange = (data: GridRowOrderChangeParams) => {
        if (props.readonly) return;
        const minIndex = Math.min(data.oldIndex, data.targetIndex);
        const maxIndex = Math.max(data.oldIndex, data.targetIndex);
        const delta = (data.oldIndex > data.targetIndex) ? +1 : -1;

        rows.filter(row => row.productId);

        setRows(draft => {

            // dataPackages
            draft
                .filter(row => row.productId !== data.row.productId)
                .filter(row => row.sequenceNumber >= minIndex && row.sequenceNumber <= maxIndex)
                .forEach(row => row.sequenceNumber += delta)

            // dataPackages
            draft
                .filter(row => row.productId === data.row.productId)
                .forEach(row => row.sequenceNumber = data.targetIndex)

            draft.sort((a, z) => a.sequenceNumber - z.sequenceNumber)

            reorderMutation.mutate(draft.map(d => d.detailId))
        });
    }
    
    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------
    // endregion
    

    useEffect(() => {
        if (!salesProducts?.products) return;
        if (props.salesTextId) {
            setRows(salesProducts.products.filter(p => p.textId === props.salesTextId));
        }
        else {
            setRows(salesProducts.products);
        }
    }, [salesProducts?.products, props.salesTextId, setRows]);

    return (
        <>
            <SalesConfigurationProductListGridContainer>
                {/*<PlattixCardText */}
                {/*    text={t('SalesConfigurationProductList.salesConfigurationProductListGrid.Description')}*/}
                {/*    textStyle={{centered: 'true'}}*/}
                {/*/>*/}

                {/* Weergave van de productenlijst */}
                <SalesConfigurationProductGrid
                    readonly={props.readonly}
                    products={rows}
                    handleRowOrderChange={handleRowOrderChange}
                    
                    getDetailPanelHeight={() => 'auto'}
                    getDetailPanelContent={getDetailPanelContent}
                    apiRef={apiRef}
                    onProductDelete={onDeleteProduct}
                    onProductEdit={editProductHandler}
    
                    isLoading={salesProductsQuery.isLoading}
                    error={salesProductsQuery.error?.title}
                    
                    setSelectedRows={(ids) => setSelectedRows(ids)}
                />

                {/* Weergave van alles onder de productenlijst */}
                <SalesConfigurationProductListTotalsContainer>

                    {/* Weergave van de knoppen, onder de productenlijst */}
                    {!props.readonly &&
                        <SalesConfigurationProductListTotalsButtons>

                            <DeleteIconButton onClick={deleteText}>{t('Sales.Text.Delete')}</DeleteIconButton>
                            
                            {/*<ConfirmButton onClick={addNewProduct}>*/}
                            {!selectedRows.length &&
                                <ConfirmButton ref={newProductButtonRef} icon={faPlus} onClick={() => onEditProduct()}>
                                    {t('SalesConfigurationProductList.Add.Row.Btn')}
                                </ConfirmButton>
                            }

                            {!!selectedRows.length && <>
                                <DeleteButton icon={faTrash} onClick={() => onDeleteProducts(selectedRows as number[])}>
                                    {t('SalesConfigurationProductList.Delete.Rows.Btn')}
                                </DeleteButton>
                                
                                {salesProducts?.texts && salesProducts?.texts.length > 1 && 
                                    <>
                                        <EditButton icon={faUpDownLeftRight} onClick={() => setShowMoveDetailModal(true)}>
                                            {t('SalesConfigurationProductList.changeGroup')}
                                        </EditButton>
                                        <MoveSalesTextDetailModal
                                            show={showMoveDetailModal}
                                            onClose={() => setShowMoveDetailModal(false)} 
                                            type={props.model.salesType}
                                            salesId={props.model.salesId}
                                            textId={props.salesTextId}
                                            selectedRows={selectedRows}
                                        />
                                    </>
                                    
                                }
                            </>
                            }
                        </SalesConfigurationProductListTotalsButtons>
                    }
                                        
                </SalesConfigurationProductListTotalsContainer>
            </SalesConfigurationProductListGridContainer>

            {props.model && props.model.customerAccountId &&
                <>                    
                    {addNewProduct &&
                        <SalesConfigurationProductForm
                            salesType={props.model.salesType}
                            salesId={props.model.salesId}
                            ownerId={props.model.ownerAccountId}
                            customerId={props.model.customerAccountId}
                            detailId={editedSalesDetailId}
                            onSubmit={onSubmitHandler}
                            onClose={() => setAddNewProduct(false)}
                            
                            textId={props.salesTextId}
                        />
                    }
                </>
            }
        </>
    );
}

interface SalesConfigurationProductGridProps extends Pick<DataGridProProps, "getDetailPanelHeight" | "getDetailPanelContent" | "apiRef">{
    readonly?: boolean,
    products: SalesDetail[],
    handleRowOrderChange?: (params: GridRowOrderChangeParams, event: MuiEvent<{}>, details: GridCallbackDetails) => void,

    onProductDelete?: (id: number) => void,
    onProductEdit?: (row: any) => void,

    isLoading?: boolean,
    error?: string | undefined,

    setSelectedRows?: (selectionModel: GridSelectionModel, details: GridCallbackDetails) => void
}

export function SalesConfigurationProductGrid(props: SalesConfigurationProductGridProps){
    /**
     * Definities om de currencysymbol te renderen in de cell
     * */
    const currencyColumn = {
        type: 'number',
        valueFormatter: ({value}) => formatEuro((value),4),
    };

    /**
     * Definities van de columns
     * */
    const columns: (GridColDef | GridActionsColDef)[] = [
        {
            field: 'productCode',
            headerName: t('Productnumber'),
            width: 140
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1,
        },
        {
            field: 'skuSalesPrice',
            headerName: t('skuSalesPrice'),
            align: 'right',
            width: 120,
            ...currencyColumn
        },
        {
            field: 'quantity',
            headerName: t('Amount'),
            align: 'right',
            type: 'number',
        },
        {
            field: 'skuCodeDescription',
            headerName: t('skuCode'),
        },
        {
            field: 'totalGrossPrice',
            headerName: t('totalGrossPrice'),
            align: 'right',
            width: 120,
            ...currencyColumn
        },
        {
            field: 'totalDiscountAmount',
            headerName: t('totalDiscountAmount'),
            align: 'right',
            ...currencyColumn
        },
        {
            field: 'partGlobalDiscountAmount',
            headerName: t('Sales.Detail.PartGlobalDiscountAmount'),
            align: 'right',
            width: 220,
            ...currencyColumn
        },
        {
            field: 'totalBaseVatAmount',
            headerName: t('totalBaseVatAmount'),
            align: 'right',
            width: 200,
            ...currencyColumn
        },
        {
            field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
            renderCell: (params) => <></>,
            hide: true,
        },

    ];
    
    if (!props.readonly){
        columns.push(
            {
                field: 'actions',
                type: "actions",
                width: 50,
                getActions: (row) => [
                    <GridActionsCellItem
                        icon={<FontAwesomeIcon icon={faEdit}/>}
                        onClick={() => props.onProductEdit?.(row)}
                        label={t('edit')}
                    />,
                    <GridActionsCellItem
                        icon={<FontAwesomeIcon icon={faTrash}/>}
                        onClick={() => props.onProductDelete?.(row.id as number)}
                        label={t('delete')}
                    />,
                ]
            }
        )
    }
    
    
    return <DataGridPro
        columns={columns}
        rows={props.products}
        rowReordering={!props.readonly}
        onRowOrderChange={props.readonly ? undefined : props.handleRowOrderChange}
        autoHeight={true}
        sx={SalesConfigurationProductListGridStyling}
        hideFooter={true}
        density={"compact"}
        loading={props.isLoading}
        error={props.error}
        getRowId={row => row.detailId}
        getDetailPanelHeight={props.getDetailPanelHeight}
        getDetailPanelContent={props.getDetailPanelContent}
        apiRef={props.apiRef}
        disableColumnMenu
        disableColumnFilter
        checkboxSelection={!props.readonly}
        onSelectionModelChange={props.setSelectedRows}
    />
}

/* Hier staat het overzicht van de totaalprijzen van het document. */
export function SalesConfigurationDetailTotals(props: {model: SalesModel}){

    const {prices} = useSalesProductsQuery(props.model.salesType, props.model.salesId)
    
    return <SalesConfigurationProductListTotals>
        <SalesConfigurationProductListTotalsRow>
            <b>{t('TotalGrossPrice')}</b>
            <p>{formatEuro(prices?.totalGrossPrice ?? 0)}</p>
        </SalesConfigurationProductListTotalsRow>
        <SalesConfigurationProductListTotalsRow>
            <b>{t('TotalDiscountAmount')}</b>
            <p>{formatEuro(-(prices?.totalDiscountAmount ?? 0))}</p>
        </SalesConfigurationProductListTotalsRow>
        <SalesConfigurationProductListTotalsRow>
            <b>{t('TotalGlobalDiscountAmount')}</b>
            <p>{formatEuro(-(prices?.totalGlobalDiscountAmount ?? 0))}</p>
        </SalesConfigurationProductListTotalsRow>
        <hr/>
        <SalesConfigurationProductListTotalsRow>
            <b>{t('TotalBaseVatAmount')}</b>
            <p>{formatEuro(prices?.totalBaseVatAmount ?? 0)}</p>
        </SalesConfigurationProductListTotalsRow>
        <SalesConfigurationProductListTotalsRow>
            <b>{t('TotalVatAmount')}</b>
            <p>{formatEuro(prices?.totalVatAmount ?? 0)}</p>
        </SalesConfigurationProductListTotalsRow>
        <hr/>
        <SalesConfigurationProductListTotalsRow>
            <b>{t('TotalPriceVatInclusive')}</b>
            <p>{formatEuro((prices?.totalBaseVatAmount ?? 0) + (prices?.totalVatAmount ?? 0))}</p>
        </SalesConfigurationProductListTotalsRow>
    </SalesConfigurationProductListTotals>
}

function MoveSalesTextDetailModal(props: {selectedRows: GridSelectionModel, type: SalesType, salesId: number, textId: number} & ModalBaseProps){
    const {queryKey, texts} = useSalesProductsQuery(props.type, props.salesId)
    const form = useForm<{toId: number}>()
    
    const otherTexts: SelectOption[] = useMemo(()=> texts
        .filter(t => t.salesTextId !== props.textId)
            .map(t => ({
                label: t.description,
                value: t.salesTextId
            }))
        , [props.textId, texts]);

    const moveMutation = usePlattixMutation<SalesProductDataModel, {toId: number, detailIds: GridSelectionModel}>(
        queryKey,
        SalesEndpoints.Text.MoveDetails(props.type, props.salesId, props.textId),
        {},
        {onError: (e) => {toast.error(e.title)}}
    );
    async function moveDetails(model){
        await moveMutation.mutateAsync({
            toId: model.toId,
            detailIds: props.selectedRows
        })
    }
    
    const fields: PlattixSubmitField<{ toId: number; }>[] = [{
        name: "toId",
        type: "select",
        options: otherTexts,
        label: 'Sales.MoveDetail.Label'
    }]
    
    return <PlattixAutoFormModal<{toId: number}>
        title={t('Sales.MoveDetails')}
        show={props.show} onClose={props.onClose}  
        fields={fields} 
        onSubmit={moveDetails}
        form={form}
        loading={moveMutation.isLoading}
        error={moveMutation.error}
    />
}