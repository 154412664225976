import {PlattixDataGrid} from "PlattixUI/core/grid/PlattixDataGrid";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {PlattixCard, PlattixCardContainer} from "PlattixUI/core/components/ContentCard";
import {MarketplaceHeader} from "PlattixSalesUI/Sales/Marketplace/Components/MHeader";
import React from "react";

export function MarketplaceOrders() {
    return (
        <>
            <MarketplaceHeader
                title={t('MarketplaceOrders.Headline')}
                includeBtns={{
                    checkout: true, 
                    cart: true
                }}
            />
            <PlattixCardContainer>
                <PlattixCard
                    header={t('MarketplaceOrders.Grid.Title')}
                    width={'full'}
                >
                    <PlattixDataGrid
                        gridCode={"OrdersForUser"}
                        includeLanguageCode
                        showEditButton onEdit={row => `/Order/${row.id}`}
                    />
                </PlattixCard>
            </PlattixCardContainer>
        </>
    );
}