import {
    ActiveCartQueryKey,
    MarketplaceTotalsProductListItemProps,
    MarketplaceTotalsProductListProps,
    ShoppingCartViewModel
} from "PlattixSalesUI/Sales/MarketplaceUtil";
import {ReadMoreText} from "PlattixUI/core/components/ContentCard";
import {
    NumberInputStyling,
    ProductList,
    ProductListItem,
    ProductListItemActions,
    ProductListItemAmount,
    ProductListItemImage,
    ProductListItemInfo,
    ProductListItemInfoPrice,
    ProductListItemInfoPriceItem
} from "PlattixSalesUI/Sales/MarketplaceStyling";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {DeleteButton} from "PlattixUI/core/components/Buttons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import NumberInput from 'semantic-ui-react-numberinput';
import {generatePath} from "react-router-dom";
import {MarketplaceRouteMap} from "PlattixSalesUI/configuration/RouteMap";
import {usePlattixMutation} from "PlattixUI/PlattixReactCore/api/Api";
import {Endpoints} from "configuration/ApiEnpointMap";
import {formatEuro} from "PlattixUI/util/numberUtil";
import {useMediaQuery} from "@mui/material";
import {deviceDimensions} from "PlattixUI/core/components/Responsive";

export function MarketplaceTotalsProductList(props: MarketplaceTotalsProductListProps) {

    return (
        <>
            <ProductList small={props.smallLayout ? 'true' : undefined}>
                {props.products.map((product, index) => {
                    return (
                        <MarketplaceTotalsProductListItem 
                            product={product}
                            key={`${product.productId}_${index}`}
                            editable={props.editable}
                            smallLayout={props.smallLayout}
                        />
                    )
                })}
            </ProductList>
        </>
    )
}

export function MarketplaceTotalsProductListItem(props: MarketplaceTotalsProductListItemProps) {
    const [productQty, setProductQty] = useState<number>(props.product.amount);
    const ProductListItemId = `cart-article-${props.product.productId}`;
    
    const mobile = useMediaQuery(deviceDimensions.mobile);
    const tablet = useMediaQuery(deviceDimensions.tablet);
    const desktop = useMediaQuery(deviceDimensions.desktop);

    const small = props.smallLayout ? 'true' : undefined;
    
    useEffect(() => {
        // Fix voor ontbrekende icons in NumberInput
        if (!document.querySelector(`#${ProductListItemId} #amountSelector .button .icon.minus .fa-minus`)) {
            const minusIcon = document.createElement("i");
            minusIcon.className = "fa fa-minus";
            document.querySelector(`#${ProductListItemId} #amountSelector .button .icon.minus`)?.appendChild(minusIcon);
        }

        // Fix voor ontbrekende icons in NumberInput
        if (!document.querySelector(`#${ProductListItemId} #amountSelector .button .icon.plus .fa-plus`)) {
            const plusIcon = document.createElement("i");
            plusIcon.className = "fa fa-plus";
            document.querySelector(`#${ProductListItemId} #amountSelector .button .icon.plus`)?.appendChild(plusIcon);
        }

    }, [props.product]);
    
    useEffect(() => {
        setProductQty(props.product.amount)
    }, [props.product.amount])

    const deleteProductHandler = () => {
        console.log('Delete product')
        productQtyHandler(0)
    }
    
    const productAmountMutation = usePlattixMutation<ShoppingCartViewModel, {productId: number, amount: number}>(
        [ActiveCartQueryKey],
        Endpoints.Marketplace.UpdateProductAmount()
    );
    
    const productQtyHandler = (qty: number) => {
        setProductQty(qty)
        productAmountMutation.mutateAsync({productId: props.product.productId, amount: qty})
    };
    
    const redirectToProductHandler = (product) => {
        const url = generatePath(MarketplaceRouteMap.Product(), {
            productId: product.productId,
        });
        
        window.open(url, "_blank");
    }
    
    return (
        <ProductListItem id={ProductListItemId} small={small}>
            <ProductListItemImage small={small}>
                <img 
                    src={props.product.filePath} 
                    alt={props.product.description1}
                    onClick={() => redirectToProductHandler(props.product)}
                />
            </ProductListItemImage>

            <ProductListItemInfo small={small}>
                <h5 onClick={() => redirectToProductHandler(props.product)}>
                    {props.product.description1}
                </h5>
                <ReadMoreText rows={3}>{props.product.description2}</ReadMoreText>
                {/*<p>Verkocht door Energytix</p>*/}
            </ProductListItemInfo>

            <ProductListItemActions small={small}>
                {props.editable ?
                    <>
                        <NumberInput
                            value={productQty.toString()}
                            minValue={props.product?.minQuantity >= 0 ? 0 : props.product?.minQuantity}
                            stepAmount={props.product?.salesMultiple >= 0 ? 1 : props.product?.salesMultiple}
                            onChange={productQtyHandler}
                            buttonPlacement="leftAndRight"
                            className={`${NumberInputStyling} ${desktop ? '' : 'mobile'}`}
                            id={'amountSelector'}
                        />
                        
                        <DeleteButton onClick={deleteProductHandler}>
                            <FontAwesomeIcon icon={faTrash}/>
                        </DeleteButton>
                    </>
                    :
                    <ProductListItemAmount small={small}>
                        <b>{t('Amount')}</b>
                        <p>{productQty}</p>
                    </ProductListItemAmount>
                }
            </ProductListItemActions>

            <ProductListItemInfoPrice small={small}>
                <ProductListItemInfoPriceItem small={small}>
                    <h5>{formatEuro(props.product.skuTotalSalesPriceIncl)} {t('Total')}</h5>
                    <p>{formatEuro(props.product.skuTotalSalesPriceExcl)} {t('Marketplace.Home.Products.ExcludingVat', {amount: props.product.vatPercent})}</p>
                </ProductListItemInfoPriceItem>
                {/*<hr/>*/}
                <ProductListItemInfoPriceItem small={small}>
                    <b>{formatEuro(props.product.skuSingleSalesPriceIncl)} / {props.product.sku}</b>
                    <p>{formatEuro(props.product.skuSingleSalesPriceExcl)} {t('Marketplace.Home.Products.ExcludingVat', {amount: props.product.vatPercent})}</p>
                </ProductListItemInfoPriceItem>
            </ProductListItemInfoPrice>
        </ProductListItem>
    );
}