import {PlattixForm, PlattixFormModal} from "PlattixUI/core/components/form/Form";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import {PlattixSubmitButton, PlattixValidatedInput} from "PlattixUI/core/components/form/Input";
import {ExtendedCalculatePriceDetail, SalesDetail, SalesType} from "PlattixSalesUI/core/types/Sales";
import {Min, Range, Required} from "PlattixUI/core/forms/FormValidators";
import {EditIconButton, ToggleButton} from "PlattixUI/core/components/Buttons";
import {formatEuro, toFixedDecimal} from "PlattixUI/util/numberUtil";
import {
    SalesConfigurationProductFormCombinedInput,
    SalesConfigurationProductFormCombinedInputContainer,
    SalesConfigurationProductFormContainer,
    SalesConfigurationProductListTotals,
    SalesConfigurationProductListTotalsRow
} from "PlattixSalesUI/Sales/SalesConfiguration/SalesConfigurationStyling";
import {ChooseFromGridModal} from "PlattixUI/core/grid/ChooseFromGridModal";
import React, {PropsWithChildren, useEffect, useState} from "react";
import {useQueryClient} from "@tanstack/react-query";
import {usePlattixMutation, usePlattixQuery} from "PlattixUI/PlattixReactCore/api/Api";
import {useForm, UseFormReturn} from "react-hook-form";
import {ProductModel} from "PlattixSalesUI/Sales/Products/ProductDetail";
import {GetTranslation} from "PlattixUI/util/TranslationUtil";
import {ContentCardButtons} from "PlattixUI/core/components/ContentCard";
import {GridApiPro} from "@mui/x-data-grid-pro/models/gridApiPro";
import {SalesEndpoints} from "PlattixSalesUI/Sales/configuration/RouteMap";

export interface SalesConfigurationProductFormProps {
    salesType: SalesType;
    salesId: number;
    detailId: number;
    ownerId: number;
    customerId: number;
    onSubmit?: () => void;
    onClose?: () => void;
    apiRef?:  React.MutableRefObject<GridApiPro>;
    nextSequence?: number;
    
    textId: number
}

export function SalesConfigurationProductForm(props: SalesConfigurationProductFormProps) {
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
    const newProduct = props.detailId === -1;

    const queryClient = useQueryClient();
    const detailQuery = usePlattixQuery<SalesDetail>(
        ['Sales', props.salesType, 'Detail', props.ownerId, props.detailId ?? -1],
        SalesEndpoints.SalesDetail(props.salesType, props.salesId, props.detailId ?? -1), 
        {}, 
        // {enabled: props.show}
        {
            enabled: true,
        }
    );
    
    const detailMutation = usePlattixMutation<SalesDetail>(
        ['Sales', props.salesType, 'Detail', props.detailId ?? -1],
        SalesEndpoints.SalesDetail(props.salesType, props.salesId, props.detailId ?? -1),
        {},
        {
            onSuccess: data => {
                queryClient.invalidateQueries(['Sales', props.salesType, 'Detail', props.salesId])
                form.reset()
                // props.onClose()
            }
        }
    );

    const [showProductModal, setShowProductModal] = useState(newProduct);
    const [openFormModal, setOpenFormModal] = useState<boolean>(false);
    
    const form = useForm<SalesDetail>({defaultValues: {quantity: 1, textId: props.textId}})
    const watches = form.watch();
    
    const productQuery = usePlattixQuery<ProductModel>(
        ['Sales', 'Products', props.ownerId, props.customerId, watches.productId],
        SalesEndpoints.Product(props.ownerId, watches.productId),
        {}, 
        {
            keepPreviousData: true, 
            enabled: !!watches.productId
        }
    );
    
    useEffect(() => {
        form.setValue('productCode', productQuery.data?.productCode ?? '');
        form.setValue('description', productQuery.data ? GetTranslation(productQuery.data.description1) : '');
        form.setValue('sequenceNumber', productQuery.data?.sequenceNumber ?? (props.nextSequence ?? 0))
    }, [form, productQuery.data])

    const params = {
        quantity: watches.quantity || 0,
        skuSalesPrice: watches.skuSalesPrice || 0,
        discountPercentage1: watches.discountPct || 0,
        discountPercentage2: watches.discountPct2 || 0,
        discountAmount1: watches.discountAmount || 0,
        discountAmount2: watches.discountAmount2 || 0,
    }
    
    const priceQuery = usePlattixQuery<ExtendedCalculatePriceDetail>(
        ['Sales', 'Prices', props.ownerId, props.customerId, watches.productId, params],
        SalesEndpoints.CalculatePrice(props.ownerId, props.customerId, watches.productId),
        params,
        {
            enabled: !!props.ownerId && !!props.customerId && !!watches.productId
        }
    );
    
    const {data: prices} = priceQuery;

    function showProductModalDoubleClickHandler(id: any, row: any) {
        form.setValue('productId', id);
        form.setValue('skuSalesPrice', 0);
        setOpenFormModal(true);
    }

    useEffect(() => {
        if (priceQuery.data && !watches.skuSalesPrice) form.setValue('skuSalesPrice', priceQuery.data.defaultSkuSalesPrice ?? 0);
    }, [form, priceQuery.data, watches.skuSalesPrice])

    useEffect(() => {
        if (watches.quantity < (productQuery.data?.minimumQuantityToSale ?? 1)) {
            form.setValue('quantity', productQuery.data?.minimumQuantityToSale ?? 1);
        }
    }, [form, productQuery.data?.minimumQuantityToSale, watches.quantity])
    
    function onFormModalClose(){
        form.reset({})
        setOpenFormModal(false);
        props.onClose?.()
    }
    
    /**
     * Handler voor het aanpassen van een account
     * */
    const searchProductHandler = (e) => {
        const alpha = /[ A-Za-z]/;
        const numeric = /[0-9]/;
        const alphaNumeric = /[ A-Za-z0-9]/;
        const alphaNumericKeyPress = alphaNumeric.test(String.fromCharCode(e.which || e.keyCode));
        
        if (
            e.key === 'Enter' ||
            e.key === 'Backspace' ||
            e.type === 'click' ||
            alphaNumericKeyPress
        ) {
            setShowProductModal(true)
        }
    }

    /**
     * Handler voor het opslaan van de details van een product
     * */
    const saveRowHandler = () => {
        setFormSubmitted(false);
    }

    /**
     * Callback voor wanneer het opslaan gelukt is, om daarna de opengeklapte form te sluiten
     * */
    useEffect(() => {
        if (!form.formState.isSubmitSuccessful) return;
        setFormSubmitted(true);
        
        if (newProduct) setOpenFormModal(false);
        
        if (props.onSubmit) props.onSubmit();

        props.apiRef?.current.toggleDetailPanel(props.detailId);
    }, [form.formState.isSubmitSuccessful])

    // region Discounts
    
    useEffect(() => {
        form.reset({
            ...detailQuery.data,
            textId: detailQuery.data?.textId ?? props.textId 
        })

        setDiscount1AsPercent((detailQuery.data?.discountPct ?? 0) > 0);
        setDiscount2AsPercent((detailQuery.data?.discountPct2 ?? 0) > 0);
    }, [form, detailQuery.data, props.textId])

    const [discount1AsPercent, setDiscount1AsPercent] = useState(false);
    const [discount2AsPercent, setDiscount2AsPercent] = useState(false);

    function toggleDiscount1(){
        if (discount1AsPercent){
            form.setValue('discountAmount', watches.discountPct ? watches.discountPct * 100 : null)
            form.setValue('discountPct', null)
        } else {
            form.setValue('discountPct', watches.discountAmount ? watches.discountAmount / 100 : null)
            form.setValue('discountAmount', null)
        }

        setDiscount1AsPercent(!discount1AsPercent)
    }

    function toggleDiscount2(){
        if (discount1AsPercent){
            form.setValue('discountAmount2', watches.discountPct2 ? watches.discountPct2 * 100 : null)
            form.setValue('discountPct2', null)
        } else {
            form.setValue('discountPct2', watches.discountAmount2 ? watches.discountAmount2 / 100 : null)
            form.setValue('discountAmount2', null)
        }

        setDiscount2AsPercent(!discount2AsPercent)
    }
    
    // endregion
    
    
    return (
        <>
            <SalesConfigurationProductFormWrapper
                modal={newProduct}
                form={form}
                detailMutation={detailMutation}
                detailId={props.detailId}
                show={openFormModal}
                onClose={onFormModalClose}
                onSubmit={form.handleSubmit(model => detailMutation.mutate(model))}
            >
                    <PlattixValidatedInput<SalesDetail>
                        formHook={form}
                        label={'productId'}
                        name={'productId'}
                        type={"hidden"}
                        readOnly
                        validation={[Required()]}
                        error={detailMutation.error}
                    />
                    <PlattixValidatedInput<SalesDetail>
                        formHook={form}
                        name={'textId'}
                        type={"hidden"}
                        readOnly
                        validation={[Required()]}
                        error={detailMutation.error}
                    />

                    {watches.productId > 0 && <>
                        <PlattixValidatedInput<SalesDetail>
                            formHook={form}
                            name={'productCode'}
                            label={'productCode'}
                            onKeyDown={searchProductHandler}
                            readOnly
                            actionButtons={<EditIconButton onClick={searchProductHandler} />}
                        />
                        <PlattixValidatedInput<SalesDetail>
                            formHook={form} name={'description'}
                            label={'description'}
                            validation={Required()}
                        />

                        <PlattixValidatedInput<SalesDetail>
                            formHook={form} name={'quantity'}
                            type={"number"}
                            error={detailMutation.error}
                            step={productQuery.data?.salesMultiple ?? 1}
                            min={productQuery.data?.minimumQuantityToSale ?? 1}
                            validation={Min(1)}
                            description={<>
                                {t('Product.SalesMultiple')}: {productQuery.data?.salesMultiple ?? 1}
                                <br/>
                                {t('Product.MinimumQuantityToSale')}: {productQuery.data?.minimumQuantityToSale ?? 1}
                            </>}
                            // onClick={setStartFocusInput}
                        />

                        <PlattixValidatedInput<SalesDetail>
                            formHook={form} name={'skuSalesPrice'} step={0.001} type={"number"}
                            error={detailMutation.error}
                            validation={Required(true)} suffix={"euro"}
                            description={<>
                                {t('Sales.Product.DefaultPrice')} {formatEuro(prices?.defaultSkuSalesPrice || 0, 2)}
                            </>}
                        />

                        <PlattixValidatedInput<SalesDetail>
                            formHook={form} name={'remarks'}
                            type={"textareaAutoSize"}
                            label={'Sales.Detail.DetailRemarks'}
                            error={detailMutation.error}
                        />

                        {/* Discount 1 */}
                        <SalesConfigurationProductFormCombinedInputContainer>
                            <SalesConfigurationProductFormCombinedInput>
                                {discount1AsPercent &&
                                    <PlattixValidatedInput<SalesDetail>
                                        formHook={form} name={'discountPct'} type={"percent"}
                                        error={detailMutation.error}
                                        step={0.01} validation={[Range(0, 100)]}
                                        suffix={"percent"}
                                        label={"Sales.Detail.DiscountPct1"}
                                    />
                                }

                                {!discount1AsPercent &&
                                    <PlattixValidatedInput<SalesDetail>
                                        formHook={form} name={'discountAmount'} type={"number"}
                                        error={detailMutation.error}
                                        step={0.001} validation={Min(0)} suffix={"euro"}
                                        label={"Sales.Detail.DiscountAmount1"}
                                    />
                                }
                            </SalesConfigurationProductFormCombinedInput>
                            <SalesConfigurationProductFormCombinedInput width={'fit-content'}>
                                <span>&euro;</span>
                                <ToggleButton checked={discount1AsPercent} onClick={toggleDiscount1}/>
                                <span>%</span>
                            </SalesConfigurationProductFormCombinedInput>
                        </SalesConfigurationProductFormCombinedInputContainer>

                        {/* Discount 2*/}
                        {(!!watches.discountAmount || !!watches.discountPct || !!watches.discountAmount2 || !!watches.discountPct2) &&
                            <SalesConfigurationProductFormCombinedInputContainer>
                                <SalesConfigurationProductFormCombinedInput>
                                    {discount2AsPercent &&
                                        <PlattixValidatedInput<SalesDetail>
                                            formHook={form} name={'discountPct2'} type={"percent"}
                                            error={detailMutation.error}
                                            step={0.01} validation={[Range(0, 100)]}
                                            suffix={"percent"}
                                            label={"Sales.Detail.DiscountPct2"}
                                        />
                                    }

                                    {!discount2AsPercent &&
                                        <PlattixValidatedInput<SalesDetail>
                                            formHook={form} name={'discountAmount2'} type={"number"}
                                            error={detailMutation.error}
                                            step={0.001} validation={Min(0)} suffix={"euro"}
                                            label={"Sales.Detail.DiscountAmount2"}
                                        />
                                    }
                                </SalesConfigurationProductFormCombinedInput>
                                <SalesConfigurationProductFormCombinedInput width={'fit-content'}>
                                    <span>&euro;</span>
                                    <ToggleButton checked={discount2AsPercent} onClick={toggleDiscount2}/>
                                    <span>%</span>
                                </SalesConfigurationProductFormCombinedInput>
                            </SalesConfigurationProductFormCombinedInputContainer>
                        }

                        <SalesConfigurationProductListTotals>
                            <SalesConfigurationProductListTotalsRow>
                                <b></b>
                                <p></p>
                                <p></p>
                            </SalesConfigurationProductListTotalsRow>
                            <SalesConfigurationProductListTotalsRow>
                                <b>{t('skuSalesPrice')}</b>
                                <p></p>
                                <p>{formatEuro(watches.skuSalesPrice || 0, 2)}</p>
                            </SalesConfigurationProductListTotalsRow>
                            <SalesConfigurationProductListTotalsRow>
                                <b>{t('quantity')}</b>
                                <p></p>
                                <p>{watches.quantity}</p>
                            </SalesConfigurationProductListTotalsRow>
                            <SalesConfigurationProductListTotalsRow>
                                <b>{t('total')}</b>
                                <p></p>
                                <p>{formatEuro(prices?.totalGrossPrice ?? 0, 2)}</p>
                            </SalesConfigurationProductListTotalsRow>
                            {
                                (!!watches.discountAmount || !!watches.discountPct || !!watches.discountAmount2 || !!watches.discountPct2) && <>
                                    <hr/>
                                    <p>Korting</p>
                                    {
                                        !!watches.discountAmount &&
                                        <SalesConfigurationProductListTotalsRow>
                                            <b>{t('Sales.Detail.discountAmount1')}</b>
                                            <p></p>
                                            <p>{formatEuro(-(watches.discountAmount || 0), 2)}</p>
                                        </SalesConfigurationProductListTotalsRow>
                                    }
                                    {
                                        !!watches.discountPct &&
                                        <SalesConfigurationProductListTotalsRow>
                                            <b>{t('Sales.Detail.discountPct1')}</b>
                                            <p>{toFixedDecimal((watches.discountPct || 0) * 100, 0)}%</p>
                                            <p>{formatEuro(-(prices?.totalDiscountPct || 0), 2)}</p>
                                        </SalesConfigurationProductListTotalsRow>
                                    }
                                    {
                                        (!!watches.discountPct2) &&
                                        <SalesConfigurationProductListTotalsRow>
                                            <b>{t('Sales.Detail.discount2.BasAmount')}</b>
                                            <p></p>
                                            <p>{formatEuro(prices?.discount2BaseAmount || 0, 2)}</p>
                                        </SalesConfigurationProductListTotalsRow>
                                    }
                                    {
                                        !!watches.discountPct2 &&
                                        <SalesConfigurationProductListTotalsRow>
                                            <b>{t('Sales.Detail.discountPct2')}</b>
                                            <p>{toFixedDecimal((watches.discountPct2 || 0) * 100, 0)}%</p>
                                            <p>{formatEuro(-(prices?.totalDiscountPct2 || 0), 2)}</p>
                                        </SalesConfigurationProductListTotalsRow>
                                    }
                                    {
                                        !!watches.discountAmount2 &&
                                        <SalesConfigurationProductListTotalsRow>
                                            <b>{t('Sales.Detail.discountAmount2')}</b>
                                            <p></p>
                                            <p>{formatEuro(-(watches.discountAmount2 || 0), 2)}</p>
                                        </SalesConfigurationProductListTotalsRow>
                                    }
                                    {
                                        (!!watches.discountAmount || !!watches.discountPct) && (!!watches.discountAmount2 || !!watches.discountPct2) &&
                                        <SalesConfigurationProductListTotalsRow>
                                            <b>{t('Sales.Detail.TotalDiscountAmount')}</b>
                                            <p></p>
                                            <p>{formatEuro(-(prices?.totalDiscountAmount || 0), 2)}</p>
                                        </SalesConfigurationProductListTotalsRow>
                                    }
                                </>
                            }
                            <hr/>
                            <SalesConfigurationProductListTotalsRow>
                                <b>{t('TotalBaseVatAmount')}</b>
                                <p></p>
                                <p>{formatEuro(prices?.totalBaseVatAmount || 0)}</p>
                            </SalesConfigurationProductListTotalsRow>
                            <SalesConfigurationProductListTotalsRow>
                                <b>{t('Sales.Detail.VatAmount')}</b>
                                <p>{toFixedDecimal(prices?.vatPercentage || 0, 2)}%</p>
                                <p>{formatEuro(prices?.totalVatAmount || 0)}</p>
                            </SalesConfigurationProductListTotalsRow>
                            <SalesConfigurationProductListTotalsRow>
                                <b>{t('TotalPriceVatInclusive')}</b>
                                <p></p>
                                <p>{formatEuro(prices?.totalCostPrice || 0)}</p>
                            </SalesConfigurationProductListTotalsRow>
                        </SalesConfigurationProductListTotals>
                    </>
                    }

                    {!newProduct &&
                        <>
                            <hr />
                            <ContentCardButtons>
                                <PlattixSubmitButton
                                    loading={formSubmitted}
                                    disabled={formSubmitted}
                                    name={t('Save')}
                                    onClick={saveRowHandler}
                                />
                            </ContentCardButtons>
                        </>
                    }
                {/*</PlattixForm>*/}
            </SalesConfigurationProductFormWrapper>
            
            <ChooseFromGridModal
                show={showProductModal}
                onClose={() => setShowProductModal(false)}
                title={t('Sales.DetailChooseProductModal.Title')}
                description={t('SalesConfigurationProductList.ShowProductModalText.Description')}
                gridCode={"GetAvailableProducts"}
                includeLanguageCode
                parameters={{
                    "param_invoice_id": props.salesId.toString() ?? '',
                    'param_owner_id': props.ownerId.toString() ?? '',
                    'param_customer_id': props.customerId.toString() ?? ''
                }}
                onSelect={showProductModalDoubleClickHandler}
            />
        </>
    );
}

export interface SalesConfigurationProductFormWrapperProps {
    modal?: boolean;
    form:  UseFormReturn<SalesDetail, object>;
    detailMutation: any;
    detailId: number;
    show?: boolean;
    onClose?: () => void;
    onSubmit?: React.FormEventHandler<HTMLFormElement>;
}

export function SalesConfigurationProductFormWrapper(props: PropsWithChildren<SalesConfigurationProductFormWrapperProps>) {
    const onCloseHandler = () => {
        if (props.onClose) props.onClose();
    }
    
    const title = props.detailId ? t('Sales.Detail.Edit') : t('Sales.Detail.Create');
    
    if (props.modal) return (
        <>
            <PlattixFormModal
                loading={props.detailMutation.isLoading}
                onSubmit={props.onSubmit}
                show={props.show ?? false} 
                onClose={onCloseHandler}
                title={title}
            >
                {props.children}
            </PlattixFormModal>
        </>
    );
    
    return (
        <SalesConfigurationProductFormContainer>
            <PlattixForm
                id={'SalesConfigurationProductForm'}
                onSubmit={props.onSubmit}
                title={title}
            >
                {props.children}
            </PlattixForm>
        </SalesConfigurationProductFormContainer>
    );
}