import {css, styled} from "goober";
import {useMediaQuery} from "@mui/material";
import {deviceDimensions} from "PlattixUI/core/components/Responsive";

export interface ResponsiveProps {
    small?: string;
}

export const CategoryList = styled('div')(() => {
    return `
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(calc(var(--columnWidth) / 3), 1fr));
        gap: 10px;
    `;
});

export const CategoryListItem = styled('p')(() => {
    return `
        width: 100%;
        cursor: pointer;
        padding: 0;
        margin: 0;
        
        a {
            all: inherit;
        }
    `;
});

export const ProductChildProductListContainer = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        gap: 5px;
        margin: 0;
        padding: 0;
    `;
});

export const ProductChildProductList = styled('ul')(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        gap: 5px;
        margin: 0;
        padding: 0 0 0 15px;
    `;
});

export const ProductChildProductListItem = styled('li')(() => {
    return `
        width: 100%;
        padding: 0;
        margin: 0;
    `;
});

export const ProductTitle = styled('div')(() => {
    return `
        width: 100%;
        padding: 0;
        margin: 0;
        
        h5 {
            cursor: pointer;
        }
    `;
});

export const ProductFooter = styled('div')(() => {
    return `
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
    `;
});

export const ProductFooterLeft = styled('div')(() => {
    return `
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: flex-end;
    `;
});

export const ProductFooterRight = styled('div')(() => {
    return `
        width: fit-content;
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
        align-items: flex-end;
    `;
});

export const ProductFooterPrice = styled('div')(() => {
    return `
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        flex-flow: column nowrap;
        gap: 5px;
        
        h5 {
            font-size: 1.3em;
            font-weight: 700;
            margin: 0;
            padding: 0;
        }
        
        p {
            font-style: italic;
            opacity: 0.8;
        }
    `;
});

export const ProductPage = styled('div')(() => {
    return `
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        flex-flow: row nowrap;
        gap: 50px;
    `;
});

// export const ProductPageImage = styled('div')(() => {
//     return `
//         border: 1px dotted red;
//         width: 100%;
//         padding: 0;
//         margin: 0;
//         display: flex;
//         flex-flow: column nowrap;
//         gap: 50px;
//        
//         .swiper-button-next, .swiper-button-prev {
//             color: white;
//            
//             &.swiper-button-disabled {
//                 opacity: 0.5;
//             }
//         }
//     `;
// });

// export const ProductPageImage = styled('div')(() => {
//     return `
//         .swiper {
//             width: 100%;
//             height: 300px;
//             margin-left: auto;
//             margin-right: auto;
//            
//             .swiper-wrapper {
//                 position: relative;
//                 width: 100%;
//                 height: 100%;
//                 z-index: 1;
//                 display: flex;
//                 transition-property: transform;
//                 box-sizing: content-box;
//             }
//            
//             .swiper-button-next, .swiper-button-prev {
//                 color: white;
//                
//                 &:after {
//                     font-size: 15px;
//                     font-weight: 700;
//                     background: #333;
//                     padding: 6px 7px;
//                     border-radius: 5px;
//                 }
//             }
//        
//             .swiper-slide {
//                 background-size: contain;
//                 background-position: center;
//                
//                 img {
//                     display: block;
//                     width: 100%;
//                     height: 100%;
//                     object-fit: scale-down;
//                 }
//             }
//            
//             &.image-selector {
//                 height: 20%;
//                 box-sizing: border-box;
//                 padding: 10px 0;
//                
//                 .swiper-slide {
//                     width: 25%;
//                     height: 100%;
//                     opacity: 0.4;
//                 }
//                
//                 .swiper-slide-thumb-active {
//                     opacity: 1;
//                 }
//             }
//            
//             &.enlarged-image {
//                 height: 80%;
//                 width: 100%;
//             }
//         }
//        
//         * {
//             user-select: none;
//         }
//     `;
// });

export const ProductPageImage = styled('div')(() => {
    return `
        .swiper {
            width: 100%;
            height: 300px;
            margin-left: auto;
            margin-right: auto;
            
            .swiper-button-next, .swiper-button-prev {
                color: white;
                
                &:after {
                    font-size: 15px;
                    font-weight: 700;
                    background: #333;
                    padding: 6px 7px;
                    border-radius: 5px;
                }
            }
        
            .swiper-slide {
                background-size: contain;
                background-position: center;
                
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: scale-down;
                    background: #eee;
                }
            }
            
            &.image-selector {
                height: 20%;
                box-sizing: border-box;
                padding: 10px 0;
                
                .swiper-slide {
                    width: 25%;
                    height: 100%;
                    opacity: 0.4;
                }
                
                .swiper-slide-thumb-active {
                    opacity: 1;
                }
                
                img {
                    max-height: 100px;
                }
            }
            
            &.enlarged-image {
                height: 80%;
                width: 100%;
                
                img {
                    max-height: 350px;
                }
            }
        }
        
        * {
            user-select: none;
        }
    `;
});

export const ProductPageDetails = styled('div')(() => {
    return `
        width: 100%;
        padding: var(--padding2);
        margin: 0;
        display: flex;
        flex-flow: column nowrap;
        gap: 15px;
    `;
});

export const ProductPageContainer = styled('div')(() => {
    return `
        width: 100%;
        padding: var(--padding2);
        margin: 0;
    `;
});

export const ProductDetailPrice = styled('div')(() => {
    return `
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        flex-flow: column nowrap;
        gap: 3px;
        
        h5, p {
            text-align: right;
            margin: 0;
        }
    `;
});

export const ProductDetailOrder = styled('div')(() => {
    return `
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
    `;
});

export const ProductList = styled('div')((props: ResponsiveProps) => {
    const mobile = useMediaQuery(deviceDimensions.mobile);
    const tablet = useMediaQuery(deviceDimensions.tablet);
    const desktop = useMediaQuery(deviceDimensions.desktop);

    return `
        width: 100%;
        margin: 0;
        display: flex;
        flex-flow: column nowrap;
        gap: ${(mobile || tablet || !!props.small) ? '30px' : '10px'};
        max-height: 50vh;
        overflow-x: hidden;
        overflow-y: auto;
    `;
});

export const ProductListItem = styled('div')((props: ResponsiveProps) => {
    const mobile = useMediaQuery(deviceDimensions.mobile);
    const tablet = useMediaQuery(deviceDimensions.tablet);
    const desktop = useMediaQuery(deviceDimensions.desktop);
    
    const gap = (mobile || tablet || !!props.small) ? '15px' : '10px';
    
    if (mobile || tablet || !!props.small) return `
        width: 100%;
        padding: 0;
        margin: 0;
        gap: ${gap};
        
        background: #f5f5f5;
        padding: 10px;
        border-radius: 10px;
        
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(10px, 1fr));
    `;
        
    return `
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        flex-flow: row nowrap;
        gap: ${gap};
        padding-bottom: ${gap};
        border-bottom: var(--borderBottom1);
    `;
});

export const ProductListItemImage = styled('div')((props: ResponsiveProps) => {
    const mobile = useMediaQuery(deviceDimensions.mobile);
    const tablet = useMediaQuery(deviceDimensions.tablet);
    const desktop = useMediaQuery(deviceDimensions.desktop);
    
    const mobileStyling = (mobile || tablet || !!props.small) ? `
        grid-area: 1 / 1 / 2 / 2;
    ` : '';
    
    return `
        width: fit-content;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        
        ${mobileStyling}
        
        img {
            width: 100px;
            height: 100px;
            object-fit: contain;
            cursor: pointer;
        }
    `;
});

export const ProductListItemInfo = styled('div')((props: ResponsiveProps) => {
    const mobile = useMediaQuery(deviceDimensions.mobile);
    const tablet = useMediaQuery(deviceDimensions.tablet);
    const desktop = useMediaQuery(deviceDimensions.desktop);

    const mobileStyling = (mobile || tablet || !!props.small) ? `
        grid-area: 2 / 1 / 3 / -1;
        
        h5 {
            font-size: 1.5em;
        }
    ` : '';
    
    return `
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        flex-flow: column nowrap;
        
        p, h5 {
            width: 90%;
        }
        
        h5 {
            cursor: pointer;
            font-size: 1.8em;
        }
        
        ${mobileStyling}
    `;
});

export const ProductListItemInfoPrice = styled('div')((props: ResponsiveProps) => {
    const mobile = useMediaQuery(deviceDimensions.mobile);
    const tablet = useMediaQuery(deviceDimensions.tablet);
    const desktop = useMediaQuery(deviceDimensions.desktop);

    const mobileStyling = (mobile || tablet || !!props.small) ? `
        grid-area: 1 / 2 / 2 / -1;
        border: none;
        padding: 0;
    ` : '';
    
    return `
        padding: 0 0 0 10px;
        margin: 0;
        display: flex;
        flex-flow: column nowrap;
        gap: 15px;
        align-items: flex-end;
        min-width: 150px;
        border-left: var(--borderBottom1);
        
        ${mobileStyling}
    `;
});

export const ProductListItemInfoPriceItem = styled('div')((props: ResponsiveProps) => {
    return `
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        flex-flow: column nowrap;
        gap: 3px;
        align-items: flex-end;
        
        p, b, h5 {
            margin: 0;
            text-align: right;
            width: max-content;
            max-width: 150px;
        }
        
        h5 {
            font-size: 1.5em;
        }
    `;
});

export const ProductListItemActions = styled('div')((props: ResponsiveProps) => {
    const mobile = useMediaQuery(deviceDimensions.mobile);
    const tablet = useMediaQuery(deviceDimensions.tablet);
    const desktop = useMediaQuery(deviceDimensions.desktop);

    const mobileStyling = (mobile || tablet || !!props.small) ? `
        grid-area: 3 / 1 / 4 / -1;
        border: none;
        flex-flow: row-reverse wrap;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0;
    ` : '';
    
    return `
        padding: 0 0 0 10px;
        margin: 0;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 10px;
        border-left: var(--borderBottom1);
        
        ${mobileStyling}
        
        h5 {
            width: 100%;
        }
    `;
});

export const ProductListItemAmount = styled('div')((props: ResponsiveProps) => {
    const mobile = useMediaQuery(deviceDimensions.mobile);
    const tablet = useMediaQuery(deviceDimensions.tablet);
    const desktop = useMediaQuery(deviceDimensions.desktop);

    return `
        width: 100%;
        display: flex;
        flex-flow: ${(desktop && !props.small) ? 'column nowrap' : 'row wrap'};
        align-items: ${(desktop && !props.small) ? 'flex-end' : 'center'};
        justify-content: ${(desktop && !props.small) ? 'center' : 'flex-end'};
        gap: 5px;
    `;
});

export const ProductListItemPrice = styled('div')(() => {
    return `
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: flex-end;
    `;
});

export const ProductTotalsPriceList = styled('div')(() => {
    return `
        display: flex;
        flex-flow: column nowrap;
    `;
});

export const ProductTotalsPriceListItem = styled('dl')(() => {
    return `
        width: 100%;
    `;
});

export const NumberInputStyling = css`
    .ui.button {
        background: var(--backgroundColor2);
        color: var(--styleColor4);
        margin: 0;
        font-weight: var(--fontWeight2);
        font-size: var(--inputFontSize);
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        
        &[disabled] {
            opacity: 0.3;
        }
        
        &:not([disabled]):hover {
            background: var(--backgroundColor3);
        }
        
        &:nth-of-type(1) {
            border-radius: var(--roundCorner2) 0 0 var(--roundCorner2) !important;
            border-right: 1px solid #333 !important;
            padding: 5px 5px 5px 10px !important;
        }
        
        &:nth-of-type(2) {
            border-radius: 0 var(--roundCorner2) var(--roundCorner2) 0 !important;
            border-left: 1px solid #333 !important;
            padding: 5px 10px 5px 5px !important;
        }
        
        & .icon {
            color: var(--styleColor4) !important;
            font-size: 10px !important;
            width: fit-content !important;
            height: fit-content !important;
            position: relative !important;
            top: 1px !important;
        }
    }
    
    .ui.input {
        z-index: 1;
        
        & > input {
            background: var(--backgroundColor2);
            color: var(--styleColor4);
            font-weight: bold;
            width: 50px;
            padding: 5px !important;
            margin: 0;
            font-weight: var(--fontWeight2);
            font-size: var(--inputFontSize);
            border: none;
            
            @media only screen and (max-width: 1150px) {
                font-size: 16px;
            }
        }
    }
    
    &.mobile {
        .ui.button {
            background: white;
            
            &:not([disabled]):hover {
                background: white;
            }
        }
        
        .ui.input {
            & > input {
                background: white;
            }
        }
    } 
`;

export const ProductTotalsContainer = styled('div')((props: ResponsiveProps) => {
    const mobile = useMediaQuery(deviceDimensions.mobile);
    const tablet = useMediaQuery(deviceDimensions.tablet);
    const desktop = useMediaQuery(deviceDimensions.desktop);

    const mobileStyling = (mobile || tablet || !!props.small) ? `
        padding-top: 40px;
    ` : '';
    
    return `
        display: flex;
        padding-top: 10px;
        
        ${mobileStyling}
    `;
});

export const ProductTotals = styled('div')(() => {
    return `
        width: 500px;
        display: flex;
        flex-flow: column nowrap;
        gap: 5px;
        margin-left: auto;
        
        dl dd {
            width: unset !important;
        }
    `;
});

export const ProductTotalsExtras = styled('div')(() => {
    return `
        width: 100%;
    `;
});

export const OrderDetailContainer = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        gap: 25px;
    `;
});

export const OrderDetailItem = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        gap: 5px;
        
        hr {
            margin: 10px 0;
        }
    `;
});

export const MarketplaceHeaderContainer = styled('div')(() => {

    const desktop = useMediaQuery(deviceDimensions.desktop);
    
    return `
        display: flex;
        flex-flow: column nowrap;
        gap: 25px;
        
        .info {
            margin-bottom: 0 !important;
        }
    `;
});

export const MarketplaceHeaderChildrenContainer = styled('div')(() => {
    return `
        display: flex;
        flex-flow: column nowrap;
        gap: 25px;
    `;
});

export const MarketplaceHeaderChildrenItem = styled('div')(() => {
    const mobile = useMediaQuery(deviceDimensions.mobile);
    const tablet = useMediaQuery(deviceDimensions.tablet);
    const desktop = useMediaQuery(deviceDimensions.desktop);

    const mobileStyling = (mobile || tablet) ? `
        justify-content: flex-start;
        align-items: flex-end;
        margin-bottom: 25px;
        
        h5 {
            width: fit-content;
        }
    ` : '';

    return `
        display: flex;
        flex-flow: column nowrap;
        gap: 5px;
        
        ${mobileStyling}
    `;
});

export const mirrorIcon = css`
    transform: rotateY(180deg);
`;

export const MarketplaceAddressContainer = styled('div')(() => {
    return `
        display: flex;
        flex-flow: column nowrap;
        gap: 10px;
    `;
});

export const MarketplaceAddress = styled('div')(() => {
    return `
        display: flex;
        flex-flow: column nowrap;
        gap: 10px;
    `;
});

export const MarketplaceAddressDetails = styled('div')(() => {
    return `
        display: flex;
        flex-flow: column nowrap;
        gap: 0;
    `;
});

export const MarketplaceCheckoutAddressContainer = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        gap: 10px;
        flex-flow: column nowrap;
        
        input[type="checkbox"] {
            margin-left: 0 !important;
        }
        
        label {
            user-select: none;
            cursor: pointer;
        }
    `;
});

export const MarketplaceCheckoutAddress = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        gap: 10px;
        flex-flow: column nowrap;
    `;
});

export const MarketplaceEmptyContainer = styled('div')(() => {
    return `
        width: 100%;
        display: flex;
        gap: 10px;
        flex-flow: column nowrap;
        
        h5, p {
            text-align: center;
        }
    `;
});