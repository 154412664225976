import {useTranslation} from "../../../PlattixReactCore/i18n";
import * as React from "react";
import {useState} from "react";
import {isHttpError} from "../../../PlattixReactCore/api/Api";
import {doForgotPassword, ForgotEmailModel} from "../../../PlattixReactCore/api/AccountApi";
import {PlattixInput, PlattixSubmitButton} from "../../components/form/Input";
import {Link, useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {CancelButton, ConfirmButton} from "../../components/Buttons";
import {HttpError} from "../../../PlattixReactCore/CoreTypes";
import {PlattixForm} from "../../components/form/Form";
import {ContentCardButtons, PlattixFormError} from "../../components/ContentCard";
import {LoginOptions} from "PlattixUI/core/pages/Account/LoginOptions";


interface ForgotPasswordPageProps {
    email?: string;
    hideTitle?: boolean;
}


export function ForgotPasswordPage(props: ForgotPasswordPageProps) {
    const {register, handleSubmit, watch, formState: {errors}} = useForm<ForgotEmailModel>({
        defaultValues: {
            email: props.email
        }
    });

    const {t} = useTranslation();

    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState<HttpError | null>(null);
    const [success, setSuccess] = useState(false);
    
    const history = useHistory();

    async function onSubmit(value: ForgotEmailModel) {
        setSuccess(false);
        setLoading(true);

        const response = await doForgotPassword(value);

        if (isHttpError(response)) {
            setError(error)
        } else {
            setSuccess(true);
        }

        setLoading(false);
    }
    
    const backToLoginHandler = () => {
        history.push('/login');
    }


    return <div className="login-form">
        {!props.hideTitle &&
            <h5 className="login-title">{t('forgoth4')}</h5>
        }
        {
            success && <>
                <div className={"login-content-group"}>
                    <p>{t('ForgotConfirmationP')}</p>
                </div>
                <ContentCardButtons>
                    <ConfirmButton onClick={backToLoginHandler}>{t('Link.To.Login')}</ConfirmButton>
                </ContentCardButtons>
            </>
        }

        {
            !success &&
                <PlattixForm onSubmit={handleSubmit(onSubmit)}>
                    <PlattixInput
                        disabled={isLoading}
                        type={"email"}
                        name="Email"
                        label={t('Email')}
                        placeholder={t('EmailPH')}
                        error={errors.email}
                        register={register("email", { required: true })}
                />

                <ContentCardButtons>
                    {/*<Link to={'/login'}><CancelButton>{t('Link.To.Login')}</CancelButton></Link>*/}
                    <PlattixSubmitButton loading={isLoading} disabled={isLoading} name={t("reset")}/>
                </ContentCardButtons>

            </PlattixForm>
        }

        {
            error &&
            <PlattixFormError>
                <p className="login-error">{error.errors?.["_global"] ?? error.detail ?? error.title}</p>
            </PlattixFormError>
        }

        <LoginOptions 
            hide={{
                forgotPassword: true,
                TwoFactorAuth: true,
            }}
        />
    </div>
}