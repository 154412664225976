import {MarketplaceProductOverviewProps} from "PlattixSalesUI/Sales/MarketplaceUtil";
import {MarketplaceTotalsProductList} from "PlattixSalesUI/Sales/Marketplace/Components/MTotalsProductList";
import {MarketplaceEmptyContainer, ProductTotals, ProductTotalsContainer} from "PlattixSalesUI/Sales/MarketplaceStyling";
import {t} from "PlattixUI/PlattixReactCore/i18n";
import React from "react";
import {formatEuro} from "PlattixUI/util/numberUtil";

export function MarketplaceProductOverview(props: MarketplaceProductOverviewProps) {
    
    const toEuro = (amount) => {
        const amountNumber = typeof amount === 'string' ? Number(amount) : amount;
        return formatEuro(amountNumber);
    }
    
    return (
        <>
            {!!props.products.length ?
                <MarketplaceTotalsProductList
                    products={props.products}
                    editable={props.editable ?? true}
                    smallLayout={props.smallLayout}
                />
                :
                <MarketplaceEmptyContainer>
                    <h5>{t('Cart.IsEmpty')}</h5>
                    <hr/>
                </MarketplaceEmptyContainer>
            }

            <ProductTotalsContainer small={props.smallLayout ? 'true' : undefined}>
                <ProductTotals>
                    <dl>
                        <dt>{t('Marketplace.Totals.totalProductsExcl')}</dt>
                        <dd>{toEuro(props.totalProductsExcl)}</dd>
                    </dl>
                    <dl>
                        <dt>{t('Marketplace.Totals.totalVat')}</dt>
                        <dd>{toEuro(props.totalVat)}</dd>
                    </dl>
                    <hr />
                    <dl>
                        <dt>{t('Marketplace.Totals.totalProductsIncl')}</dt>
                        <dd>{toEuro(props.totalProductsIncl)}</dd>
                    </dl>
                    <dl>
                        <dt>{t('Marketplace.Totals.transportCost')}</dt>
                        <dd>{toEuro(props.transportCost)}</dd>
                    </dl>
                    <hr />
                    <dl>
                        <dt>{t('Marketplace.Totals.Total')}</dt>
                        <dd><b>{toEuro(props.total)}</b></dd>
                    </dl>
                </ProductTotals>
            </ProductTotalsContainer>
        </>
    );
}